import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { QRCodeModule } from 'angularx-qrcode';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedComponentsModule } from './components/shared-components.module';
import { DirectivesModule } from './directives/directives.module';
import { MaterialModule } from './material.module';
import { CroppingModule } from './modules/cropping/cropping.module';
import { ModalsModule } from './modules/modals/modals.module';
import { PaginationModule } from './modules/pagination/pagination.module';
import { SelectsModule } from './modules/selects/selects.module';
import { SnackbarsModule } from './modules/snackbars/snackbars.module';
import { TablesModule } from './modules/tables/tables.module';
import { ValidationModule } from './modules/validation/validation.module';
import { PipesModule } from './pipes/pipes.module';
// import { InfoFieldComponent } from './components/info-field/info-field.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    SharedComponentsModule,
    ValidationModule,
    TablesModule,
    ModalsModule,
    PaginationModule,
    SnackbarsModule,
    SelectsModule,
    DirectivesModule,
    PipesModule,
    RxReactiveFormsModule,
    QRCodeModule,
    CroppingModule,
    InfiniteScrollModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    SharedComponentsModule,
    ValidationModule,
    TablesModule,
    ModalsModule,
    PaginationModule,
    SnackbarsModule,
    SelectsModule,
    DirectivesModule,
    PipesModule,
    // InfoFieldComponent,
    RxReactiveFormsModule,
    QRCodeModule,
    CroppingModule,
    InfiniteScrollModule
  ],
  declarations: [
    // InfoFieldComponent,
  ],
  providers: [],
})
export class SharedModule {}

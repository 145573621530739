import { Navigate } from '@ngxs/router-plugin';
import { MyProfileDetailsModel } from './../model/user.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserService } from '../model/user.service';
import { tap } from 'rxjs/operators';
import { SystemRoles } from '@core/modules/authorization/model/authorization.config';
import * as USER_MODELS from '@core/modules/user/model/user.model';
import * as USER_ACTIONS from '@core/modules/user/state/user.actions';
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { PaginationModel } from '@core/http/apis.model';

export class UserStateModel {
  public user!: USER_MODELS.loggedInUserModel;

  constructor() {
    this.user = {
      id: '',
      title: '',
      fullName: '',
      profileRoles: [],
      userPermissions: []
    }
  }
}

@Injectable()
@State<UserStateModel>({
  name: 'out_out_user',
  defaults: new UserStateModel
})
export class UserState {
  constructor(
    private _userService: UserService
  ) { }

  @Selector() static user(state: UserStateModel): USER_MODELS.loggedInUserModel {
    return state.user;
  }

  @Selector() static userRoles(state: UserStateModel): any {
    return state.user.profileRoles;
  }


  @Action(USER_ACTIONS.SetUser)
  setUser(ctx: StateContext<UserStateModel>, { user }: USER_ACTIONS.SetUser) {
    ctx.patchState({ user: { ...user, profileRoles: [SystemRoles.Master] } });
  }

  @Action(USER_ACTIONS.UpdateMyProfileDetails)
  updateMyProfileDetails(ctx: StateContext<MyProfileDetailsModel>, { profile }: any) {
    return this._userService.updatedMyProfileDetails(profile).pipe(tap(result => {
      console.log(result);

      ctx.dispatch([
        new USER_ACTIONS.UpdateUserProperty('profileImage', result.result.profileImage),  //updating state management with the new profile Image
        new USER_ACTIONS.UpdateUserProperty('fullName', result.result.fullName),          //updating state management with the new full name
        new Navigate(['/users/my-profile/view'])])
    }))
  }

  // @Action(ResetUserInfo)
  // public resetUserInfo({ dispatch }: StateContext<UserStateModel>) {
  //   // alert("we are resetting user info")
  //   return this._userService.GetMyUserInfo().pipe(
  //     tap((user: USER_MODELS.loggedInUserModel) => dispatch([
  //       new SetUser({ ...user, profileRoles: [SystemRoles.Master] }),
  //       new SetGrantedRoles(user.permissions)]
  //     ))
  //   )
  // }



  @Action(USER_ACTIONS.UpdateUserProperty)
  updateUserProperty(ctx: StateContext<UserStateModel>, { property, value }: USER_ACTIONS.UpdateUserProperty) {
    ctx.patchState({ user: { ...ctx.getState().user, [property]: value } });
  }


  @Action(USER_ACTIONS.UpdateRoles)
  public updateRoles({ patchState, getState, dispatch }: StateContext<UserStateModel>, { roles: profileRoles }: USER_ACTIONS.UpdateRoles) {
    patchState({ user: { ...getState().user, profileRoles } });
  }






}

import { AuthorizationState } from './../modules/authorization/state/authorization.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { OutOutConfigInst } from 'src/app/config/app.config';
import { StorageService } from '@core/services/storage/storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private _router: Router,
    private _storage: StorageService,
  ) { }

  @SelectSnapshot(AuthorizationState.isSuperAdmin) public isSuperAdmin: boolean;
  @SelectSnapshot(AuthorizationState.isVenueAdmin) public isVenueAdmin: boolean;
  @SelectSnapshot(AuthorizationState.isEventAdmin) public isEventAdmin: boolean;
  @SelectSnapshot(AuthorizationState.isTicketAdmin) public isTicketAdmin:boolean;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._routeProtectionChecker();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._routeProtectionChecker();
  }

  private _routeProtectionChecker() {
    if (this._storage.get('token')) {
      if (!this.isSuperAdmin && !this.isVenueAdmin && !this.isEventAdmin && !this.isTicketAdmin) {
        this._router.navigate([OutOutConfigInst.ROUTES_CONFIG.landingPage]);
        return false;
      }
      return true;
    } else {
      this._router.navigate([OutOutConfigInst.ROUTES_CONFIG.login]);
      return false;
    }
  }
}

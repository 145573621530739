import { Injectable } from "@angular/core";
import { CanLoad, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from '@core/auth/model/auth.service';
import { OutOutConfigInst } from "src/app/config/app.config";
import { Store } from "@ngxs/store";
import { AuthorizationState } from "../state/authorization.state";
import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class SuperAdminGuard implements CanActivate, CanLoad {

  constructor(
    private _store: Store) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this._handleProtection();
  }


  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this._handleProtection()
  }


  /**
   *
   * @returns Observable of boolean
   */
  private _handleProtection(): boolean {
    const isSuperAdmin = this._store.selectSnapshot(AuthorizationState.isSuperAdmin)
    if (isSuperAdmin) return true;
    return false;

  }
}

import { Component, OnInit } from '@angular/core';
import { OutOutConfigInst } from 'src/app/config/app.config';
import { ResetUserInfo } from '@core/modules/user/state/user.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AuthorizationState } from '../state/authorization.state';

@Component({
    selector: 'app-forbidden',
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.scss']
})

export class ForbiddenComponent implements OnInit {
    constructor() { }

    public exceptionMessage = OutOutConfigInst.CRUD_CONFIG.errorsMessages.forbidden;
    @ViewSelectSnapshot(AuthorizationState.isTicketAdmin) private _isTicketAdmin: boolean
    ngOnInit() { }

    public onReturningHome() {
        // this.fireResettingUserInfo();
        this.fireNavigateToHome();
    }


    @Dispatch() public fireResettingUserInfo() { return new ResetUserInfo}
    @Dispatch() public fireNavigateToHome() {return new Navigate([this._isTicketAdmin ? '/tickets':OutOutConfigInst.ROUTES_CONFIG.root])}
}

<div class="flex items-center justify-center w-full h-screen splash">

  <div >
    <img src="assets/images/splash-logo.svg" class="outoutLogo">
  </div>

</div>

<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
  id="blobSvg1">
  <path id="blob1"
    d="M341,288.5Q341,327,315.5,402Q290,477,233.5,426.5Q177,376,140,340Q103,304,96,248Q89,192,147.5,183Q206,174,247,104.5Q288,35,359,67Q430,99,385.5,174.5Q341,250,341,288.5Z"
    fill="#E7398B"></path>
</svg>

<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
  id="blobSvg2">
  <path id="blob2"
    d="M341,288.5Q341,327,315.5,402Q290,477,233.5,426.5Q177,376,140,340Q103,304,96,248Q89,192,147.5,183Q206,174,247,104.5Q288,35,359,67Q430,99,385.5,174.5Q341,250,341,288.5Z"
    fill="#E7398B"></path>
</svg>
import { Component, OnInit } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { NotificationState } from '@modules/notifications/state/notifications.state';
import * as NOTIFICATIONS_MODELS from '@modules/notifications/models/notifications.model';
import * as NOTIFICATIONS_ACTIONS from '@modules/notifications/state/notifications.actions'
import { Router } from '@angular/router';
import { NOTIFICATION_ACTION_TYPE } from '@modules/notifications/models/notifications.config';
import { Store } from '@ngxs/store';
@Component({
  selector: 'app-outout-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  public isOpened = false;
  public notificationAction = NOTIFICATION_ACTION_TYPE;
  public scrollDistance = 1;
  public throttle = 200;

  @ViewSelectSnapshot(NotificationState.newNotificationFlag) public bellFlag: boolean
  @ViewSelectSnapshot(NotificationState.navbarNotifications) public notifications!: NOTIFICATIONS_MODELS.NotificationModel[];
  @ViewSelectSnapshot(NotificationState.unreadNotifications) public unreadNotifications: number;
  @ViewSelectSnapshot(NotificationState.NavbarNotificationLoader) public loadingSpinner: boolean;
  
  constructor(private _router: Router, private _store: Store) { }

  ngOnInit(): void {
    this._fireStartConnection();
    this._fireGetNavBarNotifications();
  }

  openAndCloseNotifications() {
    this.isOpened = !this.isOpened;
  }

  public viewNotification(notification: NOTIFICATIONS_MODELS.NotificationModel) {
    if (!notification.isRead) {
      this._store.dispatch(new NOTIFICATIONS_ACTIONS.MarkNotificationsAsRead([notification.id])).subscribe(()=>{
        this._fireGetNavBarNotifications();
      })
    }
    this._router.navigate([this.notificationAction[notification.action], notification.affectedId]).then(() => {
      this.isOpened = false;
    })
  }

  public viewAllNotifications() {
    this._router.navigate(['notifications']).then(() => {
      this.isOpened = false;
    })
  }

  public onScrollDown() {
    let paginate = this._store.selectSnapshot(NotificationState.navbarNotificationsPagination);
    paginate.pageSize += 5;
    this.firePaginateNavbarNotificationsPage(paginate);
  }

  public markAllNotificationsAsRead(){
    this._store.dispatch(new NOTIFICATIONS_ACTIONS.MarkAllNotificationsAsRead()).subscribe(()=>{
      this._fireGetNavBarNotifications()
    })
  }


  @Dispatch() private _fireStartConnection() { return new NOTIFICATIONS_ACTIONS.StartConnection() }
  @Dispatch() private _fireGetNavBarNotifications() { return new NOTIFICATIONS_ACTIONS.GetNavBarNotifications() }
  @Dispatch() public markAllAsRead() { return new NOTIFICATIONS_ACTIONS.MarkAllNotificationsAsRead() }
  @Dispatch() public markNotificationAsRead(notificationIds: string[]) { return new NOTIFICATIONS_ACTIONS.MarkNotificationsAsRead(notificationIds) }
  @Dispatch() public firePaginateNavbarNotificationsPage(pagination) { return new NOTIFICATIONS_ACTIONS.PaginateNavBarNotificationsPage(pagination )}
}

import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import * as SEARCH_FILTER_MODELS from '@modules/search-and-filter-results/models/search-and-filter-results.model';
import * as FILTER_DATA_ACTIONS from '@layouts/state/filter-data.actions';
import { FilterDataService } from "@layouts/model/filter-data.service";


export class FilterDataStateModel {
    public venuesCategories: SEARCH_FILTER_MODELS.VenueCategoryModel[];
    public eventsCategories: SEARCH_FILTER_MODELS.EventCategoryModel[];
    public offerTypes: SEARCH_FILTER_MODELS.OfferTypeModel[];
    public cities: SEARCH_FILTER_MODELS.CityModel[];


    constructor() {
        this.venuesCategories = null;
        this.eventsCategories = null;
        this.offerTypes = null;
        this.cities = null;
    }
}


@Injectable()
@State<FilterDataStateModel>({
    name: 'filterData',
    defaults: new FilterDataStateModel()
})

export class FilterDataState {
    constructor(
        private _mainService: FilterDataService
    ) { }

    @Selector() static venuesCategories(state: FilterDataStateModel): SEARCH_FILTER_MODELS.VenueCategoryModel[] {
        return state.venuesCategories
    }

    @Selector() static eventsCategories(state: FilterDataStateModel): SEARCH_FILTER_MODELS.EventCategoryModel[] {
        return state.eventsCategories
    }

    @Selector() static offerTypes(state: FilterDataStateModel): SEARCH_FILTER_MODELS.OfferTypeModel[] {
        return state.offerTypes
    }

    @Selector() static allCities(state: FilterDataStateModel): SEARCH_FILTER_MODELS.CityModel[] {
        return state.cities
    }


    /*__________________________________Filters Data Reducers_______________________________________*/
    @Action(FILTER_DATA_ACTIONS.GetVenuesCategories)
    public getVenuesCategories({ patchState }: StateContext<FilterDataStateModel>) {
        return this._mainService.getVenuesCategories().pipe(
            tap(
                (venuesCategories: SEARCH_FILTER_MODELS.VenueCategoryModel[]) => patchState({ venuesCategories })
            )
        )

    }

    @Action(FILTER_DATA_ACTIONS.GetEventsCategories)
    public getEventsCategories({ patchState }: StateContext<FilterDataStateModel>) {
        return this._mainService.getEventsCategories().pipe(
            tap(
                (eventsCategories: SEARCH_FILTER_MODELS.EventCategoryModel[]) => patchState({ eventsCategories })
            )
        )

    }

    @Action(FILTER_DATA_ACTIONS.GetOfferTypes)
    public getOfferTypes({ patchState }: StateContext<FilterDataStateModel>) {
        return this._mainService.getOfferTypes().pipe(
            tap(
                (offerTypes: SEARCH_FILTER_MODELS.OfferTypeModel[]) => patchState({ offerTypes })
            )
        )
    }

    @Action(FILTER_DATA_ACTIONS.GetAllCities)
    public getAllCities({ patchState }: StateContext<FilterDataStateModel>) {
        return this._mainService.getAllCities().pipe(
            tap(
                (cities: SEARCH_FILTER_MODELS.CityModel[]) => patchState({ cities })
            )
        )
    }
}
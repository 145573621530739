<div>

    <button mat-icon-button (click)="openAndCloseFilterForm()" matTooltip="Filter">

        <mat-icon svgIcon="filter"></mat-icon>

    </button>



    <div class="filter-backGround" (click)="isOpened = false" *ngIf="isOpened"></div>
    <section [ngClass]="{'isShown': isOpened}" class="z-50 shadow-md userControl custom-scrolling" *ngIf="isOpened">
        <div class="flex items-center justify-start">
            <mat-icon svgIcon="filter"></mat-icon>
            <p class="my-0 ml-3 text-xl font-medium text-mainFont">Filter By</p>
        </div>

        <form [formGroup]="filterForm">

            <section>

                <!--Venues Category-->
                <mat-form-field class="w-full" *appAccessControl="{permissions:['VIEW_CATEGORY_FIELD']}">
                    <mat-label>Venue Category</mat-label>
                    <mat-select matInput formControlName="venueCategories" multiple [disableOptionCentering]="true"
                        panelClass="filterSelect">

                        <mat-option *ngFor="let venue of venues" [value]="venue.id">
                            {{ venue.name }}
                        </mat-option>

                    </mat-select>

                </mat-form-field>

                <!-- Events Category -->
                <mat-form-field class="w-full">
                    <mat-label>Event Category</mat-label>
                    <mat-select matInput formControlName="eventCategories" multiple [disableOptionCentering]="true"
                        panelClass="filterSelect">

                        <mat-option *ngFor="let event of events" [value]="event.id">
                            {{ event.name }}
                        </mat-option>

                    </mat-select>

                </mat-form-field>

                <!--Featured Events-->
                <div class="w-full py-4">

                    <mat-slide-toggle formControlName="featuredEvents" labelPosition='before'
                        [checked]="filterForm.get('featuredEvents')?.value">Featured Events
                    </mat-slide-toggle>
                </div>

                <!--Offer Types-->


                <mat-form-field class="w-full" *appAccessControl="{permissions:['VIEW_DEALS_FIELD']}">
                    <mat-label>Offers</mat-label>
                    <mat-select matInput formControlName="offerTypeId" [disableOptionCentering]="true"
                        panelClass="filterSelect">

                        <mat-option *ngFor="let offer of offerTypes" [value]="offer.id">
                            {{ offer.name }}
                        </mat-option>

                    </mat-select>

                </mat-form-field>

                <!--Cities-->

                <mat-form-field class="w-full">
                    <mat-label>City</mat-label>
                    <mat-select matInput formControlName="cityId" [disableOptionCentering]="true"
                        panelClass="filterSelect">

                        <mat-option *ngFor="let city of cities" [value]="city.id">
                            {{ city.name }}
                        </mat-option>

                    </mat-select>

                </mat-form-field>


                <!--date-->

                <mat-form-field class="w-full">
                    <mat-label>Date</mat-label>
                    <mat-date-range-input [formGroup]="filterForm" [rangePicker]="picker" [min]="dateConfig.min"
                        [max]="dateConfig.max">
                        <input matStartDate formControlName="from" placeholder="Start date">
                        <input matEndDate formControlName="to" placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>



            </section>


        </form>

        <div class="flex flex-col p-6">
            <button mat-button class="action-btn" (click)="applyFilters()">
                Apply
            </button>

            <button mat-button (click)="cancel()" class="cancel-btn">
                Cancel
            </button>

        </div>
    </section>


</div>
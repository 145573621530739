import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ValidationModule } from './../../shared/modules/validation/validation.module';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { MaterialModule } from '@shared/material.module';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './auth.guard';
import { AutoLoginComponent } from './pages/autologin/autologin.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { CodeVerificationComponent } from './pages/code-verification/code-verification.component';
import { CodeInputModule } from 'angular-code-input';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from './state/auth.state';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { SplashComponent } from './pages/splash/splash.component';
import { CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [LoginComponent, LogoutComponent, AutoLoginComponent, ForgetPasswordComponent, CodeVerificationComponent, ResetPasswordComponent, SplashComponent, ChangePasswordComponent, AuthLayoutComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ValidationModule,
    CountdownModule,
    NgxsModule.forFeature([AuthState]),
    CodeInputModule.forRoot({
      codeLength: 6,
      isCharsCode: true,
      code: ''
    }),
  ],
  providers: [AuthGuard]
})
export class AuthModule { }

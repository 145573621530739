import { PermissionModel } from "./model/authorization.model";
import { SEARCH_RESULTS_PERMISSIONS } from "@modules/search-and-filter-results/search-filter-results-permissions";
import { MY_PROFILE_PERMISSIONS } from "@core/modules/user/my-profile.permissions";
import { FILTER_PERMISSIONS } from "@layouts/components/filter/filter-permissions";
import { EVENT_PERMISSIONS } from "@modules/events/events.permissions";
import { DASHBOARD_PERMISSIONS } from "@modules/dashboard/dashboard.permissions";
import { CUSTOMERS_PERMISSIONS } from "@modules/customers/customers.permissions";

export const SystemPermissions: PermissionModel[] = [
        ...MY_PROFILE_PERMISSIONS,
        ...FILTER_PERMISSIONS,
        ...SEARCH_RESULTS_PERMISSIONS,
        ...EVENT_PERMISSIONS,
        ...DASHBOARD_PERMISSIONS,
        ...CUSTOMERS_PERMISSIONS

];

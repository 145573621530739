import { Store } from '@ngxs/store';
import { Component, OnInit, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { Router } from "@angular/router";
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { HideSpinner, ShowSpinner } from '@core/modules/spinner/state/spinner.actions';
import { Logout } from '@core/auth/state/auth.actions';
import { OutOutConfigInst } from 'src/app/config/app.config';
import { ModalsService } from '@shared/modules/modals/model/modals.service';
@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        opacity: 1
      })),
      state('closed', style({
        opacity: 0
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class NavItemComponent {
  @Input() link?: string;
  @Input() action?: string;
  @Input() icon!: string;
  @Input() label!: string;
  @Input() current: any;
  @Input() children: any;
  @Input() iconsOnly: any;
  @Input() queries!: object;
  @Input() materialIcon!: { isSvg: boolean; name: string; inline?: boolean };
  public collapsed: boolean = true;


  constructor(private _router: Router, private _store: Store, private _modals: ModalsService,) { }


  public ShowChildren() {
    this.collapsed = !this.collapsed
  }
  public confirmLogout() {
    this._modals.openConfirmationDialog({
      title: OutOutConfigInst.CRUD_CONFIG.actions.logout,
      class: 'danger',
      content: OutOutConfigInst.CRUD_CONFIG.confirmationMessages.logout(),
      proceedText: OutOutConfigInst.CRUD_CONFIG.actions.logout,
      cancelText: OutOutConfigInst.CRUD_CONFIG.actions.cancel,
      titleClasses:['text-center']
    }, () => this._router.navigate([OutOutConfigInst.ROUTES_CONFIG.logout]))
  }

  public hideSpinnerOnKeyboardEvents($event: KeyboardEvent) {
    if ($event && ($event.ctrlKey || $event.shiftKey)) {
      setTimeout(() => {
        this._store.dispatch(new HideSpinner())
      }, 300)
    }
  }

  @Dispatch() public showLoader() { return new ShowSpinner }

}

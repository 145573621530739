import { authorizedRoleGuard } from './auth/authorizedRole.guard';
import { SuperAdminGuard } from './modules/authorization/guards/superAdmin.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { SpinnerModule } from './modules/spinner/spinner.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpService } from './http/http/http.service';
import { HeadersInterceptor } from "./http/interceptors/headers-interceptor";
import { ErrorHandlerInterceptor } from "./http/interceptors/error-handler.interceptor";
import { ForbiddenComponent } from "./modules/authorization/forbidden/forbidden.component";
import { authModuleConfig } from "./auth/config/oauth-module.config";
import { AuthGuard } from "./auth/auth.guard";
import { SomethingWentWrongComponent } from './components/something-went-wrong/something-went-wrong.component';
import { VenueAdminGuard } from './modules/authorization/guards/venueAdmin.guard';
import { EventAdminGuard } from './modules/authorization/guards/event-admin.guard';
import { SyncHelperGuard } from './auth/syncHelper.guard';


@NgModule({
  declarations: [ForbiddenComponent, SomethingWentWrongComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    SpinnerModule,
  ],
  exports: [SpinnerModule],
  providers: [
    AuthGuard,
    SuperAdminGuard,
    authorizedRoleGuard,
    VenueAdminGuard,
    EventAdminGuard,
    SyncHelperGuard,
    {
      provide: HttpService,
      useClass: HttpService
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
  ]
})
export class CoreModule {}

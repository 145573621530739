import { FiltrationModel } from './../../../feature-modules/search-and-filter-results/models/search-and-filter-results.model';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SearchFilterResultsState } from '@modules/search-and-filter-results/state/search-and-filter-results.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import * as SEARCH_FILTER_MODELS from '@modules/search-and-filter-results/models/search-and-filter-results.model';
import * as SEARCH_FILTER_ACTIONS from '@modules/search-and-filter-results/state/search-and-filter-results.actions';
import * as FILTER_DATA_ACTIONS from '@layouts/state/filter-data.actions';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Router } from '@angular/router';
import { provideReactiveFormGetters } from '@shared/helpers/provide-reactive-form-getters.helper';
import { Observable } from 'rxjs';
import { FilterDataState } from '@layouts/state/filter-data.state';
import { RouterState } from '@ngxs/router-plugin';

@Component({
  selector: 'app-outout-filter',
  templateUrl: './filter.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  constructor(
    private _formBuilder: FormBuilder,
    private _store: Store,
    private _router: Router
  ) {
    this._initForm();

  }

  @ViewSelectSnapshot(FilterDataState.venuesCategories) public venues!: SEARCH_FILTER_MODELS.VenueCategoryModel[];
  @ViewSelectSnapshot(FilterDataState.eventsCategories) public events!: SEARCH_FILTER_MODELS.EventCategoryModel[];
  @ViewSelectSnapshot(FilterDataState.offerTypes) public offerTypes!: SEARCH_FILTER_MODELS.OfferTypeModel[];
  @ViewSelectSnapshot(FilterDataState.allCities) public cities!: SEARCH_FILTER_MODELS.CityModel[];
  @Select(SearchFilterResultsState.filtration) private _filter$!: Observable<SEARCH_FILTER_MODELS.FiltrationModel>;

  public isOpened = false;
  public filterForm!: FormGroup;
  public formControls!: { [control: string]: AbstractControl | FormControl };
  public dateConfig: { min: Date, max: Date } = {
    min: new Date(),
    max: new Date(new Date().setFullYear(new Date().getFullYear() + 2))
  }


  ngOnInit(): void {
    this._fireGetVenuesCategories()
    this._fireGetEventsCategories()
    this._fireGetOfferTypes()
    this._fireGetCities()

  }

  private _initForm() {

    this.filterForm = this._formBuilder.group({
      venueCategories: [null],
      eventCategories: [null],
      featuredEvents: [false],
      offerTypeId: [''],
      cityId: [''],
      from: [''],
      to: [''],
    })
    this.formControls = provideReactiveFormGetters(this.filterForm, '');
    this._filter$.subscribe((filter) => {

      this.filterForm.patchValue(
        {
          ...filter,
          to: this._handleFormDate(filter.to)
        }
      );

    })


  }

  public openAndCloseFilterForm() {
    this.isOpened = !this.isOpened;
    this._filter$.subscribe((filter) => {

      this.filterForm.patchValue(
        {
          ...filter,
          to: this._handleFormDate(filter.to)
        }
      );
    })
  }

  public cancel() {
    this.isOpened = false;
    const filterInitialValues: FiltrationModel = {
      searchQuery: '',
      venueCategories: [],
      eventCategories: [],
      featuredEvents: false,
      offerTypeId: '',
      cityId: '',
      from: null,
      to: null
    }
    this._store.dispatch(new SEARCH_FILTER_ACTIONS.Filter(filterInitialValues))
  }

  private _switchToUTC(value: Date) {
    if (value) {

      const date = new Date(value);
      const date_UTC = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1,
        date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return new Date(date_UTC)
    }
    return null
  }
  //subtract 1 from state value
  private _handleFormDate(value: Date) {
    if (value) {

      const date = new Date(value);
      const date_UTC = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - 1,
        date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return new Date(date_UTC)
    }
    return null
  }

  public applyFilters() {
    const filter: SEARCH_FILTER_MODELS.FiltrationModel = {
      ...this.filterForm.getRawValue(),
      featuredEvents: this.formControls.featuredEvents.value ? true : false,
      to: this._switchToUTC(this.formControls.to.value)
    };
    this._store.dispatch(new SEARCH_FILTER_ACTIONS.Filter(filter)).subscribe(
      () => {

        if (this._store.selectSnapshot(RouterState).state.url !== '/result') this._router.navigate(['result']);
        this.isOpened = false
      }
    )
  }

  /*_____________________________________Fired Actions____________________________________*/

  @Dispatch() private _fireGetVenuesCategories() { return new FILTER_DATA_ACTIONS.GetVenuesCategories() }
  @Dispatch() private _fireGetEventsCategories() { return new FILTER_DATA_ACTIONS.GetEventsCategories() }
  @Dispatch() private _fireGetOfferTypes() { return new FILTER_DATA_ACTIONS.GetOfferTypes() }
  @Dispatch() private _fireGetCities() { return new FILTER_DATA_ACTIONS.GetAllCities() }
}

import { RecoursesServerConfig,  } from 'src/app/config/servers.config';

export const environment = {
  production: true,
  apiUrl: RecoursesServerConfig.STAGING_APIs_URL,
  notificationApiUrl:RecoursesServerConfig.NOTIFICATION_API_URL,
  version: 'OutOut-0.3.0',
  disableLogs: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCW-VRLhkictuoMdxVOtGlH-giggtTbS7Y',
    authDomain: 'out-out-staging.firebaseapp.com',
    databaseURL: 'https://out-out-staging-default-rtdb.firebaseio.com',
    projectId: 'out-out-staging',
    storageBucket: 'out-out-staging.appspot.com',
    messagingSenderId: '215122111385',
    appId: '1:215122111385:web:59c20839ce21283dcb0108',
    measurementId: 'G-5TTFP7R03L',
  },
};

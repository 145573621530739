export const NOTIFICATION_ACTION_TYPE = {
        7: false,
        8: false,
        10: false,
        14: 'venues/waiting',
        15: 'events/view-waiting-approval',
        16: 'bookings/venue/view',
        17: 'bookings/event/view',
        18: 'venues/view',
        19: 'events/view',
        20: false,
        21: false,
        22: false,
        23: false,
        24: false,
        25: 'venues/waiting',
        26: 'venues/waiting',
}

export const NOTIFICATIONS_TYPES = {
        0: 'Not specified',
        1: 'Venue booking reminder',
        2: 'Event booking reminder',
        3: 'New venue in your area',
        4: 'New event',
        5: 'New offer',
        6: 'Not using app in a while',
        7: 'Venue booking confirmation',
        8: 'Venue booking rejection',
        9: 'Ticket transfer',
        10: 'Event booking rejection',
        11: 'Deactivate loyalty',
        12: 'Deactivate deal',
        13: 'Deactivate venue',
        14: 'Venue waiting for approval',
        15: 'Event waiting for approval',
        16: 'New venue booking',
        17: 'New event booking',
        18: 'Venues',
        19: 'Events',
        20: 'New category',
        21: 'Terms and conditions',
        22: 'Customer profile',
        23: 'Delete venue',
        24: 'Delete event',
        25: 'Unassign loyalty',
        26: 'Unassign offer'
}

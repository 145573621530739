import { MyProfileDetailsModel } from './user.model';
import { Injectable } from '@angular/core';
import { ApiResponse, PaginationModel } from '@core/http/apis.model';
import { HttpService } from '@core/http/http/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as USER_MODELS from '@core/modules/user/model/user.model'
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { buildQueryString } from '@shared/helpers/build-query-string.helper';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private _http: HttpService
  ) { }

  private _endpoint = 'profiles';
  private _ADMIN_PROFILE_ENDPOINT_BASE = 'AdminProfile'

  public updatedMyProfileDetails(myProfile: MyProfileDetailsModel) {
    return this._http.post(`${this._ADMIN_PROFILE_ENDPOINT_BASE}/UpdateMyAccountInfo`, myProfile)
  }

  public getMyProfileDetails(): Observable<USER_MODELS.MyProfileDetailsModel> {
    return this._http.fetch(`${this._ADMIN_PROFILE_ENDPOINT_BASE}/GetMyAccountInfo`).pipe(
      map(
        (res: ApiResponse<USER_MODELS.MyProfileDetailsModel>) => res.result
      )
    )
  }


  public getVenuesCreatedByMe(pagination: PaginationConfigModel): Observable<PaginationModel<USER_MODELS.VenueCreatedByMeModel>> {
    return this._http.fetch(`${this._ADMIN_PROFILE_ENDPOINT_BASE}/GetVenuesCreatedByMe${buildQueryString(pagination)}`).pipe(
      map(
        (res: ApiResponse<PaginationModel<USER_MODELS.VenueCreatedByMeModel>>) => res.result
      )
    )
  }

  public getEventsCreatedByMe(pagination: PaginationConfigModel): Observable<PaginationModel<USER_MODELS.EventsCreatedByMeModel>> {
    return this._http.fetch(`${this._ADMIN_PROFILE_ENDPOINT_BASE}/GetEventsCreatedByMe${buildQueryString(pagination)}`).pipe(
      map(
        (res: ApiResponse<PaginationModel<USER_MODELS.EventsCreatedByMeModel>>) => res.result
      )
    )
  }


  // public GetMyUserInfo(): Observable<USER_MODELS.loggedInUserModel> {
  //   return this._http.fetch('Auth/GetMyUserInfo').pipe(
  //     map((res: ApiResponse<USER_MODELS.loggedInUserModel>) => res.result)
  //   )
  // }



}



<outout-auth-layout
name="Change Password"
wrapperClassList="w-full md:w-2/3 xl:w-1/2"
>
  <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()" class="w-full">
    <div class="mb-4 form-group">
      <label for="oldPassword" class="block pb-2">
       Old Password
        <span class="text-red-700">*</span>
      </label>
      <mat-form-field appearance="outline" class="w-full">
        <input
          matInput
          type="password"
          placeholder="Old Paswword"
          id="oldPassword"
          formControlName="oldPassword"
          [type]="oldHide ? 'password' : 'text'"
        />
        <mat-icon matPrefix>vpn_key</mat-icon>
        <button
          mat-icon-button
          type="button"
          matSuffix
          (click)="oldHide = !oldHide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="oldHide"
        >
          <mat-icon>{{
            oldHide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>
      <mat-error class="pl-5">
        <app-validation-error [control]="changePasswordForm.get('oldPassword')" businessName="Old Password">
        </app-validation-error>
      </mat-error>
    </div>
    <div class="mb-4 form-group">
      <label for="newPassword" class="block pb-2">
        New Password
        <span class="text-red-700">*</span>
      </label>
      <mat-form-field appearance="outline" class="w-full">
        <input
          matInput
          type="password"
          placeholder="New Paswword"
          id="password"
          formControlName="newPassword"
          [type]="hide ? 'password' : 'text'"
        />
        <mat-icon matPrefix>vpn_key</mat-icon>
        <button
          mat-icon-button
          type="button"
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>
      <mat-hint>
        Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
      </mat-hint>
      <mat-error class="pl-5">
        <app-validation-error [control]="changePasswordForm.get('newPassword')" businessName="New Password">
        </app-validation-error>
      </mat-error>
    </div>
    <div class="mb-4 form-group">
      <label for="confirmPassword" class="block pb-2">
        Confirm Password
        <span class="text-red-700">*</span>
      </label>
      <mat-form-field appearance="outline" class="w-full">
        <input
          matInput
          type="password"
          placeholder="Confirm Paswword"
          id="confirmPassword"
          [type]="confirm_hide ? 'password' : 'text'"
          formControlName="confirmPassword"
        />
        <mat-icon matPrefix>vpn_key</mat-icon>
        <button
          mat-icon-button
          type="button"
          matSuffix
          (click)="confirm_hide = !confirm_hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="confirm_hide"
        >
          <mat-icon>{{
            confirm_hide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>

      <mat-error class="pl-5">
        <app-validation-error [control]="changePasswordForm.get('confirmPassword')" businessName="Confirm Password">
        </app-validation-error>
      </mat-error>
    </div>
    <div class="float-right mt-6">
      <button mat-flat-button class="px-12 py-3 font-bold rounded-full action-btn" [disabled]="changePasswordForm.invalid">Change Password</button>
    </div>
  </form>
</outout-auth-layout>

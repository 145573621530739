import { Injectable } from '@angular/core';
import { PaginationModel } from '@core/http/apis.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../models/notifications.service';
import * as NOTIFICATIONS_MODELS from '@modules/notifications/models/notifications.model';
import * as NOTIFICATIONS_ACTIONS from './notifications.actions'

export class NotificationsStateModel {
	public allNotificationsPage: NOTIFICATIONS_MODELS.NotificationModel[];
	public allNotificationsPagePagination: PaginationConfigModel;
	public newNotification: NOTIFICATIONS_MODELS.NotificationModel;
	public newNotificationFlag: boolean;
	public navbarNotifications: NOTIFICATIONS_MODELS.NotificationModel[];
	public navbarNotificationsPagination: PaginationConfigModel;
	public unreadNotifications: number;
	public NavbarNotificationLoader: boolean;

	constructor() {
		this.allNotificationsPage = null;
		this.allNotificationsPagePagination = {
			pageNumber: 0,
			pageSize: 10
		}
		this.newNotification = null;
		this.newNotificationFlag = false;
		this.navbarNotifications = null;
		this.navbarNotificationsPagination = {
			pageNumber: 0,
			pageSize: 5
		}
		this.unreadNotifications = 0;
		this.NavbarNotificationLoader = false
	}
}

@Injectable()
@State<NotificationsStateModel>({
	name: 'notifications',
	defaults: new NotificationsStateModel(),
})

export class NotificationState {
	constructor(private _mainService: NotificationService) { }

	@Selector() static allNotificationsPage(state: NotificationsStateModel): NOTIFICATIONS_MODELS.NotificationModel[] { return state.allNotificationsPage };
	@Selector() static allNotificationPagePagination(state: NotificationsStateModel): PaginationConfigModel { return { ...state.allNotificationsPagePagination } };
	@Selector() static newNotificationFlag(state: NotificationsStateModel): boolean { return state.newNotificationFlag };
	@Selector() static NewNotification(state: NotificationsStateModel): NOTIFICATIONS_MODELS.NotificationModel { return state.newNotification };
	@Selector() static navbarNotifications(state: NotificationsStateModel): NOTIFICATIONS_MODELS.NotificationModel[] { return state.navbarNotifications };
	@Selector() static navbarNotificationsPagination(state: NotificationsStateModel): PaginationConfigModel { return { ...state.navbarNotificationsPagination } };
	@Selector() static unreadNotifications(state: NotificationsStateModel): number { return state.unreadNotifications }
	@Selector() static NavbarNotificationLoader(state: NotificationsStateModel): boolean { return state.NavbarNotificationLoader }
	/*_____________________________Reducers___________________________________*/

	@Action(NOTIFICATIONS_ACTIONS.StartConnection)
	public StartConnection() {
		this._mainService.startConnection();
	}

	@Action(NOTIFICATIONS_ACTIONS.GetAllNotificationsPage)
	public GetAllNotificationsPage({
		getState,
		patchState
	}: StateContext<NotificationsStateModel>) {
		const { allNotificationsPagePagination: { pageNumber, pageSize }, } = getState();
		return this._mainService.GetMyNotificationsForAdmin({ pageNumber, pageSize }).pipe(
			tap(
				({
					records,
					recordsTotalCount,
					totalPages,
					pageNumber,
					pageSize,
				}: PaginationModel<NOTIFICATIONS_MODELS.NotificationModel>) => {
					patchState({
						allNotificationsPage: records,
						allNotificationsPagePagination: {
							totalPages,
							recordsTotalCount,
							pageSize,
							pageNumber,
						},
					});
				}
			)

		)
	}

	@Action(NOTIFICATIONS_ACTIONS.PaginateAllNotificationsPage)
	public PaginateAllNotificationsPage(
		{ dispatch, patchState, getState }: StateContext<NotificationsStateModel>,
		{ pagination }: NOTIFICATIONS_ACTIONS.PaginateAllNotificationsPage
	) {
		patchState({
			allNotificationsPagePagination: {
				...getState().allNotificationsPagePagination,
				pageNumber: pagination.pageNumber
			}
		});
		dispatch(new NOTIFICATIONS_ACTIONS.GetAllNotificationsPage())
	}

	@Action(NOTIFICATIONS_ACTIONS.GetNavBarNotifications)
	public GetNavBarNotifications({
		getState,
		patchState
	}: StateContext<NotificationsStateModel>) {
		const { navbarNotificationsPagination: { pageNumber, pageSize }, } = getState();
		patchState({NavbarNotificationLoader: true})
		return this._mainService.GetMyNotificationsForAdmin({ pageNumber, pageSize }).pipe(
			tap(
				({
					records,
					recordsTotalCount,
					totalPages,
					pageNumber,
					pageSize,
					unReadNotificationsCount
				}: PaginationModel<NOTIFICATIONS_MODELS.NotificationModel>) => {
					patchState({
						navbarNotifications: records,
						navbarNotificationsPagination: {
							totalPages,
							recordsTotalCount,
							pageSize,
							pageNumber,
						},
						unreadNotifications: unReadNotificationsCount,
						newNotificationFlag: unReadNotificationsCount > 0 ? true : false,
						NavbarNotificationLoader: false
					});
				}
			)

		)
	}

	@Action(NOTIFICATIONS_ACTIONS.PaginateNavBarNotificationsPage)
	public PaginateNavBarNotificationsPage(
		{ dispatch, patchState, getState }: StateContext<NotificationsStateModel>,
		{ pagination }: NOTIFICATIONS_ACTIONS.PaginateNavBarNotificationsPage
	) {
		patchState({
			navbarNotificationsPagination: {
				...getState().navbarNotificationsPagination,
				...pagination
			}
		});
		dispatch( new NOTIFICATIONS_ACTIONS.GetNavBarNotifications() )
	}


	@Action(NOTIFICATIONS_ACTIONS.MarkAllNotificationsAsRead)
	public MarkAllNotificationsAsRead({
		patchState, getState, dispatch
	}: StateContext<NotificationsStateModel>) {
		return this._mainService.markAllNotificationsAsRead().pipe(
			tap((res: boolean)=> res)
		)
		// dispatch(new NOTIFICATIONS_ACTIONS.MarkNotificationsAsRead(accountIds.map(notification=> notification.id)))
	
	}

	@Action(NOTIFICATIONS_ACTIONS.NewNotification)
	public NewNotification({
		getState, patchState
	}: StateContext<NotificationsStateModel>, { notification }: NOTIFICATIONS_ACTIONS.NewNotification) {
		patchState({
			newNotificationFlag: true,
			navbarNotifications: [notification, ...getState().navbarNotifications],
			unreadNotifications: getState().unreadNotifications + 1
		})
	}

	@Action(NOTIFICATIONS_ACTIONS.MarkNotificationsAsRead)
	public MarkNotificationsAsRead({ dispatch }: StateContext<NotificationsStateModel>, { notificationIds }: NOTIFICATIONS_ACTIONS.MarkNotificationsAsRead) {
		return this._mainService.markNotificationsAsRead(notificationIds).pipe(
			tap((res: boolean)=> res)
		)
	}

}
import { TicketDetailsModel } from "../model/authorization.model";

export class InstallPermissions {
  static readonly type = '[AUTHORIZATION] Install Permissions';
  constructor(public roles: string[]) {}
}


export class LoadSystemRoles {
  static readonly type = '[AUTHORIZATION] Load System Roles';
}

export class SetGrantedRoles {
  static readonly type = '[AUTHORIZATION] Set Granted System Roles';
  constructor(public roles: string[]) {}
}

export class SetTicketDetails {
  static readonly type = '[AUTHORIZATION] Set Ticket Details';
  constructor(public ticketDetails: TicketDetailsModel) { }
}

import { Injectable } from '@angular/core';
import { ApiResponse, PaginationModel } from '@core/http/apis.model';
import { HttpService } from '@core/http/http/http.service';
import * as DEALS_MODELS from '@modules/deals/models/deals.model';
import { buildQueryString } from '@shared/helpers/build-query-string.helper';
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DealsService {

  constructor(private _http: HttpService
  ) { }

  private _ENDPOINT_BASE = 'Offer';
  private _VENUE_ENDPOINT_BASE = 'Venue';

  public getAssignedDealsPage(pagination: PaginationConfigModel, body: DEALS_MODELS.DealsRequestBodyModel): Observable<PaginationModel<DEALS_MODELS.DealModel>> {
    return this._http.post(`${this._ENDPOINT_BASE}/GetAssignedOffersPage${buildQueryString(pagination)}`, body).pipe(
      map(
        (res: ApiResponse<PaginationModel<DEALS_MODELS.DealModel>>) => res.result
      )
    )
  }

  public getActiveVenues(): 
  Observable<DEALS_MODELS.VenueModel[]> {
    return this._http.post(`${this._VENUE_ENDPOINT_BASE}/GetActiveAccessibleVenues `).pipe(
      map(
        (res: ApiResponse<DEALS_MODELS.VenueModel[]>) => res.result
      )
    )
  }
  
  public GetDealTypes(): 
  Observable<DEALS_MODELS.DealTypeModel[]> {
    return this._http.fetch(`${this._ENDPOINT_BASE}/GetOfferTypes`).pipe(
      map(
        (res: ApiResponse<DEALS_MODELS.DealTypeModel[]>) => res.result
      )
    )
  }

  public assignDeal(deal: FormData):Observable<boolean>
  {
    return this._http.post(`${this._ENDPOINT_BASE}/AssignOfferToVenue`,deal)
    .pipe(map((res: ApiResponse<boolean>) => res.result))

  }
  public updatedAssignedDeal(dealData:{dealId:string, deal:FormData}) : Observable<boolean>
  {
    return this._http.post(
      `${this._ENDPOINT_BASE}/UpdateAssignedOffer${buildQueryString({offerId: dealData.dealId })}`,
      dealData.deal)
    .pipe(map((res: ApiResponse<boolean>) => res.result))

  }

  public unAssignDealFromVenue(venueId: string, offerId: string): Observable<boolean> {
    return this._http.post(`${this._ENDPOINT_BASE}/UnAssignOfferFromVenue${buildQueryString({ venueId, offerId })}`).pipe(
      map(
        (res: ApiResponse<boolean>) => res.result
      )
    )
  }

  public unassignAllDeals(): Observable<boolean> {
    return this._http.fetch(`${this._ENDPOINT_BASE}/UnAssignAllOffers`).pipe(
      map(
        (res: ApiResponse<boolean>) => res.result
      )
    )
  }

  public getAssignedDeal (offerId:string): Observable<DEALS_MODELS.AssignedDealModel>
  {
    return this._http.fetch(`${this._ENDPOINT_BASE}/GetAssignedOffer${buildQueryString({offerId:offerId})}`).pipe(
      map((res: ApiResponse<DEALS_MODELS.AssignedDealModel>)=>res.result)
    );
  }
}

import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';

export class GetAllEventsPage {
  static readonly type = '[EVENTS] Get All Events Page';
}
``;
export class PaginateAllEventsPage {
  static readonly type = '[EVENTS] Paginate All Events Page';
  constructor(public pagination: PaginationConfigModel) {}
}

export class SortAllEventsPage {
  static readonly type = '[EVENTS] Sort All Events Page';
  constructor(public sortBy: number) {}
}

export class SearchAllEventsPage {
  static readonly type = '[EVENTS] Search All Events Page';
  constructor(public searchQuery: string) {}
}

export class DeleteEvent {
  static readonly type = '[EVENTS] Delete Event';
  constructor(public id: string) {}
}

export class GetActiveCategories {
  static readonly type = '[EVENTS] Get Active Categories';
  constructor(public typeFor: number) {}
}

export class GetActiveCities {
  static readonly type = '[EVENTS] Get Active Cities';
}
export class GetActiveVenues {
  static readonly type = '[EVENTS] Get Active Venues';
}

export class CreateEvent {
  static readonly type = '[EVENTS] Create Event';
  constructor(public event: FormData) {}
}

export class GetEvent {
  static readonly type = '[EVENTS] Get Event';
  constructor(public id: string) {}
}

export class UpdateEvent {
  static readonly type = '[EVENTS] Update Event';
  constructor(public id: string, public event: FormData) {}
}


export class GetEventDetails {
  static readonly type = '[EVENTS] Get Event Details'
  constructor(public id: string) { }
}

export class UpdateIsFeaturedForEvent {
  static readonly type = '[EVENTS] Update IsFeatured For Event'
  constructor(public id: string, public isFeatured: boolean) { }
}

export class UpdateEventCode {
  static readonly type = '[EVENTS] Update Event Code'
  constructor(public id: string, public code: string) { }
}

export class GetCustomerOrdersForEvent {
  static readonly type = '[EVENTS] Get Customer Orders For Event'
}

export class PaginateCustomerOrders {
  static readonly type = '[EVENTS] Paginate Customer Orders';
  constructor(public pagination: PaginationConfigModel) {}
}

export class SortCustomerOrders {
  static readonly type = '[EVENTS] Sort Customer Orders';
  constructor(public customerOrdersSortBy: number) {}
}

export class SearchCustomerOrders {
  static readonly type = '[EVENTS] Search Customer Orders';
  constructor(public customerOrdersSearchQuery: string) {}
}

export class RejectEventBooking{
  static readonly type = '[Events] Reject Event Booking';
  constructor(public eventBookingId: string) {}
}
export class GetWaitingApprovalEvents{
  static readonly type = '[Events] Get Waiting Approval Events'
}

export class PaginateWaitingApprovalEvents {
  static readonly type = '[Events] Paginate Waiting Approval Events';
  constructor(public pagination: PaginationConfigModel) {}
}

export class SortWaitingApprovalEvents {
  static readonly type = '[Events] Sort Waiting Approval Events';
  constructor(public waitingApprovalEventsSortBy: number) {}
}

export class SearchWaitingApprovalEvents {
  static readonly type = '[Events] Search Waiting Approval Events';
  constructor(public waitingApprovalEventsSearchQuery: string) {}
}

export class ApproveWaitingEvent {
  static readonly type = '[Events] Approve Waiting Event';
  constructor(public id: string) {}
}

export class RejectWaitingEvent {
  static readonly type = '[Events] Reject Waiting Event';
  constructor(public id: string) {}
}

export class GetWaitingApprovalEventDetails {
  static readonly type = '[Events] Get Waiting Approval Event Details';
  constructor(public id: string) { }
}

export class GetVenueAdminWaitingApprovalEvents {
  static readonly type = '[Events] Get Venue Admin Waiting Approval Events';
}
export class PaginateVenueAdminWaitingApprovalEvents {
  static readonly type = '[Events] Paginate Venue Admin Waiting Approval Events';
  constructor(public pagination: PaginationConfigModel) {}
}

export class SortVenueAdminWaitingApprovalEvents {
  static readonly type = '[Events] Sort Venue Admin Waiting Approval Events';
  constructor(public venueAdminWaitingApprovalEventsSortBy: number) {}
}

export class SearchVenueAdminWaitingApprovalEvents {
  static readonly type = '[Events] Search Venue Admin Waiting Approval Events';
  constructor(public venueAdminWaitingApprovalEventsSearchQuery: string) {}
}

export class DeleteWaitingApprovalEventRequest {
  static readonly type = '[Events] Delete Waiting Approval Event Request';
  constructor(public requestId: string) {}
}

import { Injectable } from '@angular/core';
import { ApiResponse, PaginationModel } from '@core/http/apis.model';
import { HttpService } from '@core/http/http/http.service';
import * as VENUES_MODELS from '@modules/venues/models/venues.model';
import { buildQueryString } from '@shared/helpers/build-query-string.helper';
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VenuesService {

  constructor(private _http: HttpService) { }

  private _ENDPOINT_BASE = 'Venue';
  private _VENUE_REQUEST_ENDPOINT_BASE = 'VenueRquest';

  public getActiveCategories(typeFor: number): Observable<VENUES_MODELS.ActiveCategory> {
    return this._http.fetch(`Category/GetActiveCategories${buildQueryString({ typeFor })}`).pipe(
      map(
        (res: ApiResponse<VENUES_MODELS.ActiveCategory>) => res.result
      )
    )
  }

  public getAllEvents(): Observable<any> {
    return this._http.post(`Event/GetAllEvents`).pipe(map((res: ApiResponse<any>) => res.result))
  }

  public getAllEventsNotAssociatedToVenue(venueId:string):Observable<any>{
    return this._http.post(`Event/GetAllEventsNotAssociatedToVenue${buildQueryString({venueId})}`).pipe(map((res: ApiResponse<any>) => res.result))
  }

  public getActiveCities(): Observable<any> {
    return this._http.fetch('City/GetActiveCities').pipe(map((res: ApiResponse<any>) => res.result))
  }

  public getActiveTermsAndConditions(): Observable<VENUES_MODELS.ActiveTermsAndConditions> {
    return this._http.fetch(`TermsAndConditions/GetActiveTermsAndConditions`).pipe(map((res: ApiResponse<VENUES_MODELS.ActiveTermsAndConditions>) => res.result))
  }

  public getVenuesPage(pagination: PaginationConfigModel, body: VENUES_MODELS.VenuesRequestBodyModel): Observable<PaginationModel<VENUES_MODELS.VenueModel>> {
    return this._http.post(`${this._ENDPOINT_BASE}/GetVenuesPage${buildQueryString(pagination)}`, body).pipe(
      map(
        (res: ApiResponse<PaginationModel<VENUES_MODELS.VenueModel>>) => res.result
      )
    )
  }

  public deleteVenue(id: string): Observable<boolean> {
    return this._http.post(`${this._ENDPOINT_BASE}/DeleteVenue${buildQueryString({ id })}`).pipe(
      map(
        (res: ApiResponse<boolean>) => res.result
      )
    )
  }

  public createVenue(venue: FormData): Observable<boolean> {
    return this._http.post(`${this._ENDPOINT_BASE}/CreateVenue`, venue).pipe(map((res: ApiResponse<boolean>) => res.result))
  }

  public getVenue(id: string): Observable<VENUES_MODELS.VenueModel> {
    return this._http.fetch(`${this._ENDPOINT_BASE}/GetVenue${buildQueryString({ id })}`).pipe(map((res: ApiResponse<VENUES_MODELS.VenueModel>) => res.result))
  }

  public updateVenue(id: string, venue: FormData): Observable<boolean> {
    return this._http.post(`${this._ENDPOINT_BASE}/UpdateVenue${buildQueryString({ id })}`, venue).pipe(map((res: ApiResponse<boolean>) => res.result))
  }

  public getVenueTermsAndConditions(id: string): Observable<VENUES_MODELS.ActiveTermsAndConditions> {
    return this._http.fetch(`${this._ENDPOINT_BASE}/GetVenueTermsAndConditions${buildQueryString({ venueId: id })}`).pipe(map((res: ApiResponse<VENUES_MODELS.ActiveTermsAndConditions>) => res.result))
  }

  public getVenueDetails(id: string): Observable<VENUES_MODELS.VenueDetailsModel> {
    return this._http.fetch(`${this._ENDPOINT_BASE}/GetVenueDetailsForAdmin${buildQueryString({ id })}`).pipe(
      map(
        (res: ApiResponse<VENUES_MODELS.VenueDetailsModel>) => res.result
      )
    )
  }

  public getUpcomingEventsPageInVenue(pagination: PaginationConfigModel, id: string): Observable<PaginationModel<VENUES_MODELS.UpcomingEventModel>> {
    return this._http.fetch(`${this._ENDPOINT_BASE}/GetUpcomingEventsPageInVenue${buildQueryString({ ...pagination, id })}`).pipe(
      map(
        (res: ApiResponse<PaginationModel<VENUES_MODELS.UpcomingEventModel>>) => res.result
      )
    )
  }

  public getOffersPageInVenue(pagination: PaginationConfigModel, id: string): Observable<PaginationModel<VENUES_MODELS.OfferModel>> {
    return this._http.fetch(`${this._ENDPOINT_BASE}/GetOffersPageInVenue${buildQueryString({ ...pagination, id })}`).pipe(
      map(
        (res: ApiResponse<PaginationModel<VENUES_MODELS.OfferModel>>) => res.result
      )
    )
  }

  public updateVenueCode(id: string, code: string): Observable<boolean> {
    return this._http.post(`${this._ENDPOINT_BASE}/UpdateVenueCode${buildQueryString({ id, code })}`).pipe(map((res: ApiResponse<boolean>) => res.result))
  }

  public updateVenueTermsAndConditions(venueId: string, selectedTermsAndConditions: string[]): Observable<boolean> {
    return this._http.post(`${this._ENDPOINT_BASE}/UpdateVenueTermsAndConditions${buildQueryString({ venueId })}`, selectedTermsAndConditions).pipe(map((res: ApiResponse<boolean>) => res.result))
  }

  public deleteGalleryImage(id: string, images: string[]): Observable<boolean> {
    return this._http.post(`${this._ENDPOINT_BASE}/DeleteGalleryImages${buildQueryString({ id })}`, images).pipe(
      map(
        (res: ApiResponse<boolean>) => res.result
      )
    )
  }

  public getAllWaitingForApprovalVenuesPage(pagination: PaginationConfigModel, body: VENUES_MODELS.VenuesRequestBodyModel): Observable<PaginationModel<VENUES_MODELS.WaitingForApprovalVenuesModel>> {
    return this._http.post(`${this._VENUE_REQUEST_ENDPOINT_BASE}/GetVenuesAwaitingApproval${buildQueryString(pagination)}`, body).pipe(
      map(
        (res: ApiResponse<PaginationModel<VENUES_MODELS.WaitingForApprovalVenuesModel>>) => res.result
      )
    )
  }

  public getAllWaitingForApprovalVenuesPageForVenueAdmin(pagination: PaginationConfigModel, body: VENUES_MODELS.VenuesRequestBodyModel): Observable<PaginationModel<VENUES_MODELS.WaitingForApprovalVenuesModel>> {
    return this._http.post(`${this._VENUE_REQUEST_ENDPOINT_BASE}/GetMyVenuesAwaitingApproval${buildQueryString(pagination)}`, body).pipe(
      map(
        (res: ApiResponse<PaginationModel<VENUES_MODELS.WaitingForApprovalVenuesModel>>) => res.result
      )
    )
  }

  public approveVenue(requestId: string): Observable<boolean> {
    return this._http.fetch(`${this._ENDPOINT_BASE}/ApproveVenue${buildQueryString({ requestId })}`).pipe(map((res: ApiResponse<boolean>) => res.result))
  }

  public rejectVenue(id: string): Observable<boolean> {
    return this._http.fetch(`${this._ENDPOINT_BASE}/RejectVenue${buildQueryString({ id })}`).pipe(map((res: ApiResponse<boolean>) => res.result))
  }

  public deleteVenueRequest(requestId: string): Observable<boolean> {
    return this._http.post(`${this._VENUE_REQUEST_ENDPOINT_BASE}/DeleteVenueRequest${buildQueryString({ requestId })}`).pipe(map((res: ApiResponse<boolean>) => res.result))
  }

  public getAwaitingVenueDetailsForAdmin(requestId: string): Observable<VENUES_MODELS.WaitingForApprovalVenuesModel> {
    return this._http.fetch(`${this._VENUE_REQUEST_ENDPOINT_BASE}/GetAwaitingVenueDetailsForAdmin${buildQueryString({ requestId })}`).pipe(
      map(
        (res: ApiResponse<VENUES_MODELS.WaitingForApprovalVenuesModel>) => res.result
      )
    )
  }

  public getAwaitingVenueTermsAndConditions(requestId: string): Observable<VENUES_MODELS.ActiveTermsAndConditions> {
    return this._http.fetch(`${this._VENUE_REQUEST_ENDPOINT_BASE}/GetAwaitingVenueTermsAndConditions${buildQueryString({ requestId })}`).pipe(map((res: ApiResponse<VENUES_MODELS.ActiveTermsAndConditions>) => res.result))
  }

  public getOffersPageInAwaitingVenue(pagination: PaginationConfigModel, requestId: string): Observable<PaginationModel<VENUES_MODELS.OfferModel>> {
    return this._http.fetch(`${this._VENUE_REQUEST_ENDPOINT_BASE}/GetOffersPageInAwaitingVenue${buildQueryString({ ...pagination, requestId })}`).pipe(
      map(
        (res: ApiResponse<PaginationModel<VENUES_MODELS.OfferModel>>) => res.result
      )
    )
  }

  public getUpcomingEventsPageInAwaitingVenue(pagination: PaginationConfigModel, requestId: string): Observable<PaginationModel<VENUES_MODELS.UpcomingEventModel>> {
    return this._http.fetch(`${this._VENUE_REQUEST_ENDPOINT_BASE}/GetUpcomingEventsPageInAwaitingVenue${buildQueryString({ ...pagination, requestId })}`).pipe(
      map(
        (res: ApiResponse<PaginationModel<VENUES_MODELS.UpcomingEventModel>>) => res.result
      )
    )
  }

}

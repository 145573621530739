import { PaginationConfigModel } from "@shared/modules/pagination/model/pagination.model"


export class GetAllAssignedDealsPage {
  static readonly type = '[DEALS] Get All Assigned Deals Page'
}

export class PaginateAllAssignedDealsPage {
  static readonly type = '[DEALS] Paginate All Assigned Deals Page'
  constructor(public pagination: PaginationConfigModel) { }
}

export class SortAllAssignedDealsPage {
  static readonly type = '[DEALS] Sort All Assigned Deals Page'
  constructor(public sortBy: number) { }
}

export class SearchAllAssignedDealsPage {
  static readonly type = '[DEALS] Search All Assigned Deals Page'
  constructor(public searchQuery: string) { }
}

export class UnassignDealFromVenue {
  static readonly type = '[DEALS] Unassign Deal From Venue'
  constructor(
    public venueId: string,
    public offerId: string) { }
}

export class UnassignAllDeals {
  static readonly type = '[DEALS] Unassign All Deals'
}

export class GetAllActiveVenues {
  static readonly type = '[DEALS] Get All Active Venues'
}

export class GetDealTypes{
  static readonly type= "[DEALS] GET DEAL TYPE"
}

export class GetAssignedDeal{
  static readonly type="[DEALS] Get Assigned Deal";
  constructor(public dealId:string){}
  
}
export class AssignDeal{
  static readonly type="[DEALS] Assign Deal";
  constructor(public deal: FormData) { }
}
export class UpdateAssignedDeal{
  static readonly type="[DEALS] Update Deal";
  constructor(public dealId: string, public deal: FormData) { }
}
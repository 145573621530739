import { Injectable } from '@angular/core';
import { ApiResponse, PaginationModel } from '@core/http/apis.model';
import { HttpService } from '@core/http/http/http.service';
import * as SEARCH_FILTER_MODELS from '@modules/search-and-filter-results/models/search-and-filter-results.model'
import { buildQueryString } from '@shared/helpers/build-query-string.helper';
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilterDataService {

  constructor(private _http: HttpService) { }

  private _CATEGORY_ENDPOINT_BASE = 'Category';
  private _OFFER_ENDPOINT_BASE = 'Offer';
  private _CITY_ENDPOINT_BASE = 'City';

  public getVenuesCategories(): Observable<SEARCH_FILTER_MODELS.VenueCategoryModel[]> {
    return this._http.fetch(`${this._CATEGORY_ENDPOINT_BASE}/GetActiveCategories${buildQueryString({ typeFor: 0 })}`).pipe(
      map(
        (res: ApiResponse<SEARCH_FILTER_MODELS.VenueCategoryModel[]>) => res.result
      )
    )
  }

  public getEventsCategories(): Observable<SEARCH_FILTER_MODELS.EventCategoryModel[]> {
    return this._http.fetch(`${this._CATEGORY_ENDPOINT_BASE}/GetActiveCategories${buildQueryString({ typeFor: 1 })}`).pipe(
      map(
        (res: ApiResponse<SEARCH_FILTER_MODELS.EventCategoryModel[]>) => res.result
      )
    )
  }

  public getOfferTypes(): Observable<SEARCH_FILTER_MODELS.OfferTypeModel[]> {
    return this._http.fetch(`${this._OFFER_ENDPOINT_BASE}/GetOfferTypes`).pipe(
      map(
        (res: ApiResponse<SEARCH_FILTER_MODELS.OfferTypeModel[]>) => res.result
      )
    )
  }

  public getAllCities(): Observable<SEARCH_FILTER_MODELS.CityModel[]> {
    return this._http.fetch(`${this._CITY_ENDPOINT_BASE}/GetActiveCities`).pipe(
      map(
        (res: ApiResponse<SEARCH_FILTER_MODELS.CityModel[]>) => res.result
      )
    )
  }

}

import { SystemRoles } from '@core/modules/authorization/model/authorization.config';
import { MenuItemModel } from './model/layout.interface';


const userLinks: MenuItemModel[] = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin, SystemRoles.EventAdmin],
    materialIcon: {
      isSvg: true,
      name: 'dashboard',
    },
  },

  {
    label: 'Users',
    link: '/users',
    roles: [SystemRoles.SuperAdmin],
    materialIcon: {
      isSvg: true,
      name: 'users',
    },
  },

  {
    label: 'Venues',
    link: '/venues',
    roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin],
    materialIcon: {
      isSvg: true,
      name: 'venues',
    },
  },

  {
    label: 'Events',
    link: '/events',
    roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin, SystemRoles.EventAdmin],
    materialIcon: {
      isSvg: true,
      name: 'events',
    },
  },

  {
    label: 'Featured',
    link: '/featured-events',
    roles: [SystemRoles.SuperAdmin],
    materialIcon: {
      isSvg: true,
      name: 'featured',
    },
  },

  {
    label: 'Assigned Loyalties',
    link: '/loyalty',
    roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin],
    materialIcon: {
      isSvg: true,
      name: 'loyalty',
    },
  },

  {
    label: 'Assigned Offers',
    link: '/deals',
    roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin],
    materialIcon: {
      isSvg: true,
      name: 'deals',
    },
  },

  {
    label: 'Bookings',
    link: '/bookings',
    roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin, SystemRoles.EventAdmin],
    materialIcon: {
      isSvg: true,
      name: 'bookings',
    },
  },

  {
    label: 'Customers',
    link: '/customers',
    roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin, SystemRoles.EventAdmin],
    materialIcon: {
      isSvg: true,
      name: 'customers',
    },
  },

  {
    label: 'Manage',
    link: '/manage',
    roles: [SystemRoles.SuperAdmin],
    materialIcon: {
      isSvg: true,
      name: 'manage',
    },
  },
  {
    label: 'Redeem a Ticket',
    link: '/tickets',
    roles: [SystemRoles.SuperAdmin, SystemRoles.TicketAdmin],
    materialIcon: {
      isSvg: false,
      name: 'qr_code_2',
    },
  },

  {
    label: 'Logout',
    link: '/logout',
    roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin, SystemRoles.EventAdmin, SystemRoles.TicketAdmin],
    materialIcon: {
      isSvg: true,
      name: 'logout',
    },
  },
];


/**
 *
 * @param roles
 * @returns {MenuItemModel[]} - Array of sidenav links
 */
export function getLinksBasedOnRole(roles: string[]): MenuItemModel[] {
  let assignedLinks = [];
  assignedLinks = userLinks.filter((link: MenuItemModel) => {
    if (link.roles && roles.length) {
      const canLoad: boolean = link.roles.some((val) => roles.indexOf(val) !== -1);
      // if (canLoad && link.children) link.children = link.children.filter((child: MenuItemModel) => {
      //   if (child.roles && roles.length) {
      //     const canLoadChild = child.roles.some((val) => roles.indexOf(val) !== -1);
      //     if (canLoadChild) return child;
      //   }
      // })
      if (canLoad) {
        return link
      } else {
        return null
      }
      ;
    } else {
      return null
    }
  }
  )
  return assignedLinks
}


// Recursive but not now
// export function getLinksBasedOnRole(roles: string[], assignedLinks = [], children: boolean = false): MenuItemModel[] {
//   userLinks.forEach((link: MenuItemModel) => {
//     if (link.roles && roles.length) {
//       const canLoad: boolean = link.roles.some((val) => roles.indexOf(val) !== -1);
//       if (canLoad && !children) {
//         assignedLinks.push(link);
//         if (link.children) {
//           getLinksBasedOnRole(roles, assignedLinks.find(assignedLink => assignedLink.label = link.label), true)
//         }
//       }
//       else if (canLoad && children) assignedLinks.push(link)
//     }

//   })

//   return assignedLinks;
// }


<div
  *ngIf="(isEnabled$ | async) && (isActivated$ | async)"
  class="overlay"
  [@fadeInOut]
>
  <!-- <div class="overlay"> -->
  <!-- <mat-progress-spinner
    class="spinner"
    [color]="color"
    [mode]="mode"
    [value]="value"
  >
  </mat-progress-spinner> -->
  <!-- <div class="loader">
      <div class="cyan"></div>
      <div class="magenta"></div>
  </div> -->

  <div class="flex items-center justify-center w-full h-screen ng-star-inserted">
    <div class="w-full shadow-lg lg:w-1/4">
      <div class="w-full p-4 bg-white border border-gray-100 rounded-lg widget dark:bg-gray-900 dark:border-gray-800">
        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-col">
            <div class="text-xs font-light text-gray-500 uppercase">please wait</div>

            <div class="flex w-56 text-xl font-bold loadingData"><p class="w-64">loading data</p><svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 100 10" enable-background="new 0 0 0 0" xml:space="preserve">
              <circle fill="#e41f7b" stroke="none" cx="10" cy="10" r="2">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1"/>
              </circle>
              <circle fill="#e41f7b" stroke="none" cx="20" cy="10" r="2">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2"/>
              </circle>
              <circle fill="#e41f7b" stroke="none" cx="30" cy="10" r="2">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3"/>
              </circle>
            </svg></div>
          </div>
          <div class="logoLoading">
            <svg class="filledLogo" xmlns="http://www.w3.org/2000/svg" width="117.355" height="36.457" viewBox="0 0 117.355 36.457">
              <g>
                <rect width="117.355" height="36.457" x="0" y="0" fill="none" rx="0" ry="0"/>
                <path fill="rgb(28,55,86)" fill-rule="evenodd" d="M30.68803406 16.38185501h5.3701477L37.59098053 11.63974h-7.5203247q-1.2507248-3.6563797-4.2923813-5.7852745c-2.02777862-1.41926385-4.31900024-2.12889672-6.87367248-2.12889672q-6.37072372-.0000019-11.18469238 5.00556755-4.81396485 5.00556565-4.81396485 11.28048706 0 4.35891342 2.63451004 7.71724701c1.75633622 2.23888779 4.08747864 3.61380005 6.99341965 4.12473679.3832016.06386566.7664032.11176681 1.1496048.14369964.3832016.03193283.7664032.04790116 1.1496048.04790116 4.08747863 0 7.71192932-1.59667206 10.87333679-4.79001618q4.7421112-4.79001999 4.98161316-10.87333679zm-5.35949707 0h.61738586q-.26345825 4.21520996-3.59783935 7.56822586-3.3343811 3.35301209-7.59217453 3.35301209c-2.10760498 0-3.81604386-.69455338-5.12531662-2.08365631C8.32132339 23.8303299 7.666687 22.09793854 7.666687 20.02226639q.0053215-4.35891342 3.40090943-7.95142555c2.2637291-2.3950081 4.88049316-3.59251214 7.85030365-3.59251214.29680633.00075531.5931015.02477837.88615417.07184983q.4524002.07185173.93138886.16765022l-.09579468.2874012-.09580231.2874012c-.19515228.54286957-.3725586 1.06977081-.53222656 1.58070564-.15731049.49578476-.23806763 1.0126648-.23949433 1.5328045.0040741.37516212.06877137.74719619.19159699 1.10170363.13960266.40706635.33290863.7936802.57479858 1.1496048.60674286.89413643 1.3252411 1.40506935 2.15550995 1.5328045.8720169.13080978 1.75274658.19486236 2.63450623.1916008z"/>
                <path fill="rgb(28,55,86)" fill-rule="evenodd" d="M45.96818565 32.09310634h-1.43700476c-.69663934.00135604-1.38728849-.1286485-2.03575675-.38320127-.64012413-.24591845-1.23194543-.60263266-1.7483558-1.0538035-.64138004.44777403-1.34288344.80255736-2.0836569 1.0538035-.73211137.25227753-1.50090043.3817578-2.27525754.38320127-.15201015.00044321-.3039277-.00755245-.4550515-.02395008-.14370048-.01596672-.29671488-.0399168-.45505151-.07185024-.15457862.00091286-.30827419-.02335486-.4550515-.07185024-.14910649-.04913687-.3010728-.089128-.45505152-.1197504-1.15315197-.38320126-2.02333818-1.03783676-2.61055865-1.9639065-.58355406-.89556021-.87630959-1.9494801-.83825277-3.01771-.0006495-.15998201.00734552-.31988227.02395007-.47900159.01482152-.16090363.03881058-.32083067.07185024-.47900158.00039044-.1623222.0245984-.3237086.07185024-.4790016.04790016-.15966719.0864864-.31933438.1197504-.47900158l2.68240889-8.33462761h4.9816165l-3.16141047 9.81953253c-.03266711.09340794-.05671749.18960943-.07185024.28740095-.01502627.07897173-.02304083.15911737-.02395008.2395008-.00520727.11593534.03819658.22878534.1197504.31135103.07805951.0798336.22974335.1197504.4550515.1197504.09629692-.00004282.19242519-.0080535.28740096-.02395008.09753205-.01639649.19362726-.0404203.28740095-.07185024.5738028-.14478534 1.10130232-.43326164 1.53280508-.83825278.4322134-.36436863.76233374-.83479012.95800317-1.36515452-.00272752-.0262806.0059997-.05246227.02395008-.07185024.0167492-.02007803.02530262-.0457383.02395008-.07185024l2.73030905-8.33462761h4.93371635l-3.11351032 9.81953253c-.03266711.09340794-.05671748.18960943-.07185024.28740095-.01502627.07897173-.02304083.15911737-.02395008.2395008-.00520727.11593534.03819658.22878534.1197504.31135103.07805952.0798336.22974335.1197504.4550515.1197504h3.01771l-1.58070523 4.7421157z"/>
                <path fill="rgb(28,55,86)" fill-rule="evenodd" d="M57.12892263 21.33685959h-2.53870841l-1.96523707 6.01413104h5.70144945l-1.53413564 4.7421157h-5.36481778c-1.14782973 0-2.0792217-.38453182-2.79417592-1.15359548-.6996915-.71977065-1.0870063-1.68662317-1.07775358-2.69039225.00001542-.10468478.00802204-.20921565.02395008-.31268159.01537043-.1195744.0229263-.24002391.02261952-.36058175.03193344-.133056.06386688-.24748415.09713088-.35925119.033264-.11176704.06253632-.21688127.09580032-.3126816l1.81887547-5.57371568h-2.68906169l1.58070524-4.75675187h2.63583929l1.81488379-5.60431857 4.50261492 1.48490492-1.342535 4.12473589h2.54003897l-1.52748284 4.75808243z"/>
                <path fill="rgb(28,55,86)" fill-rule="evenodd" d="M86.25088501 16.38185501h5.36482239l1.5328064-4.75542068h-7.52032471c-.8302765-2.42871476-2.2592926-4.352705-4.2870636-5.77196885-2.027771-1.41926384-4.31900025-2.1288967-6.87367249-2.1288967-4.24715423 0-7.97537994 1.66852187-11.18469238 5.00556754q-4.81396484 5.00556565-4.81396484 11.28048706c0 2.90594101.8781662 5.47835922 2.63450622 7.71724701 1.75634003 2.23888779 4.08748627 3.61380005 6.99342346 4.12473679.3832016.06386566.7664032.11176681 1.1496048.14369964.3832016.03193283.7664032.04790116 1.1496048.04790116 4.08747864 0 7.71192932-1.59667206 10.8733368-4.79001618q4.7421112-4.79001999 4.98161315-10.87333679zm-5.36480713 0h.63600159c-.18804932 2.81014252-1.39353943 5.33288193-3.61646271 7.56822586q-3.33438873 3.35301209-7.59217072 3.35301209c-2.1076126 0-3.81604766-.69455338-5.12532043-2.08365631-1.30927277-1.38910675-1.96390533-3.12149811-1.96390533-5.19717026q.00531768-4.35891342 3.40090942-7.95142555 3.39559174-3.59251214 7.85030365-3.59251214c.29681396.00075531.5931015.02477837.88615417.07184983.3015976.04790115.61206055.10378456.93139649.16765022l-.19160462.5748024c-.19514465.54286957-.37255859 1.06977081-.53222656 1.58070564-.15730285.49578476-.23806762 1.0126648-.23950195 1.5328045.0040741.37516212.068779.74719619.19160461 1.10170363.13960266.40706635.33290863.7936802.57479859 1.1496048.60673523.89413643 1.32524109 1.40506935 2.15550995 1.5328045.8720169.13080978 1.75273895.19486236 2.63451385.1916008z"/>
                <path fill="rgb(28,55,86)" fill-rule="evenodd" d="M101.5310392 32.09310634h-1.43700476c-.69663934.00135604-1.38728849-.1286485-2.03575675-.38320127-.64012413-.24591845-1.23194543-.60263266-1.7483558-1.0538035-.64138004.44777403-1.34288344.80255736-2.0836569 1.0538035-.73211137.25227753-1.50090043.3817578-2.27525754.38320127-.15201015.00044321-.3039277-.00755245-.4550515-.02395008-.14370048-.01596672-.29671488-.0399168-.45505151-.07185024-.15457862.00091286-.30827419-.02335486-.4550515-.07185024-.14910649-.04913687-.3010728-.089128-.45505152-.1197504-1.15315197-.38320126-2.02333818-1.03783676-2.61055865-1.9639065-.58355406-.89556021-.87630959-1.9494801-.83825277-3.01771-.0006495-.15998201.00734552-.31988227.02395008-.47900159.01482151-.16090363.03881057-.32083067.07185023-.47900158.00039044-.1623222.0245984-.3237086.07185024-.4790016.04790016-.15966719.0864864-.31933438.1197504-.47900158l2.68240889-8.33462761h4.9816165l-3.16141047 9.81953253c-.03266711.09340794-.05671748.18960943-.07185024.28740095-.01502627.07897173-.02304083.15911737-.02395008.2395008-.00520727.11593534.03819658.22878534.1197504.31135103.07805951.0798336.22974335.1197504.4550515.1197504.09629692-.00004282.19242519-.0080535.28740096-.02395008.09753205-.01639649.19362726-.0404203.28740095-.07185024.5738028-.14478534 1.10130233-.43326164 1.53280508-.83825278.4322134-.36436863.76233374-.83479012.95800317-1.36515452-.00272752-.0262806.0059997-.05246227.02395008-.07185024.0167492-.02007803.02530262-.0457383.02395008-.07185024l2.7316396-8.33462761h4.93371636l-3.11351032 9.81953253c-.03266711.09340794-.05671748.18960943-.07185024.28740095-.01502627.07897173-.02304083.15911737-.02395008.2395008-.00520727.11593534.03819658.22878534.1197504.31135103.07894656.0798336.2306304.1197504.4550515.1197504h3.02303225l-1.58735804 4.7421157z"/>
                <path fill="rgb(28,55,86)" fill-rule="evenodd" d="M112.69842898 21.33685959h-2.53870841l-1.96523707 6.01413104h5.70144945l-1.53413564 4.7421157h-5.37147058c-1.15315197 0-2.08720506-.38453182-2.80215928-1.15359548-.69883569-.72022472-1.08559861-1.68689298-1.07642301-2.69039225.00001541-.10468478.00802203-.20921565.02395007-.31268159.01537043-.1195744.0229263-.24002391.02261952-.36058175.03193344-.133056.06386688-.24748415.09713088-.35925119.033264-.11176704.06253632-.21688127.09580032-.3126816l1.81887547-5.57371568h-2.68240889l1.58070524-4.75675187h2.64249209l1.82020603-5.60431857 4.50128436 1.48490492-1.34120444 4.12473589h2.5387084l-1.53147451 4.75808243z"/>
              </g>
            </svg>
            <!-- <svg  xmlns="http://www.w3.org/2000/svg" width="117.355" height="36.457" viewBox="0 0 117.355 36.457">
              <g>
                <rect width="117.355" height="36.457" x="0" y="0" fill="none" rx="0" ry="0"/>
                <path class="logoPath" fill="transparent" stroke="#000" stroke-width="0.2" fill-rule="evenodd" d="M30.68803406 16.38185501h5.3701477L37.59098053 11.63974h-7.5203247q-1.2507248-3.6563797-4.2923813-5.7852745c-2.02777862-1.41926385-4.31900024-2.12889672-6.87367248-2.12889672q-6.37072372-.0000019-11.18469238 5.00556755-4.81396485 5.00556565-4.81396485 11.28048706 0 4.35891342 2.63451004 7.71724701c1.75633622 2.23888779 4.08747864 3.61380005 6.99341965 4.12473679.3832016.06386566.7664032.11176681 1.1496048.14369964.3832016.03193283.7664032.04790116 1.1496048.04790116 4.08747863 0 7.71192932-1.59667206 10.87333679-4.79001618q4.7421112-4.79001999 4.98161316-10.87333679zm-5.35949707 0h.61738586q-.26345825 4.21520996-3.59783935 7.56822586-3.3343811 3.35301209-7.59217453 3.35301209c-2.10760498 0-3.81604386-.69455338-5.12531662-2.08365631C8.32132339 23.8303299 7.666687 22.09793854 7.666687 20.02226639q.0053215-4.35891342 3.40090943-7.95142555c2.2637291-2.3950081 4.88049316-3.59251214 7.85030365-3.59251214.29680633.00075531.5931015.02477837.88615417.07184983q.4524002.07185173.93138886.16765022l-.09579468.2874012-.09580231.2874012c-.19515228.54286957-.3725586 1.06977081-.53222656 1.58070564-.15731049.49578476-.23806763 1.0126648-.23949433 1.5328045.0040741.37516212.06877137.74719619.19159699 1.10170363.13960266.40706635.33290863.7936802.57479858 1.1496048.60674286.89413643 1.3252411 1.40506935 2.15550995 1.5328045.8720169.13080978 1.75274658.19486236 2.63450623.1916008z"/>
                <path class="logoPath" fill="transparent" stroke="#000" stroke-width="0.2" fill-rule="evenodd" d="M45.96818565 32.09310634h-1.43700476c-.69663934.00135604-1.38728849-.1286485-2.03575675-.38320127-.64012413-.24591845-1.23194543-.60263266-1.7483558-1.0538035-.64138004.44777403-1.34288344.80255736-2.0836569 1.0538035-.73211137.25227753-1.50090043.3817578-2.27525754.38320127-.15201015.00044321-.3039277-.00755245-.4550515-.02395008-.14370048-.01596672-.29671488-.0399168-.45505151-.07185024-.15457862.00091286-.30827419-.02335486-.4550515-.07185024-.14910649-.04913687-.3010728-.089128-.45505152-.1197504-1.15315197-.38320126-2.02333818-1.03783676-2.61055865-1.9639065-.58355406-.89556021-.87630959-1.9494801-.83825277-3.01771-.0006495-.15998201.00734552-.31988227.02395007-.47900159.01482152-.16090363.03881058-.32083067.07185024-.47900158.00039044-.1623222.0245984-.3237086.07185024-.4790016.04790016-.15966719.0864864-.31933438.1197504-.47900158l2.68240889-8.33462761h4.9816165l-3.16141047 9.81953253c-.03266711.09340794-.05671749.18960943-.07185024.28740095-.01502627.07897173-.02304083.15911737-.02395008.2395008-.00520727.11593534.03819658.22878534.1197504.31135103.07805951.0798336.22974335.1197504.4550515.1197504.09629692-.00004282.19242519-.0080535.28740096-.02395008.09753205-.01639649.19362726-.0404203.28740095-.07185024.5738028-.14478534 1.10130232-.43326164 1.53280508-.83825278.4322134-.36436863.76233374-.83479012.95800317-1.36515452-.00272752-.0262806.0059997-.05246227.02395008-.07185024.0167492-.02007803.02530262-.0457383.02395008-.07185024l2.73030905-8.33462761h4.93371635l-3.11351032 9.81953253c-.03266711.09340794-.05671748.18960943-.07185024.28740095-.01502627.07897173-.02304083.15911737-.02395008.2395008-.00520727.11593534.03819658.22878534.1197504.31135103.07805952.0798336.22974335.1197504.4550515.1197504h3.01771l-1.58070523 4.7421157z"/>
                <path class="logoPath" fill="transparent" stroke="#000" stroke-width="0.2" fill-rule="evenodd" d="M57.12892263 21.33685959h-2.53870841l-1.96523707 6.01413104h5.70144945l-1.53413564 4.7421157h-5.36481778c-1.14782973 0-2.0792217-.38453182-2.79417592-1.15359548-.6996915-.71977065-1.0870063-1.68662317-1.07775358-2.69039225.00001542-.10468478.00802204-.20921565.02395008-.31268159.01537043-.1195744.0229263-.24002391.02261952-.36058175.03193344-.133056.06386688-.24748415.09713088-.35925119.033264-.11176704.06253632-.21688127.09580032-.3126816l1.81887547-5.57371568h-2.68906169l1.58070524-4.75675187h2.63583929l1.81488379-5.60431857 4.50261492 1.48490492-1.342535 4.12473589h2.54003897l-1.52748284 4.75808243z"/>
                <path class="logoPath" fill="transparent" stroke="#000" stroke-width="0.2" fill-rule="evenodd" d="M86.25088501 16.38185501h5.36482239l1.5328064-4.75542068h-7.52032471c-.8302765-2.42871476-2.2592926-4.352705-4.2870636-5.77196885-2.027771-1.41926384-4.31900025-2.1288967-6.87367249-2.1288967-4.24715423 0-7.97537994 1.66852187-11.18469238 5.00556754q-4.81396484 5.00556565-4.81396484 11.28048706c0 2.90594101.8781662 5.47835922 2.63450622 7.71724701 1.75634003 2.23888779 4.08748627 3.61380005 6.99342346 4.12473679.3832016.06386566.7664032.11176681 1.1496048.14369964.3832016.03193283.7664032.04790116 1.1496048.04790116 4.08747864 0 7.71192932-1.59667206 10.8733368-4.79001618q4.7421112-4.79001999 4.98161315-10.87333679zm-5.36480713 0h.63600159c-.18804932 2.81014252-1.39353943 5.33288193-3.61646271 7.56822586q-3.33438873 3.35301209-7.59217072 3.35301209c-2.1076126 0-3.81604766-.69455338-5.12532043-2.08365631-1.30927277-1.38910675-1.96390533-3.12149811-1.96390533-5.19717026q.00531768-4.35891342 3.40090942-7.95142555 3.39559174-3.59251214 7.85030365-3.59251214c.29681396.00075531.5931015.02477837.88615417.07184983.3015976.04790115.61206055.10378456.93139649.16765022l-.19160462.5748024c-.19514465.54286957-.37255859 1.06977081-.53222656 1.58070564-.15730285.49578476-.23806762 1.0126648-.23950195 1.5328045.0040741.37516212.068779.74719619.19160461 1.10170363.13960266.40706635.33290863.7936802.57479859 1.1496048.60673523.89413643 1.32524109 1.40506935 2.15550995 1.5328045.8720169.13080978 1.75273895.19486236 2.63451385.1916008z"/>
                <path class="logoPath" fill="transparent" stroke="#000" stroke-width="0.2" fill-rule="evenodd" d="M101.5310392 32.09310634h-1.43700476c-.69663934.00135604-1.38728849-.1286485-2.03575675-.38320127-.64012413-.24591845-1.23194543-.60263266-1.7483558-1.0538035-.64138004.44777403-1.34288344.80255736-2.0836569 1.0538035-.73211137.25227753-1.50090043.3817578-2.27525754.38320127-.15201015.00044321-.3039277-.00755245-.4550515-.02395008-.14370048-.01596672-.29671488-.0399168-.45505151-.07185024-.15457862.00091286-.30827419-.02335486-.4550515-.07185024-.14910649-.04913687-.3010728-.089128-.45505152-.1197504-1.15315197-.38320126-2.02333818-1.03783676-2.61055865-1.9639065-.58355406-.89556021-.87630959-1.9494801-.83825277-3.01771-.0006495-.15998201.00734552-.31988227.02395008-.47900159.01482151-.16090363.03881057-.32083067.07185023-.47900158.00039044-.1623222.0245984-.3237086.07185024-.4790016.04790016-.15966719.0864864-.31933438.1197504-.47900158l2.68240889-8.33462761h4.9816165l-3.16141047 9.81953253c-.03266711.09340794-.05671748.18960943-.07185024.28740095-.01502627.07897173-.02304083.15911737-.02395008.2395008-.00520727.11593534.03819658.22878534.1197504.31135103.07805951.0798336.22974335.1197504.4550515.1197504.09629692-.00004282.19242519-.0080535.28740096-.02395008.09753205-.01639649.19362726-.0404203.28740095-.07185024.5738028-.14478534 1.10130233-.43326164 1.53280508-.83825278.4322134-.36436863.76233374-.83479012.95800317-1.36515452-.00272752-.0262806.0059997-.05246227.02395008-.07185024.0167492-.02007803.02530262-.0457383.02395008-.07185024l2.7316396-8.33462761h4.93371636l-3.11351032 9.81953253c-.03266711.09340794-.05671748.18960943-.07185024.28740095-.01502627.07897173-.02304083.15911737-.02395008.2395008-.00520727.11593534.03819658.22878534.1197504.31135103.07894656.0798336.2306304.1197504.4550515.1197504h3.02303225l-1.58735804 4.7421157z"/>
                <path class="logoPath" fill="transparent" stroke="#000" stroke-width="0.2" fill-rule="evenodd" d="M112.69842898 21.33685959h-2.53870841l-1.96523707 6.01413104h5.70144945l-1.53413564 4.7421157h-5.37147058c-1.15315197 0-2.08720506-.38453182-2.80215928-1.15359548-.69883569-.72022472-1.08559861-1.68689298-1.07642301-2.69039225.00001541-.10468478.00802203-.20921565.02395007-.31268159.01537043-.1195744.0229263-.24002391.02261952-.36058175.03193344-.133056.06386688-.24748415.09713088-.35925119.033264-.11176704.06253632-.21688127.09580032-.3126816l1.81887547-5.57371568h-2.68240889l1.58070524-4.75675187h2.64249209l1.82020603-5.60431857 4.50128436 1.48490492-1.34120444 4.12473589h2.5387084l-1.53147451 4.75808243z"/>
              </g>
            </svg> -->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

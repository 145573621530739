import { PaginationConfigModel } from "@shared/modules/pagination/model/pagination.model"


export class GetActiveCategories {
  static readonly type = '[VENUES] Get Active Categories'
  constructor(public typeFor: number) { }
}

export class GetAllEvents {
  static readonly type = '[VENUES] Get All Events';
  constructor(public venueId?:string){}
}

export class GetActiveCities {
  static readonly type = '[VENUES] Get Active Cities'
}
export class GetActiveTermsAndConditions {
  static readonly type = '[VENUES] Get Active Terms And Conditions'
}

export class GetAllVenuesPage {
  static readonly type = '[VENUES] Get All Venues Page'
}
``
export class PaginateAllVenuesPage {
  static readonly type = '[VENUES] Paginate All Venues Page'
  constructor(public pagination: PaginationConfigModel) { }
}

export class SortAllVenuesPage {
  static readonly type = '[VENUES] Sort All Venues Page'
  constructor(public sortBy: number) { }
}

export class SearchAllVenuesPage {
  static readonly type = '[VENUES] Search All Venues Page'
  constructor(public searchQuery: string) { }
}

export class DeleteVenue {
  static readonly type = '[VENUES] Delete Venue'
  constructor(public id: string) { }
}

export class CreateVenue {
  static readonly type = '[VENUES] Create Venue'
  constructor(public venue: FormData) { }
}

export class GetVenue {
  static readonly type = '[VENUES] Get Venue'
  constructor(public id: string) { }
}

export class UpdateVenue {
  static readonly type = '[VENUES] Update Venue'
  constructor(public id: string, public venue: FormData) { }
}

export class getVenueTermsAndConditions {
  static readonly type = '[VENUES] Get Venue Terms And Conditions'
  constructor(public id: string) { }
}
export class GetVenueDetails {
  static readonly type = '[VENUES] Get Venue Details'
  constructor(public id: string) { }
}

export class GetOffersPage {
  static readonly type = '[VENUES] Get Offers Page'
}

export class PaginateOffersPage {
  static readonly type = '[VENUES] Paginate Offers Page'
  constructor(public offersPagination: PaginationConfigModel) { }
}


export class GetUpcomingEvents {
  static readonly type = '[VENUES] Get Upcoming Events'
}

export class PaginateUpcomingEvents {
  static readonly type = '[VENUES] Paginate Upcoming Events'
  constructor(public upcomingEventsPagination: PaginationConfigModel) { }
}

export class UpdateVenueCode {
  static readonly type = '[VENUES] Update Venue Code'
  constructor(public id: string, public code: string) { }
}

export class UpdateVenueTermsAndConditions {
  static readonly type = '[VENUES] Update Venue Terms And Conditions'
  constructor(public venueId: string, public selectedTermsAndConditions: string[]) { }
}

export class DeleteGalleryImage {
  static readonly type = '[VENUES] Delete Gallery Image'
  constructor(public id: string, public images: string[]) { }
}

export class GetAllWaitingForApprovalVenuesPage {
  static readonly type = '[VENUES] Get All Waiting For Approval Venues Page'
}
export class PaginateAllWaitingForApprovalVenuesPage {
  static readonly type = '[VENUES] Paginate All Waiting For Approval Venues Page'
  constructor(public pagination: PaginationConfigModel) { }
}

export class SortAllWaitingForApprovalVenuesPage {
  static readonly type = '[VENUES] Sort All Waiting For Approval Venues Page'
  constructor(public sortBy: number) { }
}

export class SearchAllWaitingForApprovalVenuesPage {
  static readonly type = '[VENUES] Search All Waiting For Approval Venues Page'
  constructor(public searchQuery: string) { }
}

export class GetAllWaitingForApprovalVenuesPageForVenueAdmin {
  static readonly type = '[VENUES] Get All Waiting For Approval Venues Page For Venue Admin'
}

export class PaginateAllWaitingForApprovalVenuesPageForVenueAdmin {
  static readonly type = '[VENUES] Paginate All Waiting For Approval Venues Page For Venue Admin'
  constructor(public pagination: PaginationConfigModel) { }
}

export class SortAllWaitingForApprovalVenuesPageForVenueAdmin {
  static readonly type = '[VENUES] Sort All Waiting For Approval Venues Page For Venue Admin'
  constructor(public sortBy: number) { }
}

export class SearchAllWaitingForApprovalVenuesPageForVenueAdmin {
  static readonly type = '[VENUES] Search All Waiting For Approval Venues Page For Venue Admin'
  constructor(public searchQuery: string) { }
}

export class ApproveVenue {
  static readonly type = '[VENUES] Approve Venue'
  constructor(public requestId: string) { }
}

export class RejectVenue {
  static readonly type = '[VENUES] Reject Venue'
  constructor(public id: string) { }
}

export class DeleteVenueRequest {
  static readonly type = '[VENUES] Delete Venue Request'
  constructor(public requestId: string) { }
}

export class GetAwaitingVenueTermsAndConditions {
  static readonly type = '[VENUES] Get Awaiting Venue Terms And Conditions'
  constructor(public id: string) { }
}
export class GetAwaitingVenueDetailsForAdmin {
  static readonly type = '[VENUES] Get Awaiting Venue Details For Admin'
  constructor(public id: string) { }
}

export class GetOffersPageInAwaitingVenue {
  static readonly type = '[VENUES] Get Offers Page In Awaiting Venue'
}

export class PaginateOffersPageInAwaitingVenue {
  static readonly type = '[VENUES] Paginate Offers Page In Awaiting Venue'
  constructor(public offersPagination: PaginationConfigModel) { }
}


export class GetUpcomingEventsInAwaitingVenue {
  static readonly type = '[VENUES] Get Upcoming Events In Awaiting Venue'
}

export class PaginateUpcomingEventsInAwaitingVenue {
  static readonly type = '[VENUES] Paginate Upcoming Events In Awaiting Venue'
  constructor(public eventsPagination: PaginationConfigModel) { }
}
<!-- Incase of no children -->

<a mat-list-item class="pr-0 listItem" *ngIf="!children && label !== 'Logout'" routerLink="{{ link }}"
  routerLinkActive="selectedItem" style="height: 46px !important" [queryParams]="queries ? queries : null"
  title="{{ label }}" (click)="showLoader()" (keydown)="hideSpinnerOnKeyboardEvents($event)">
  <div class="icon-Hover" title="{{ label }}">
    <!-- Material Icon -->
    <mat-icon *ngIf="materialIcon && materialIcon.isSvg && materialIcon.inline" svgIcon="{{ materialIcon.name }}"
      class="mr-2"></mat-icon>
    <div *ngIf="materialIcon && materialIcon.isSvg" class="mr-2">
      <img src="assets/images/{{materialIcon.name}}.svg" alt="">
    </div>
    <mat-icon *ngIf="materialIcon && !materialIcon.isSvg" class="mr-2">{{ materialIcon.name }}
    </mat-icon>
  </div>
  <mat-label *ngIf="!iconsOnly" class="text-left w-100 text-capitalize itemLabel"
    [@openClose]="iconsOnly ? 'closed' : 'open'">
    {{ label }}
  </mat-label>
</a>

<a mat-list-item class="pr-0 listItem" *ngIf="label === 'Logout'" (click)="confirmLogout()">
  <div class="icon-Hover" title="{{ label }}">
    <!-- Material Icon -->
    <mat-icon *ngIf="materialIcon && materialIcon.isSvg && materialIcon.inline" svgIcon="{{ materialIcon.name }}"
      class="mr-2"></mat-icon>
    <div *ngIf="materialIcon && materialIcon.isSvg" class="mr-2">
      <img src="assets/images/{{materialIcon.name}}.svg" alt="">
    </div>
    <!-- <mat-icon *ngIf="materialIcon && !materialIcon.isSvg" class="mr-2"
      >{{ materialIcon.name }}
    </mat-icon> -->
  </div>
  <mat-label *ngIf="!iconsOnly" class="text-left w-100 text-capitalize itemLabel"
    [@openClose]="iconsOnly ? 'closed' : 'open'">
    {{ this.label }}
  </mat-label>
</a>

<!-- Incase of children -->

<a mat-list-item class="pr-0 listItem subNav wrapper wrapper1" [ngClass]="{ active: !collapsed }" *ngIf="children"
  (click)="ShowChildren()">
  <span *ngIf="!iconsOnly" class="arrow"> </span>

  <div class="icon-Hover" title="{{ label }}">
    <!-- Material Icon -->
    <mat-icon *ngIf="materialIcon && materialIcon.isSvg" svgIcon="{{ materialIcon.name }}" class="mr-2"></mat-icon>
    <mat-icon *ngIf="materialIcon && !materialIcon.isSvg" class="mr-2">{{
      materialIcon.name
      }}</mat-icon>
  </div>

  <mat-label *ngIf="!iconsOnly" class="text-left w-100 text-capitalize itemLabel"
    [@openClose]="iconsOnly ? 'closed' : 'open'">
    {{ this.label }}
  </mat-label>
</a>

<a mat-list-item class="pl-3 pr-0 listItem subItem" routerLink="{{ subNav.link }}" routerLinkActive="selectedItem"
  [routerLinkActiveOptions]="{ exact: true }" *ngFor="let subNav of children"
  [queryParams]="subNav.queryParams ? subNav.queryParams : {}" [ngClass]="{ show: !collapsed }" (click)="showLoader()"
  (keydown)="hideSpinnerOnKeyboardEvents($event)">
  <div class="icon-Hover" title="{{ subNav.label }}">
    <!-- Material Icon -->
    <mat-icon *ngIf="subNav.materialIcon && subNav.materialIcon.isSvg" svgIcon="{{ subNav.materialIcon.name }}"
      class="mr-2"></mat-icon>
    <mat-icon *ngIf="subNav.materialIcon && !subNav.materialIcon.isSvg" class="mr-2">
      {{ subNav.materialIcon.name }}</mat-icon>
  </div>
  <mat-label *ngIf="!iconsOnly" class="text-left w-100 text-capitalize itemLabel"
    [@openClose]="iconsOnly ? 'closed' : 'open'">
    {{ subNav?.label }}
  </mat-label>
</a>
import { SystemRoles } from "@core/modules/authorization/model/authorization.config";
import { PermissionModel } from "@core/modules/authorization/model/authorization.model";

export const FILTER_PERMISSIONS: PermissionModel[] = [
    {
        name: 'VIEW_CATEGORY_FIELD',
        roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin]
    },
    {
        name: 'VIEW_DEALS_FIELD',
        roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin]
    }
]
import { CountdownComponent } from 'ngx-countdown';
import { loggedInUserModel } from './../../modules/user/model/user.model';
import * as AUTH_MODEL from './../model/auth.model';
export class Login {
  static readonly type = '[Auth] User Login';
  constructor(public user: AUTH_MODEL.LoginModel) { }
}

export class ForgetPassword {
  static readonly type = '[Auth] Forget Password';
  constructor(public email: string) { }
}

export class Logout {
  static readonly type = '[Auth] User Logout';
}

export class VerifyResetPassword {
  static readonly type = '[Auth] Verify Reset Password';
  constructor(public email: string, public otp: string) { }
}

export class ResetPassword {
  static readonly type = '[Auth] Reset Password';
  constructor(public email: string, public hashedOTP: string, public newPassword: string) { }
}

export class ChangePassword {
  static readonly type = '[Auth] Change Password';
  constructor(public changePasswordPayload) { }
}
export class RestartCountDown {
  static readonly type = '[Auth] Restart Count Down';
}


// export class LogoutOrderFromClient {
//   static readonly type = "[Auth] Logout Order From Client";
// }

// export class LogoutOrderFromServer {
//   static readonly type = '[Auth] Logout Order From Server';
// }

export class NotifyAllOriginContextsToLogout {
  static readonly type = '[Auth] NotifyAllOriginContextsToLogout';
}
export class NotifyAllOriginContextsToLogin {
  static readonly type = '[Auth] NotifyAllOriginContextsToLogin';
}

export class RefreshToken {
  static readonly type = '[Auth] Refresh Token';
}

export class NavigateTargetRoute {
  static readonly type = '[Auth] Navigate Target Route';
  constructor(public route?: string) { }
}

export class SetReturnUrl {
  static readonly type = '[Auth] Set Return URL';
  constructor(public route?: string) { }
}

export class RemoveReturnUrl {
  static readonly type = '[Auth] Remove Return URL';
}


export class CacheLastDispatchedAction {
  static readonly type = '[Auth] Cache Last Dispatched Action';
  constructor(public action: object) { }
}

import { Navigate } from '@ngxs/router-plugin';
import { Injectable } from '@angular/core';
import { PaginationModel } from '@core/http/apis.model';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { tap } from 'rxjs/operators';
import * as EVENTS_MODELS from '@modules/events/models/events.model';
import * as EVENTS_ACTIONS from '@modules/events/state/events.actions';
import { EventsService } from '../models/events.service';

export class EventsStateModel {
  public allEvents: EVENTS_MODELS.EventModel[];
  public eventDetails: EVENTS_MODELS.EventDetailsModel;
  public searchQuery: string;
  public sortBy: number;
  public pagination: PaginationConfigModel;
  public activeCategories: EVENTS_MODELS.ActiveCategory[];
  public activeCities: EVENTS_MODELS.ActiveCity[];
  public activeVenues: EVENTS_MODELS.ActiveVenueModel[];
  public event: EVENTS_MODELS.EventDetailsModel;

  public customerOrders: EVENTS_MODELS.CustomerOrderForEventModel[];
  public customerOrdersSearchQuery: string;
  public customerOrdersSortBy: number;
  public customerOrdersPagination: PaginationConfigModel;

  public allWaitingApprovalEvents: EVENTS_MODELS.WaitingEventApprovalModel[];
  public WaitingApprovalEventsPagination: PaginationConfigModel;
  public waitingApprovalEventsSearchQuery: string;
  public waitingApprovalEventsSortBy: number;
  public waitingApprovalEventDetails: EVENTS_MODELS.WaitingEventApprovalModel;

  public allVenueAdminWaitingApprovalEvents: EVENTS_MODELS.WaitingEventApprovalModel[];
  public venueAdminWaitingApprovalEventsPagination: PaginationConfigModel;
  public venueAdminWaitingApprovalEventsSearchQuery: string;
  public venueAdminWaitingApprovalEventsSortBy: number;

  constructor() {
    this.allEvents = null;
    this.eventDetails = null;

    this.pagination = {
      pageNumber: 0,
      pageSize: 3,
    };

    this.sortBy = 0;

    this.searchQuery = '';
    this.activeCategories = null;
    this.activeVenues = null;
    this.event = null;

    this.customerOrders = null;
    this.customerOrdersSearchQuery = '';
    this.customerOrdersSortBy = 2;
    this.customerOrdersPagination = {
      pageNumber: 0,
      pageSize: 3,
    };

    this.allWaitingApprovalEvents = null;
    this.waitingApprovalEventsSearchQuery = '';
    this.waitingApprovalEventsSortBy = 0;
    this.WaitingApprovalEventsPagination = {
      pageNumber: 0,
      pageSize: 3,
    };

    this.waitingApprovalEventDetails = null;

    this.allVenueAdminWaitingApprovalEvents = null;
    this.venueAdminWaitingApprovalEventsSearchQuery = '';
    this.venueAdminWaitingApprovalEventsSortBy = 0;
    this.venueAdminWaitingApprovalEventsPagination = {
      pageNumber: 0,
      pageSize: 3,
    };
  }
}

@Injectable()
@State<EventsStateModel>({
  name: 'events',
  defaults: new EventsStateModel(),
})
export class EventsState {
  constructor(private _mainService: EventsService) { }

  @Selector() static allEvents(state: EventsStateModel): EVENTS_MODELS.EventModel[] { return state.allEvents; }

  @Selector() static eventDetails(state: EventsStateModel): EVENTS_MODELS.EventDetailsModel { return state.eventDetails; }

  @Selector() static pagination(state: EventsStateModel): PaginationConfigModel { return { ...state.pagination }; }

  @Selector() static sort(state: EventsStateModel): number { return state.sortBy; }

  @Selector() static searchQuery(state: EventsStateModel): string { return state.searchQuery; }

  @Selector() static activeCategories(state: EventsStateModel): EVENTS_MODELS.ActiveCategory[] { return state.activeCategories; }

  @Selector() static activeCities(state: EventsStateModel): EVENTS_MODELS.ActiveCity[] { return state.activeCities; }

  @Selector() static activeVenues(state: EventsStateModel): EVENTS_MODELS.ActiveVenueModel[] { return state.activeVenues; }

  @Selector() static event(state: EventsStateModel): EVENTS_MODELS.EventDetailsModel { return state.event; }

  @Selector() static customerOrders(state: EventsStateModel): EVENTS_MODELS.CustomerOrderForEventModel[] { return state.customerOrders; }

  @Selector() static customersOrdersPagination(state: EventsStateModel): PaginationConfigModel { return { ...state.customerOrdersPagination }; }

  @Selector() static customerOrdersSortBy(state: EventsStateModel): number { return state.customerOrdersSortBy; }

  @Selector() static customerOrdersSearchQuery(state: EventsStateModel): string { return state.customerOrdersSearchQuery; }

  @Selector() static allWaitingApprovalEvents(state: EventsStateModel): EVENTS_MODELS.WaitingEventApprovalModel[] { return state.allWaitingApprovalEvents; }

  @Selector() static WaitingApprovalEventsPagination(state: EventsStateModel): PaginationConfigModel { return { ...state.WaitingApprovalEventsPagination }; }

  @Selector() static waitingApprovalEventsSortBy(state: EventsStateModel): number { return state.waitingApprovalEventsSortBy; }

  @Selector() static waitingApprovalEventsSearchQuery(state: EventsStateModel): string { return state.waitingApprovalEventsSearchQuery; }

  @Selector() static waitingApprovalEventDetails(state: EventsStateModel): EVENTS_MODELS.WaitingEventApprovalModel { return state.waitingApprovalEventDetails; }

  @Selector() static allVenueAdminWaitingApprovalEvents(state: EventsStateModel): EVENTS_MODELS.WaitingEventApprovalModel[] { return state.allVenueAdminWaitingApprovalEvents; }

  @Selector() static venueAdminWaitingApprovalEventsPagination(state: EventsStateModel): PaginationConfigModel { return state.venueAdminWaitingApprovalEventsPagination; }

  @Selector() static venueAdminWaitingApprovalEventsSortBy(state: EventsStateModel): number { return state.venueAdminWaitingApprovalEventsSortBy; }

  @Selector() static venueAdminWaitingApprovalEventsSearchQuery(state: EventsStateModel): string { return state.venueAdminWaitingApprovalEventsSearchQuery; }
  /*_____________________________Reducers___________________________________*/

  @Action(EVENTS_ACTIONS.GetAllEventsPage)
  public getAllEventsPage({ getState, patchState, }: StateContext<EventsStateModel>) {
    const { pagination: { pageNumber, pageSize }, searchQuery, sortBy, } = getState();
    return this._mainService
      .getEventsPage({ pageNumber, pageSize }, { searchQuery, sortBy }).pipe(tap(
        ({ records, recordsTotalCount, totalPages, pageNumber, pageSize, }: PaginationModel<EVENTS_MODELS.EventModel>) => {
          patchState({
            allEvents: records,
            pagination: { totalPages, recordsTotalCount, pageSize, pageNumber },
          });
        }
      )
      );
  }

  @Action(EVENTS_ACTIONS.PaginateAllEventsPage)
  public paginateAllEventsPage({ dispatch, patchState, getState }: StateContext<EventsStateModel>, { pagination }: EVENTS_ACTIONS.PaginateAllEventsPage) {
    patchState({
      pagination: {
        ...getState().pagination,
        pageNumber: pagination.pageNumber,
      },
    });
    dispatch(new EVENTS_ACTIONS.GetAllEventsPage());
  }

  @Action(EVENTS_ACTIONS.SortAllEventsPage)
  public sortAllEventsPage({ getState, patchState, dispatch }: StateContext<EventsStateModel>, { sortBy }: EVENTS_ACTIONS.SortAllEventsPage) {
    patchState({
      sortBy,
      pagination: {
        ...getState().pagination,
        pageNumber: 0,
      },
    });
    dispatch(new EVENTS_ACTIONS.GetAllEventsPage());
  }

  @Action(EVENTS_ACTIONS.SearchAllEventsPage)
  public searchAllEventsPage({ getState, patchState, dispatch }: StateContext<EventsStateModel>, { searchQuery }: EVENTS_ACTIONS.SearchAllEventsPage) {
    patchState({
      searchQuery,
      pagination: {
        ...getState().pagination,
        pageNumber: 0,
      },
    });

    dispatch(new EVENTS_ACTIONS.GetAllEventsPage());
  }

  @Action(EVENTS_ACTIONS.DeleteEvent)
  public deleteEvent({ getState, patchState, dispatch }: StateContext<EventsStateModel>, { id }: EVENTS_ACTIONS.DeleteEvent) {
    return this._mainService
      .deleteEvent(id)
      .pipe(
        tap((isDeleted) => {
          if (isDeleted) {
            const pagination = { ...getState().pagination }
            if (pagination.recordsTotalCount && (pagination.recordsTotalCount - 1) % pagination.pageSize === 0) {
              patchState({
                pagination: {
                  ...getState().pagination,
                  pageNumber: pagination.pageNumber > 0 ? pagination.pageNumber - 1 : pagination.pageNumber
                }
              })
            }
          } else {
            return
          }
        }
        )
      );
  }

  @Action(EVENTS_ACTIONS.GetActiveCategories)
  public getActiveCategory({ patchState }: StateContext<EventsStateModel>, { typeFor }: EVENTS_ACTIONS.GetActiveCategories) {
    return this._mainService.getActiveCategories(typeFor).pipe(
      tap((activeCategories) =>
        patchState({
          activeCategories,
        })
      )
    );
  }

  @Action(EVENTS_ACTIONS.GetActiveCities)
  public getActiveCities({ patchState, getState }: StateContext<EventsStateModel>) {
    return this._mainService.getActiveCities().pipe(
      tap((activeCities) => {
        const { event } = getState();
        let activeCitiesCopy: EVENTS_MODELS.ActiveCity[] = [...activeCities]

        if (event && event.location && activeCitiesCopy.findIndex((city) => city.id === event.location.city.id) === -1) {
          activeCitiesCopy.push(event.location.city);
          activeCitiesCopy.sort((a, b) => (a.name < b.name ? -1 : 1));
        }
        patchState({ activeCities: activeCitiesCopy });
        // patchState({ activeCities });
      })
    );
  }

  @Action(EVENTS_ACTIONS.GetActiveVenues)
  public getActiveVenues({ patchState, getState, }: StateContext<EventsStateModel>) {
    return this._mainService.getActiveVenues().pipe(
      tap((activeVenues) => {
        const { event } = getState();
        let activeVenuesCopy: EVENTS_MODELS.ActiveVenueModel[] = [...activeVenues,];
        if (event && event.venue && activeVenuesCopy.findIndex((venue) => venue.id === event.venue?.id) === -1) {
          activeVenuesCopy.push(event.venue);
          activeVenuesCopy.sort((a, b) => (a.name < b.name ? -1 : 1));
        }
        patchState({ activeVenues: activeVenuesCopy });
      })
    );
  }

  @Action(EVENTS_ACTIONS.CreateEvent)
  public createEvent({ dispatch }: StateContext<EventsStateModel>, { event }: EVENTS_ACTIONS.CreateEvent) {
    return this._mainService.createEvent(event).pipe(tap((res) => (res ? dispatch(new Navigate(['/events'])) : '')));
  }

  @Action(EVENTS_ACTIONS.GetEvent)
  public getEvent({ patchState, getState }: StateContext<EventsStateModel>, { id }: EVENTS_ACTIONS.GetEvent) {
    return this._mainService.getEventDetails(id).pipe(
      tap((event) => {

        const { activeCities } = getState();
        let activeCitiesCopy: EVENTS_MODELS.ActiveCity[] = [...activeCities];
        let activeVenuesCopy = [...getState().activeVenues];

        if (event.location && activeCitiesCopy.findIndex((city) => city.id === event.location.city.id) === -1) {
          activeCitiesCopy.push(event.location.city);
          activeCitiesCopy.sort((a, b) => (a.name < b.name ? -1 : 1));
        }
        if (event.venue && activeVenuesCopy.findIndex((venue) => venue.id === event?.venue?.id) === -1) {
          activeVenuesCopy.push(event.venue)
          activeVenuesCopy.sort((a, b) => (a.name < b.name ? -1 : 1))
        }
        patchState({
          event, activeCities: activeCitiesCopy,
          activeVenues: activeVenuesCopy
        });
      })
    );
  }

  @Action(EVENTS_ACTIONS.UpdateEvent)
  public updateVenue({ dispatch }: StateContext<EventsStateModel>, { id, event }: EVENTS_ACTIONS.UpdateEvent) {
    return this._mainService.updateEvent(id, event).pipe(tap((res) => res ? dispatch(new Navigate(['events', 'view', id])) : ''));
  }

  @Action(EVENTS_ACTIONS.GetEventDetails)
  public getEventDetails({ patchState }: StateContext<EventsStateModel>, { id }: EVENTS_ACTIONS.GetEventDetails) {
    return this._mainService.getEventDetails(id).pipe(
      tap((eventDetails: EVENTS_MODELS.EventDetailsModel) => {
        patchState({
          eventDetails,
        });
      })
    );
  }

  @Action(EVENTS_ACTIONS.UpdateIsFeaturedForEvent)
  public updateIsFeaturedForEvent({ dispatch }: StateContext<EventsStateModel>, { id, isFeatured }: EVENTS_ACTIONS.UpdateIsFeaturedForEvent) {
    return this._mainService
      .updateIsFeaturedForEvent(id, isFeatured).pipe(
        tap((result) =>
          result ? dispatch(new EVENTS_ACTIONS.GetEventDetails(id)) : ''
        )
      );
  }

  @Action(EVENTS_ACTIONS.UpdateEventCode)
  public updateEventCode({ dispatch }: StateContext<EventsStateModel>, { id, code }: EVENTS_ACTIONS.UpdateEventCode) {
    return this._mainService.updateEventCode(id, code).pipe(
      tap((result) =>
        result ? dispatch(new EVENTS_ACTIONS.GetEventDetails(id)) : ''
      )
    );
  }

  @Action(EVENTS_ACTIONS.GetCustomerOrdersForEvent)
  public getCustomerOrdersForEvent({ getState, patchState, }: StateContext<EventsStateModel>) {
    const { customerOrdersPagination: { pageNumber, pageSize }, } = getState();
    const searchQuery = getState().customerOrdersSearchQuery;
    const sortBy = getState().customerOrdersSortBy;
    const id = getState().eventDetails.id;
    return this._mainService.getCustomerOrdersForEvent({ pageNumber, pageSize }, id, { searchQuery, sortBy, }).pipe(tap(
      ({ records, recordsTotalCount, totalPages, pageNumber, pageSize, }: PaginationModel<EVENTS_MODELS.CustomerOrderForEventModel>) => {
        patchState({
          customerOrders: records,
          customerOrdersPagination: { totalPages, recordsTotalCount, pageSize, pageNumber },
        });
      }
    )
    );
  }

  @Action(EVENTS_ACTIONS.PaginateCustomerOrders)
  public paginateCustomerOrders({ dispatch, patchState, getState }: StateContext<EventsStateModel>, { pagination }: EVENTS_ACTIONS.PaginateCustomerOrders) {
    patchState({
      customerOrdersPagination: {
        ...getState().customerOrdersPagination,
        pageNumber: pagination.pageNumber,
      },
    });
    dispatch(new EVENTS_ACTIONS.GetCustomerOrdersForEvent());
  }

  @Action(EVENTS_ACTIONS.SortCustomerOrders)
  public sortCustomerOrders({ getState, patchState, dispatch }: StateContext<EventsStateModel>, { customerOrdersSortBy }: EVENTS_ACTIONS.SortCustomerOrders) {
    patchState({
      customerOrdersSortBy,
      customerOrdersPagination: {
        ...getState().customerOrdersPagination,
        pageNumber: 0,
      },
    });
    dispatch(new EVENTS_ACTIONS.GetCustomerOrdersForEvent());
  }

  @Action(EVENTS_ACTIONS.SearchCustomerOrders)
  public searchCustomerOrders({ getState, patchState, dispatch }: StateContext<EventsStateModel>, { customerOrdersSearchQuery }: EVENTS_ACTIONS.SearchCustomerOrders) {
    patchState({
      customerOrdersSearchQuery,
      customerOrdersPagination: {
        ...getState().customerOrdersPagination,
        pageNumber: 0,
      },
    });

    dispatch(new EVENTS_ACTIONS.GetCustomerOrdersForEvent());
  }

  @Action(EVENTS_ACTIONS.RejectEventBooking)
  public RejectEventBooking(
    { dispatch }: StateContext<EventsStateModel>,
    { eventBookingId }: EVENTS_ACTIONS.RejectEventBooking
  ) {
    return this._mainService.rejectEventBooking(eventBookingId).pipe(
      tap((result) =>
        result ? dispatch(new EVENTS_ACTIONS.GetCustomerOrdersForEvent()) : ''
      )
    )
  }

  @Action(EVENTS_ACTIONS.GetWaitingApprovalEvents)
  public GetWaitingApprovalEvents({ getState, patchState, }: StateContext<EventsStateModel>) {
    const { WaitingApprovalEventsPagination: { pageNumber, pageSize }, } = getState();
    const searchQuery = getState().waitingApprovalEventsSearchQuery;
    const sortBy = getState().waitingApprovalEventsSortBy;
    return this._mainService.getEventsAwaitingApproval({ pageNumber, pageSize }, { searchQuery, sortBy }).pipe(tap(
      ({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<EVENTS_MODELS.WaitingEventApprovalModel>) => {
        patchState({
          allWaitingApprovalEvents: records,
          WaitingApprovalEventsPagination: { recordsTotalCount, totalPages, pageNumber, pageSize },
        });
      }
    )
    );
  }

  @Action(EVENTS_ACTIONS.PaginateWaitingApprovalEvents)
  public PaginateWaitingApprovalEvents({ dispatch, patchState, getState }: StateContext<EventsStateModel>, { pagination }: EVENTS_ACTIONS.PaginateWaitingApprovalEvents) {
    patchState({
      WaitingApprovalEventsPagination: {
        ...getState().WaitingApprovalEventsPagination,
        pageNumber: pagination.pageNumber,
      },
    });
    dispatch(new EVENTS_ACTIONS.GetWaitingApprovalEvents());
  }

  @Action(EVENTS_ACTIONS.SortWaitingApprovalEvents)
  public SortWaitingApprovalEvents({ getState, patchState, dispatch }: StateContext<EventsStateModel>, { waitingApprovalEventsSortBy }: EVENTS_ACTIONS.SortWaitingApprovalEvents) {
    patchState({
      waitingApprovalEventsSortBy,
      WaitingApprovalEventsPagination: {
        ...getState().WaitingApprovalEventsPagination,
        pageNumber: 0,
      },
    });
    dispatch(new EVENTS_ACTIONS.GetWaitingApprovalEvents());
  }

  @Action(EVENTS_ACTIONS.SearchWaitingApprovalEvents)
  public SearchWaitingApprovalEvents({ getState, patchState, dispatch }: StateContext<EventsStateModel>, { waitingApprovalEventsSearchQuery }: EVENTS_ACTIONS.SearchWaitingApprovalEvents) {
    patchState({
      waitingApprovalEventsSearchQuery,
      WaitingApprovalEventsPagination: {
        ...getState().WaitingApprovalEventsPagination,
        pageNumber: 0,
      },
    });
    dispatch(new EVENTS_ACTIONS.GetWaitingApprovalEvents());
  }

  @Action(EVENTS_ACTIONS.ApproveWaitingEvent)
  public ApproveWaitingEvent({ dispatch, getState, patchState }: StateContext<EventsStateModel>, { id }: EVENTS_ACTIONS.ApproveWaitingEvent) {
    return this._mainService.approveWaitingEvent(id).pipe(tap((result) => {
      if (result) {
        const pagination = { ...getState().WaitingApprovalEventsPagination };
        if (pagination.recordsTotalCount && (pagination.recordsTotalCount - 1) % pagination.pageSize === 0) {
          patchState({
            WaitingApprovalEventsPagination: {
              ...getState().WaitingApprovalEventsPagination,
              pageNumber: pagination.pageNumber > 0 ? pagination.pageNumber - 1 : pagination.pageNumber,
            },
          });
        }
        dispatch(new EVENTS_ACTIONS.GetWaitingApprovalEvents());
      } else {
        return;
      }
    })
    );
  }

  @Action(EVENTS_ACTIONS.RejectWaitingEvent)
  public RejectWaitingEvent({ dispatch, getState, patchState }: StateContext<EventsStateModel>, { id }: EVENTS_ACTIONS.RejectWaitingEvent) {
    return this._mainService.rejectWaitingEvent(id).pipe(tap((result) => {
      if (result) {
        const pagination = { ...getState().WaitingApprovalEventsPagination, };
        if (pagination.recordsTotalCount && (pagination.recordsTotalCount - 1) % pagination.pageSize === 0) {
          patchState({
            WaitingApprovalEventsPagination: {
              ...getState().WaitingApprovalEventsPagination,
              pageNumber: pagination.pageNumber > 0 ? pagination.pageNumber - 1 : pagination.pageNumber,
            },
          });
        }
        dispatch(new EVENTS_ACTIONS.GetWaitingApprovalEvents());
      } else {
        return;
      }
    })
    );
  }

  @Action(EVENTS_ACTIONS.GetWaitingApprovalEventDetails)
  public GetWaitingApprovalEventDetails({ patchState }: StateContext<EventsStateModel>, { id }: EVENTS_ACTIONS.GetWaitingApprovalEventDetails) {
    return this._mainService.getEventAwaitingApprovalDetails(id).pipe(
      tap((waitingApprovalEventDetails) => {
        patchState({
          waitingApprovalEventDetails,
        });
      })
    );
  }

  @Action(EVENTS_ACTIONS.GetVenueAdminWaitingApprovalEvents)
  public GetVenueAdminWaitingApprovalEvents({ getState, patchState }: StateContext<EventsStateModel>) {
    const { venueAdminWaitingApprovalEventsPagination: { pageNumber, pageSize }, } = getState();
    const searchQuery = getState().venueAdminWaitingApprovalEventsSearchQuery;
    const sortBy = getState().venueAdminWaitingApprovalEventsSortBy;
    return this._mainService.GetMyEventsAwaitingApproval({ pageNumber, pageSize }, { searchQuery, sortBy }).pipe(tap(
      ({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<EVENTS_MODELS.WaitingEventApprovalModel>) => {
        patchState({
          allVenueAdminWaitingApprovalEvents: records,
          venueAdminWaitingApprovalEventsPagination: { totalPages, recordsTotalCount, pageSize, pageNumber },
        });
      }
    )
    );
  }

  @Action(EVENTS_ACTIONS.PaginateVenueAdminWaitingApprovalEvents)
  public PaginateVenueAdminWaitingApprovalEvents({ dispatch, patchState, getState }: StateContext<EventsStateModel>, { pagination }: EVENTS_ACTIONS.PaginateVenueAdminWaitingApprovalEvents) {
    patchState({
      venueAdminWaitingApprovalEventsPagination: {
        ...getState().venueAdminWaitingApprovalEventsPagination,
        pageNumber: pagination.pageNumber,
      },
    });
    dispatch(new EVENTS_ACTIONS.GetVenueAdminWaitingApprovalEvents());
  }

  @Action(EVENTS_ACTIONS.SortVenueAdminWaitingApprovalEvents)
  public SortVenueAdminWaitingApprovalEvents({ getState, patchState, dispatch }: StateContext<EventsStateModel>, { venueAdminWaitingApprovalEventsSortBy }: EVENTS_ACTIONS.SortVenueAdminWaitingApprovalEvents) {
    patchState({
      venueAdminWaitingApprovalEventsSortBy,
      venueAdminWaitingApprovalEventsPagination: {
        ...getState().venueAdminWaitingApprovalEventsPagination,
        pageNumber: 0,
      },
    });
    dispatch(new EVENTS_ACTIONS.GetVenueAdminWaitingApprovalEvents());
  }

  @Action(EVENTS_ACTIONS.SearchVenueAdminWaitingApprovalEvents)
  public SearchVenueAdminWaitingApprovalEvents({ getState, patchState, dispatch }: StateContext<EventsStateModel>, { venueAdminWaitingApprovalEventsSearchQuery }: EVENTS_ACTIONS.SearchVenueAdminWaitingApprovalEvents) {
    patchState({
      venueAdminWaitingApprovalEventsSearchQuery,
      venueAdminWaitingApprovalEventsPagination: {
        ...getState().venueAdminWaitingApprovalEventsPagination,
        pageNumber: 0,
      },
    });
    dispatch(new EVENTS_ACTIONS.GetVenueAdminWaitingApprovalEvents());
  }

  @Action(EVENTS_ACTIONS.DeleteWaitingApprovalEventRequest)
  public DeleteWaitingApprovalEventRequest({ dispatch, getState, patchState }: StateContext<EventsStateModel>, { requestId }: EVENTS_ACTIONS.DeleteWaitingApprovalEventRequest) {
    return this._mainService.deleteWaitingApprovalEventRequest(requestId).pipe(tap((isDeleted) => {
      if (isDeleted) {
        const pagination = { ...getState().venueAdminWaitingApprovalEventsPagination, };
        if (pagination.recordsTotalCount && (pagination.recordsTotalCount - 1) % pagination.pageSize === 0) {
          patchState({
            venueAdminWaitingApprovalEventsPagination: {
              ...getState().venueAdminWaitingApprovalEventsPagination,
              pageNumber: pagination.pageNumber > 0 ? pagination.pageNumber - 1 : pagination.pageNumber,
            },
          });
        }
        dispatch(new EVENTS_ACTIONS.GetVenueAdminWaitingApprovalEvents());
      }
    })
    );
  }
}

import { validationPatterns } from '@shared/modules/validation/config/validation-patterns';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ChangePassword } from '@core/auth/state/auth.actions';
import { Select, Store } from '@ngxs/store';
import { validationErrorMessages } from '@shared/modules/validation/config/validation-messages';
import { ValidationService } from '@shared/modules/validation/validation.service';

@Component({
  selector: 'app-outout-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

  changePasswordForm: FormGroup;
  errorMessage = validationErrorMessages;

  constructor(private _store: Store, private _fb: FormBuilder,private _validationService:ValidationService) {
    this.changePasswordForm = this._fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword:['', [Validators.required, Validators.minLength(8), Validators.maxLength(25), Validators.pattern(validationPatterns.password)]],
      confirmPassword:['', [Validators.required]]
    },{
      validators: this._validationService.passwordMatchValidator('newPassword', 'confirmPassword')
    })

  }

  oldHide = true;
  hide = true;
  confirm_hide = true;

  public changePassword() {
    this._store.dispatch(new ChangePassword(this.changePasswordForm.value));
  }
}

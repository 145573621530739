<div class="auth">
  <div class="grid items-center h-screen md:grid-cols-2">
    <div class="{{wrapperClassList}} items-center justify-center order-2 px-4 md:order-1 m-auto">
      <h1 class="mb-12 {{titleClassList}}">{{name}}</h1>
      <ng-content></ng-content>
    </div>
    <div class="order-1 w-full h-full auth-lg md:order-2"></div>
  </div>
</div>


import { MyProfileDetailsModel } from '@core/modules/user/model/user.model';
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { loggedInUserModel } from '../model/user.model';

export class SetUser {
  static readonly type = '[USER] Set User';
  constructor(public user: loggedInUserModel) { }
}

export class loadUserInfo {
  static readonly type = '[USER] Load info';
}

export class GetUserProperty {
  static readonly type = '[USER] Get User Property';
  constructor(public propertyName: string) { }
}


export class UpdateUserProperty {
  static readonly type = '[USER] Update User Property';
  constructor(public property: string, public value: string) { }
}

export class UpdateRoles {
  static readonly type = '[USER] Update User Roles';
  constructor(public roles: string[]) { }
}
export class GetMyProfileDetails {
  static readonly type = '[USER] Get Profile Details';
}

export class UpdateMyProfileDetails {
  static readonly type = '[USER] Update Profile Details';
  constructor(public profile: FormData) {}
}

export class GetVenuesCreatedByMe {
  static readonly type = '[USER] Get Venues Created By Me'
}

export class PaginateVenuesCreatedByMe {
  static readonly type = '[USER] Paginate Venues Created By Me'
  constructor(public venuesPagination: PaginationConfigModel) { }
}


export class GetEventsCreatedByMe {
  static readonly type = '[USER] Get Events Created By Me'
}

export class PaginateEventsCreatedByMe {
  static readonly type = '[USER] Paginate Events Created By Me'
  constructor(public eventsPagination: PaginationConfigModel) { }
}
export class ResetUserInfo {
  static readonly type = '[USER] Reset User Info';
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { OutOutConfigInst } from 'src/app/config/app.config';
import { ModalsService } from '@shared/modules/modals/model/modals.service';
import { Select, Store } from '@ngxs/store';
import { UserState } from '@core/modules/user/state/user.state';
import { NavigationEnd, Router } from '@angular/router';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { loggedInUserModel } from '@core/modules/user/model/user.model';
import { Search } from '@modules/search-and-filter-results/state/search-and-filter-results.actions';
import { Observable } from 'rxjs';
import { SearchFilterResultsState } from '@modules/search-and-filter-results/state/search-and-filter-results.state';
import { RouterState } from '@ngxs/router-plugin';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';





@Component({
  selector: 'app-user-nav',
  templateUrl: './user-nav.component.html',
  styleUrls: ['./user-nav.component.scss']
})
export class UserNavComponent implements OnInit {

  constructor(
    private _modals: ModalsService,
    private _store: Store,
    private _router: Router,
    private _location: Location,
    
  ) {
  }
  public isOpened = false;
  @ViewChild(SearchInputComponent) public input: SearchInputComponent

  ngOnInit(): void {
    this._router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const url = this._location.path();
        if (url !== '/result') {
          this.input.resetInput()
        }
      });
  }


  @ViewSelectSnapshot(UserState.user) public loggedInUser;

  public confirmLogout() {
    this.isOpened = !this.isOpened
    this._modals.openConfirmationDialog({
      title: OutOutConfigInst.CRUD_CONFIG.actions.logout,
      class: 'danger',
      content: OutOutConfigInst.CRUD_CONFIG.confirmationMessages.logout(),
      proceedText: OutOutConfigInst.CRUD_CONFIG.actions.logout,
      cancelText: OutOutConfigInst.CRUD_CONFIG.actions.cancel,
      titleClasses: ['text-center']
    }, () => this._router.navigate([OutOutConfigInst.ROUTES_CONFIG.logout]))
  }


  public onSearchChange({ searchQuery, status }: { searchQuery: string, status: string }) {

    this._store.dispatch(new Search(searchQuery)).subscribe(() => {
      if (this._store.selectSnapshot(RouterState).state.url !== '/result' && status === 'apply') this._router.navigate(['result']);
    })


  }

  public viewMyProfile() {
    this.isOpened = !this.isOpened
    this._router.navigate(['users', 'my-profile'])
  }
}

import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { validationErrorMessages } from './../../../../shared/modules/validation/config/validation-messages';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Login } from '@core/auth/state/auth.actions';
import { HeadRefresherType } from '@core/services/head-refresher/head-refresher.models';
import { HeadRefresherService } from '@core/services/head-refresher/head-refresher.service';
import { Store } from '@ngxs/store';
import { dispatch } from 'rxjs/internal/observable/pairs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public hide: boolean = true;

  constructor(
    private _store: Store,
    private _fb: FormBuilder
    ) {
      this._initForm();
    }

  private _initForm(){
    this.loginForm = this._fb.group({
      email:['', [Validators.required, Validators.email, Validators.maxLength(64)]],
      password:['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]]
    })
  }
  ngOnInit(): void {
  }
  public login() {
    this._store.dispatch(new Login(this.loginForm.value));
  }

}


<outout-auth-layout
name="Login to your account"
wrapperClassList="w-full md:w-2/3 xl:w-1/2"
>
  <form [formGroup]="loginForm" (ngSubmit)="login()" class="w-full">
    <div class="form-group">
      <label for="email" class="block pb-4">
        E-mail
        <span class="text-red-700">*</span>
      </label>
      <mat-form-field appearance="outline" class="w-full">
        <input matInput="matInput" type="email" placeholder="Your Email" id="email" formControlName="email">
        <mat-icon matPrefix="matPrefix">email</mat-icon>
      </mat-form-field>
      <mat-error class="pl-5">
        <app-validation-error [control]="loginForm.get('email')" businessName="Email">
        </app-validation-error>
      </mat-error>
    </div>

    <div class="mt-6 form-group">
      <label for="password" class="block pb-4">
        Password
        <span class="text-red-700">*</span>
      </label>

      <mat-form-field appearance="outline" class="w-full">
        <input matInput="matInput" placeholder="Your Password" [type]="hide ? 'password' : 'text'" id="password"
          formControlName="password">

        <mat-icon matPrefix="matPrefix" aria-label>vpn_key</mat-icon>
        <button mat-icon-button="mat-icon-button" type="button" matSuffix="matSuffix" (click)="hide = !hide"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>
            {{ hide ? "visibility_off" : "visibility" }}
          </mat-icon>
        </button>
      </mat-form-field>
      <mat-error class="pl-5">
        <app-validation-error [control]="loginForm.get('password')" businessName="Password">
        </app-validation-error>
      </mat-error>
    </div>

    <div class="flex justify-end mt-6 form-group align-center">
      <!-- <mat-checkbox class="example-margin">Remember me</mat-checkbox> -->

      <a routerLink="/guest/forget-password">Forgot Password?</a>
    </div>

    <div class="float-right mt-6">
      <button mat-flat-button class="px-12 py-3 font-bold rounded-full action-btn" [disabled]="loginForm.invalid">Login</button>
    </div>
  </form>
</outout-auth-layout>

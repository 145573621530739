import { AuthorizationState } from '@core/modules/authorization/state/authorization.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { OutOutConfigInst } from 'src/app/config/app.config';
import { StorageService } from '@core/services/storage/storage.service';
import { Router } from '@angular/router';
import { AfterViewChecked,  Component } from '@angular/core';
// import * as anime from 'animejs';
import anime from 'animejs/lib/anime.es';
@Component({
  selector: 'app-outout-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements  AfterViewChecked {

  constructor(
    private _router: Router,
    private _storage: StorageService
  ) { }

  private _token: string;
  @ViewSelectSnapshot(AuthorizationState.isAuthorizedUser) public isAuthorizedUser: boolean;



  ngAfterViewChecked() {
    setTimeout(() => {
      //only go to login page when you have token and authorized role
      if (this.isAuthorizedUser && this._storage.get('token')) {
        this._router.navigateByUrl(OutOutConfigInst.ROUTES_CONFIG.root)
      } else {
        this._router.navigateByUrl(OutOutConfigInst.ROUTES_CONFIG.login)
      }
    }, 2000);

  }


}

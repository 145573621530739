import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'outout-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  @Input() name: string;
  @Input() titleClassList: string
  @Input() wrapperClassList: string
  constructor() { }

  ngOnInit(): void {
  }

}

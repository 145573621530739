<section
  class="p-0 dialog-wrapper ConfirmationDialogData d-flex flex-column col-12 justify-content-between">




  <section mat-dialog-content class="mat-typography">


    <div class="content flex">
      <mat-icon svgIcon="warning-icon"></mat-icon>
      <p [innerHTML]="data.content"></p>
      
    </div>
  </section>


  <!-- Confirmation Dialog Actions -->
  <section mat-dialog-actions align="end">

    <!-- Cancel Button -->
    <button *ngIf="!data.noCancelButton" mat-button mat-dialog-close="cancel" class="cancel-delete-button">
      {{ data.cancelText || "Cancel" }}
    </button>

    <!-- Proceed Button -->
    <button mat-button cdkFocusInitial mat-flat-button class="confirm-delete-button" mat-dialog-close="proceed"
      cdkFocusInitial>
      {{ data.proceedText || "Confirm" }}
    </button>


  </section>


</section>

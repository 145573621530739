
import { Injectable } from "@angular/core";
import { PaginationModel } from "@core/http/apis.model";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { PaginationConfigModel } from "@shared/modules/pagination/model/pagination.model";
import { tap } from "rxjs/operators";
import * as SEARCH_FILTER_MODELS from '@modules/search-and-filter-results/models/search-and-filter-results.model';
import * as SEARCH_FILTER_ACTIONS from '@modules/search-and-filter-results/state/search-and-filter-results.actions';
import { SearchAndFilterResultsService } from "../models/search-and-filter-results.service";
import { RouterState } from "@ngxs/router-plugin";
import { AuthorizationState } from '@core/modules/authorization/state/authorization.state';



export class SearchFilterResultsStateModel {
    public venuesSearchFilterResults: SEARCH_FILTER_MODELS.VenueSearchFilterResultModel[];
    public eventsSearchFilterResults: SEARCH_FILTER_MODELS.EventSearchFilterResultModel[];
    public offersSearchFilterResults: SEARCH_FILTER_MODELS.OfferSearchFilterResultModel[];
    public venuesPagination: PaginationConfigModel;
    public eventsPagination: PaginationConfigModel;
    public offersPagination: PaginationConfigModel;
    public filtration: SEARCH_FILTER_MODELS.FiltrationModel;
    public searchQuery: string;

    constructor() {
        this.venuesSearchFilterResults = null;
        this.eventsSearchFilterResults = null;
        this.offersSearchFilterResults = null;
        this.filtration = {
            searchQuery: 'hi',
            venueCategories: null,
            eventCategories: null,
            featuredEvents: false,
            offerTypeId: null,
            cityId: null,
            from: null,
            to: null
        };
        this.venuesPagination = {
            pageNumber: 0,
            pageSize: 3
        }
        this.eventsPagination = {
            pageNumber: 0,
            pageSize: 3
        }
        this.offersPagination = {
            pageNumber: 0,
            pageSize: 3
        }

        this.searchQuery = "";
    }
}


@Injectable()
@State<SearchFilterResultsStateModel>({
    name: 'searchFilterResults',
    defaults: new SearchFilterResultsStateModel()
})

export class SearchFilterResultsState {
    constructor(
        private _mainService: SearchAndFilterResultsService,
        private _store: Store
    ) { }
    /*__________________________________________SELECTORS___________________________________*/

    @Selector() static venuesResults(state: SearchFilterResultsStateModel): SEARCH_FILTER_MODELS.VenueSearchFilterResultModel[] {
        return state.venuesSearchFilterResults
    }


    @Selector() static eventsResults(state: SearchFilterResultsStateModel): SEARCH_FILTER_MODELS.EventSearchFilterResultModel[] {
        return state.eventsSearchFilterResults
    }


    @Selector() static offersResults(state: SearchFilterResultsStateModel): SEARCH_FILTER_MODELS.OfferSearchFilterResultModel[] {
        return state.offersSearchFilterResults
    }


    @Selector() static venuesPagination(state: SearchFilterResultsStateModel): PaginationConfigModel {
        return { ...state.venuesPagination }
    }

    @Selector() static eventsPagination(state: SearchFilterResultsStateModel): PaginationConfigModel {
        return { ...state.eventsPagination }
    }

    @Selector() static offersPagination(state: SearchFilterResultsStateModel): PaginationConfigModel {
        return { ...state.offersPagination }
    }

    @Selector() static filtration(state: SearchFilterResultsStateModel): SEARCH_FILTER_MODELS.FiltrationModel {
        return { ...state.filtration }
    }

    @Selector() static searchQuery(state: SearchFilterResultsStateModel): string {
        return state.searchQuery
    }



    /*_______________________________________REDUCERS____________________________________*/
    @Action(SEARCH_FILTER_ACTIONS.GetVenuesSearchFilterResults)
    public getVenuesSearchFilterResults({ getState, patchState }: StateContext<SearchFilterResultsStateModel>) {
        const { venuesPagination: { pageNumber, pageSize }, searchQuery } = getState();
        let filtration = { ...getState().filtration }
        if (filtration.to && filtration.from) {
            filtration = {
                ...filtration,
                from: this._getDateUTC(filtration.from),
                to: this._getDateUTC(filtration.to)

            }
        }
        return this._mainService.venuesSearchFilter({ pageNumber, pageSize }, { ...filtration, searchQuery }).pipe(
            tap(({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<SEARCH_FILTER_MODELS.VenueSearchFilterResultModel>) => {
                patchState({
                    venuesSearchFilterResults: records,
                    venuesPagination: { recordsTotalCount, totalPages, pageNumber, pageSize }
                })
            })
        )
    }


    @Action(SEARCH_FILTER_ACTIONS.PaginateVenues)
    public paginateVenues({ dispatch, patchState, getState }: StateContext<SearchFilterResultsStateModel>, { venuesPagination }: SEARCH_FILTER_ACTIONS.PaginateVenues) {
        patchState({
            venuesPagination: {
                ...getState().venuesPagination,
                pageNumber: venuesPagination.pageNumber
            }
        });
        dispatch(new SEARCH_FILTER_ACTIONS.GetVenuesSearchFilterResults)
    }


    @Action(SEARCH_FILTER_ACTIONS.GetEventsSearchFilterResults)
    public getEventsSearchFilterResults({ getState, patchState }: StateContext<SearchFilterResultsStateModel>) {
        const { eventsPagination: { pageNumber, pageSize }, searchQuery } = getState();
        let filtration = { ...getState().filtration }
        if (filtration.to && filtration.from) {
            // const date = new Date(filtration.to);
            // date.setDate(date.getDate() + 1);
            filtration = {
                ...filtration,
                from: this._getDateUTC(filtration.from),
                to: this._getDateUTC(filtration.to)

            }
        }

        return this._mainService.eventsSearchFilter({ pageNumber, pageSize }, { ...filtration, searchQuery }).pipe(
            tap(({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<SEARCH_FILTER_MODELS.EventSearchFilterResultModel>) => {
                patchState({
                    eventsSearchFilterResults: records,
                    eventsPagination: { recordsTotalCount, totalPages, pageNumber, pageSize }
                })
            })
        )
    }

    @Action(SEARCH_FILTER_ACTIONS.PaginateEvents)
    public paginateEvents({ dispatch, patchState, getState }: StateContext<SearchFilterResultsStateModel>, { eventsPagination }: SEARCH_FILTER_ACTIONS.PaginateEvents) {
        patchState({
            eventsPagination: {
                ...getState().eventsPagination,
                pageNumber: eventsPagination.pageNumber
            }
        });
        dispatch(new SEARCH_FILTER_ACTIONS.GetEventsSearchFilterResults)
    }



    @Action(SEARCH_FILTER_ACTIONS.GetOffersSearchFilterResults)
    public getOffersSearchFilterResults({ getState, patchState }: StateContext<SearchFilterResultsStateModel>) {
        const { offersPagination: { pageNumber, pageSize }, searchQuery } = getState();
        let filtration = { ...getState().filtration }
        if (filtration.to && filtration.from) {
            filtration = {
                ...filtration,
                from: this._getDateUTC(filtration.from),
                to: this._getDateUTC(filtration.to)

            }
        }
        return this._mainService.offersSearchFilter({ pageNumber, pageSize }, { ...filtration, searchQuery }).pipe(
            tap(({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<SEARCH_FILTER_MODELS.OfferSearchFilterResultModel>) => {
                patchState({
                    offersSearchFilterResults: records,
                    offersPagination: { recordsTotalCount, totalPages, pageNumber, pageSize }
                })
            })
        )
    }


    @Action(SEARCH_FILTER_ACTIONS.PaginateOffers)
    public paginateOffers({ dispatch, patchState, getState }: StateContext<SearchFilterResultsStateModel>, { offersPagination }: SEARCH_FILTER_ACTIONS.PaginateOffers) {
        patchState({
            offersPagination: {
                ...getState().offersPagination,
                pageNumber: offersPagination.pageNumber
            }
        });
        dispatch(new SEARCH_FILTER_ACTIONS.GetOffersSearchFilterResults)
    }


    /*_____________________________Filtration_______________________*/

    @Action(SEARCH_FILTER_ACTIONS.Filter)
    public filter(ctx: StateContext<SearchFilterResultsStateModel>, { filtration }: SEARCH_FILTER_ACTIONS.Filter) {
        const filtrationConfig = ctx.getState().filtration;
        ctx.patchState({
            filtration: {
                ...filtrationConfig,
                ...filtration
            },
            // Don't ever forget to reset the page number to zero when you filter or search
            eventsPagination: { pageSize: 3, pageNumber: 0 },
            offersPagination: { pageSize: 3, pageNumber: 0 },
            venuesPagination: { pageSize: 3, pageNumber: 0 }
        });
        /**
      * @todo refactor this condition to cover issue OA-414, OA-415 and OA-416
      */
        if (this._store.selectSnapshot(RouterState).state.url !== '/result') return
        if (this._store.selectSnapshot(AuthorizationState.isEventAdmin)) {
            ctx.dispatch([new SEARCH_FILTER_ACTIONS.GetEventsSearchFilterResults]);
        } else {
            ctx.dispatch([new SEARCH_FILTER_ACTIONS.GetVenuesSearchFilterResults,
            new SEARCH_FILTER_ACTIONS.GetEventsSearchFilterResults,
            new SEARCH_FILTER_ACTIONS.GetOffersSearchFilterResults]);
        }
    }



    /*______________________Search___________________*/

    @Action(SEARCH_FILTER_ACTIONS.Search)
    public SearchMyTasks({ patchState, dispatch }: StateContext<SearchFilterResultsStateModel>, { searchQuery }: SEARCH_FILTER_ACTIONS.Search) {
        patchState({
            searchQuery,
            // Don't ever forget to reset the page number to zero when you filter or search
            eventsPagination: { pageSize: 3, pageNumber: 0 },
            offersPagination: { pageSize: 3, pageNumber: 0 },
            venuesPagination: { pageSize: 3, pageNumber: 0 }
        });
        /**
         * @todo refactor this condition to cover issue OA-414, OA-415 and OA-416
         */
        if (this._store.selectSnapshot(RouterState).state.url !== '/result') return
        if (this._store.selectSnapshot(AuthorizationState.isEventAdmin)) {
            dispatch([new SEARCH_FILTER_ACTIONS.GetEventsSearchFilterResults]);
        } else {
            dispatch([new SEARCH_FILTER_ACTIONS.GetVenuesSearchFilterResults,
            new SEARCH_FILTER_ACTIONS.GetEventsSearchFilterResults,
            new SEARCH_FILTER_ACTIONS.GetOffersSearchFilterResults]);
        }

    }
    /**
     * @todo function to convert the date to UTC with 00 Time.
     */
    private _getDateUTC(d: Date) {
        const month = d.getMonth() + 1
        return d ? `${d.getFullYear()}-${month < 10 ? '0' + month : month}-${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}T00:00:00.000Z` : null
    }


}

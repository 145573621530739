import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { CodeVerificationComponent } from './pages/code-verification/code-verification.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '@core/auth/pages/login/login.component';
import { AutoLoginComponent } from './pages/autologin/autologin.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { SplashComponent } from './pages/splash/splash.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent
  },
  // {
  //   path: 'code-verification',
  //   component: CodeVerificationComponent
  // },
  // {
  //   path: 'reset-password',
  //   component: ResetPasswordComponent
  // },
  // {
  //   path: 'change-password',
  //   component: ChangePasswordComponent
  // },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'splash',
    component: SplashComponent
  },
  {
    path: 'auto-login',
    component: AutoLoginComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }

import { Navigate } from '@ngxs/router-plugin';
import { Injectable } from "@angular/core";
import { PaginationModel } from "@core/http/apis.model";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { PaginationConfigModel } from "@shared/modules/pagination/model/pagination.model";
import { tap } from "rxjs/operators";
import * as DEALS_MODELS from '@modules/deals/models/deals.model';
import * as DEALS_ACTIONS from '@modules/deals/state/deals.actions';
import { DealsService } from '../models/deals.service';
import { stat } from 'fs';


export class DealsStateModel {
    public allDeals: DEALS_MODELS.DealModel[];
    public searchQuery: string;
    public sortBy: number;
    public pagination: PaginationConfigModel
    public activeVenues: DEALS_MODELS.VenueModel[];
    public dealTypes: DEALS_MODELS.DealTypeModel[];
    public assignedDeal: DEALS_MODELS.AssignedDealModel;

    constructor() {
        this.allDeals = null

        this.pagination = {
            pageNumber: 0,
            pageSize: 6,
        }

        this.sortBy = 0

        this.searchQuery = "";
        this.activeVenues = null;
        this.dealTypes = null;
        this.assignedDeal = null;

    }
}


@Injectable()
@State<DealsStateModel>({
    name: 'deals',
    defaults: new DealsStateModel()
})

export class DealsState {

    constructor(private _mainService: DealsService) { }

    @Selector() static allDeals(state: DealsStateModel): DEALS_MODELS.DealModel[] { return state.allDeals }
    @Selector() static pagination(state: DealsStateModel): PaginationConfigModel { return { ...state.pagination } }
    @Selector() static sort(state: DealsStateModel): number { return state.sortBy }
    @Selector() static searchQuery(state: DealsStateModel): string { return state.searchQuery }
    @Selector() static activeVenues(state: DealsStateModel): DEALS_MODELS.VenueModel[] { return state.activeVenues }
    @Selector() static dealTypes(state: DealsStateModel): DEALS_MODELS.DealTypeModel[] { return state.dealTypes }
    @Selector() static assignedDeal(state: DealsStateModel): DEALS_MODELS.AssignedDealModel { return state.assignedDeal }


    /*_____________________________Reducers___________________________________*/

    @Action(DEALS_ACTIONS.GetAllAssignedDealsPage)
    public getAllAssignedDealsPage({ getState, patchState }: StateContext<DealsStateModel>) {
        const { pagination: { pageNumber, pageSize }, searchQuery, sortBy } = getState();
        return this._mainService.getAssignedDealsPage({ pageNumber, pageSize }, { searchQuery, sortBy }).pipe(
            tap(
                ({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<DEALS_MODELS.DealModel>) => {
                    patchState({
                        allDeals: records,
                        pagination: { totalPages, recordsTotalCount, pageSize, pageNumber }
                    })
                }
            )
        )

    }


    @Action(DEALS_ACTIONS.PaginateAllAssignedDealsPage)
    public paginateAllAssignedDealsPage({ dispatch, patchState, getState }: StateContext<DealsStateModel>, { pagination }: DEALS_ACTIONS.PaginateAllAssignedDealsPage) {
        patchState({
            pagination: {
                ...getState().pagination,
                pageNumber: pagination.pageNumber
            }
        })
        dispatch(new DEALS_ACTIONS.GetAllAssignedDealsPage)
    }


    @Action(DEALS_ACTIONS.SortAllAssignedDealsPage)
    public sortAllAssignedDealsPage({ getState, patchState, dispatch }: StateContext<DealsStateModel>, { sortBy }: DEALS_ACTIONS.SortAllAssignedDealsPage) {
        patchState({
            sortBy,
            pagination: {
                ...getState().pagination,
                pageNumber: 0
            }
        })
        dispatch(new DEALS_ACTIONS.GetAllAssignedDealsPage)
    }


    @Action(DEALS_ACTIONS.SearchAllAssignedDealsPage)
    public searchAllAssignedDealsPage({ getState, patchState, dispatch }: StateContext<DealsStateModel>, { searchQuery }: DEALS_ACTIONS.SearchAllAssignedDealsPage) {
        patchState({
            searchQuery,
            pagination: {
                ...getState().pagination,
                pageNumber: 0
            }
        })

        dispatch(new DEALS_ACTIONS.GetAllAssignedDealsPage)

    }



    @Action(DEALS_ACTIONS.UnassignDealFromVenue)
    public UnassignDealFromVenue({ getState, patchState, dispatch }: StateContext<DealsStateModel>, { venueId, offerId }: DEALS_ACTIONS.UnassignDealFromVenue) {
        return this._mainService.unAssignDealFromVenue(venueId, offerId).pipe(
            tap((isUnassigned) => {
                if (isUnassigned) {
                    const pagination = { ...getState().pagination }
                    if (pagination.recordsTotalCount && (pagination.recordsTotalCount - 1) % pagination.pageSize === 0) {
                        patchState({
                            pagination: {
                                ...getState().pagination,
                                pageNumber: pagination.pageNumber > 0 ? pagination.pageNumber - 1 : pagination.pageNumber
                            }
                        })
                    }
                    dispatch(new DEALS_ACTIONS.GetAllAssignedDealsPage)
                } else {
                    return
                }
            })
        )
    }

    @Action(DEALS_ACTIONS.UnassignAllDeals)
    public unassignAllDeals({ patchState, dispatch }: StateContext<DealsStateModel>) {
        return this._mainService.unassignAllDeals().pipe(
            tap(
                (isUnassigned) => {
                    if (isUnassigned) {
                        patchState({
                            pagination: {
                                pageNumber: 0
                            }
                        })
                        dispatch(new DEALS_ACTIONS.GetAllAssignedDealsPage)
                    } else {
                        return
                    }
                }
            )
        )
    }

    @Action(DEALS_ACTIONS.AssignDeal)
    public assignDeal({ dispatch }: StateContext<DealsStateModel>, { deal }: DEALS_ACTIONS.AssignDeal) {
        return this._mainService.assignDeal(deal).pipe(
            tap((res => res ? dispatch(new Navigate(['/deals'])) : '')))
    }

    @Action(DEALS_ACTIONS.UpdateAssignedDeal)
    public updateAssignedDeal({ dispatch }: StateContext<DealsStateModel>,
        dealData: DEALS_ACTIONS.UpdateAssignedDeal) {
        return this._mainService.updatedAssignedDeal(dealData).pipe(tap((res => res ? dispatch(new Navigate(['/deals'])) : '')));
    }

    @Action(DEALS_ACTIONS.GetAllActiveVenues)
    public getAllActiveVenues({ patchState }: StateContext<DealsStateModel>) {
        return this._mainService.getActiveVenues().pipe(
            tap((activeVenues: DEALS_MODELS.VenueModel[]) => patchState({ activeVenues }))
        );
    }

    @Action(DEALS_ACTIONS.GetDealTypes)
    public getDealType({ patchState }: StateContext<DealsStateModel>) {
        return this._mainService.GetDealTypes().pipe(
            tap((dealTypes: DEALS_MODELS.DealTypeModel[]) => patchState({ dealTypes }))
        );

    }

    @Action(DEALS_ACTIONS.GetAssignedDeal)
    public getAssignedDeal({ patchState, getState }: StateContext<DealsStateModel>, assignedDeal: { dealId: string }) {
        return this._mainService.getAssignedDeal(assignedDeal.dealId).pipe(tap(
            (assignedDeal: DEALS_MODELS.AssignedDealModel) => {
                const { activeVenues } = getState();
                let activeVenuesCopy: DEALS_MODELS.VenueModel[] = [...activeVenues]

                if (activeVenuesCopy.findIndex((venue) => venue.id === assignedDeal.venue.id) === -1) {
                    activeVenuesCopy.push(assignedDeal.venue);
                    activeVenuesCopy.sort((a, b) => (a.name < b.name ? -1 : 1));
                }
                patchState({ assignedDeal, activeVenues: activeVenuesCopy });
            }
        ));
    }
}
import { Injectable } from "@angular/core";
import { CanLoad, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from '@core/auth/model/auth.service';
import { OutOutConfigInst } from "src/app/config/app.config";
import { Store } from "@ngxs/store";
import { AuthorizationState } from "../state/authorization.state";
import { map } from "rxjs/operators";
import { SelectSnapshot } from "@ngxs-labs/select-snapshot";

@Injectable({
    providedIn: "root"
})
export class VenueAdminGuard implements CanActivate, CanLoad {

    constructor(
        private _store: Store) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        return this._handleProtection();
    }


    canLoad(): Observable<boolean> | Promise<boolean> | boolean {
        return this._handleProtection()
    }



    private _handleProtection(): boolean {
     const isVenueAdmin =  this._store.selectSnapshot(AuthorizationState.isVenueAdmin)
     if (isVenueAdmin) return true;
     return false;
    }
}

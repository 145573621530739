import { AfterViewInit, Component } from "@angular/core";
import { SpinnerService } from "@core/modules/spinner/spinner.service";
import { Subject, Observable } from "rxjs";
import { Select } from '@ngxs/store';
import { SpinnerState } from '../state/spinner.state';
import { animate, style, transition, trigger } from "@angular/animations";
@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
  animations:[
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity:1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({opacity:0}))
      ])
    ]),
  ]
})
export class SpinnerComponent {
  @Select(SpinnerState.enabled) isEnabled$!: Observable<boolean>;
  @Select(SpinnerState.activated) isActivated$!: Observable<boolean>;
  color = "primary";
  mode = "indeterminate";
  value = 50;

}

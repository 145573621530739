import { AuthorizationState } from '../modules/authorization/state/authorization.state';
import { SystemRoles } from '../modules/authorization/model/authorization.config';
import { UserState } from '../modules/user/state/user.state';
import { SelectSnapshot, ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { OutOutConfigInst } from 'src/app/config/app.config';
import { StorageService } from '@core/services/storage/storage.service';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './model/auth.service';
import { SetReturnUrl } from './state/auth.actions';
import { enumToArray } from '@shared/helpers/enum-to-array.helper';

@Injectable({
  providedIn: 'root',
})
export class authorizedRoleGuard implements CanActivate, CanLoad {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _storage: StorageService,
    private _location: Location
  ) {}

  @SelectSnapshot(AuthorizationState.isAuthorizedUser)
  public isAuthorizedUser: boolean;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._routeProtectionChecker();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._routeProtectionChecker();
  }

  private _routeProtectionChecker() {
    if (!this.isAuthorizedUser) {
      this._router.navigate([OutOutConfigInst.ROUTES_CONFIG.landingPage]);
      return false;
    }
    return true;
  }
}

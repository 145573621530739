import { buildQueryString } from '@shared/helpers/build-query-string.helper';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr'
import { SnackBarsService } from '@shared/modules/snackbars/snackbars.service';
import * as NOTIFICATION_MODELS from './notifications.model'
import { ApiResponse, PaginationModel } from '@core/http/apis.model';
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { Observable, throwError } from 'rxjs';
import { HttpService } from '@core/http/http/http.service';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { NewNotification } from '../state/notifications.actions';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  private _END_POINT_BASE = 'AdminProfile';
  private _NOTIFICATION_END_POINT_BASE = environment.notificationApiUrl
  private _connection: signalR.HubConnection = null;
  private _connectionId: string;
  constructor(
    private _store: Store,
    private _snackbar: SnackBarsService,
    private _http: HttpService) {
    this.buildConnection();
  }

  public buildConnection(): void {
    this._connection = new signalR.HubConnectionBuilder().withUrl(`${this._NOTIFICATION_END_POINT_BASE}/NotificationHub`,
      {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      }).configureLogging(signalR.LogLevel.None).build();

    this._receiveNotifications();
  }

  public startConnection(): void {
    const userId = JSON.parse(localStorage.out_out_user)?.user?.id;

    this._connection.start().then()
      .then(() => this._getConnectionId())
      .then(() => this._connection.invoke('AddUserToGroup', this._connectionId, userId)).catch((err)=>{
        this._connection.stop();
      })
  }

  private _receiveNotifications(): void {
    this._connection.on("ReceiveNotification", (notification) => {
      this._store.dispatch(new NewNotification(notification)).subscribe(() => {
        this._snackbar.openSuccessSnackbar({
          message: `${notification.body}`,
          duration: 5,
          panelClasses: ['bg-dark']
        })
      })
    });
  }

  private _getConnectionId(): Promise<any> {
    return this._connection.invoke('GetConnectionId').then(
      (data) => {
        this._connectionId = data;
      }, err => {
        throwError(`Couldn't got connection id,  ${err}`)
      });
  }

  public closeConnection(): void {
    this._connection.stop();
  }

  public GetMyNotificationsForAdmin(pagination: PaginationConfigModel): Observable<PaginationModel<NOTIFICATION_MODELS.NotificationModel>> {
    return this._http.fetch(`${this._END_POINT_BASE}/GetMyNotificationsForAdmin${buildQueryString(pagination)}`).pipe(
      map(
        (res: ApiResponse<PaginationModel<NOTIFICATION_MODELS.NotificationModel>>) => res.result
      )
    )
  }

  public markNotificationsAsRead(notificationIds: string[]) :Observable<boolean>{
    return this._http.post(`${this._END_POINT_BASE}/MarkNotificationsAsRead`,{notificationIds}).pipe(
      map(
        (res: ApiResponse<boolean>)=> res.result
      )
    )
  }

  public markAllNotificationsAsRead() :Observable<boolean> {
    return this._http.fetch(`${this._END_POINT_BASE}/MarkAllNotificationsAsRead`).pipe(
      map(
        (res: ApiResponse<boolean>) => res.result
      )
    )
  }

}

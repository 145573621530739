import { Injectable } from '@angular/core';
import { ApiResponse, PaginationModel } from '@core/http/apis.model';
import { HttpService } from '@core/http/http/http.service';
import * as SEARCH_FILTER_MODELS from '@modules/search-and-filter-results/models/search-and-filter-results.model'
import { buildQueryString } from '@shared/helpers/build-query-string.helper';
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchAndFilterResultsService {

  constructor(
    private _http: HttpService
  ) { }

  private _SEARCH_FILTERS_ENDPOINT_BASE = 'HomeScreen';
  


  public venuesSearchFilter(pagination: PaginationConfigModel, filtration: SEARCH_FILTER_MODELS.FiltrationModel): Observable<PaginationModel<SEARCH_FILTER_MODELS.VenueSearchFilterResultModel>> {
    return this._http.post(`${this._SEARCH_FILTERS_ENDPOINT_BASE}/DashboardVenuesSearchFilter${buildQueryString(pagination)}`, filtration).pipe(
      map(
        (res: ApiResponse<PaginationModel<SEARCH_FILTER_MODELS.VenueSearchFilterResultModel>>) => res.result
      )
    )
  }



  public eventsSearchFilter(pagination: PaginationConfigModel, filtration: SEARCH_FILTER_MODELS.FiltrationModel): Observable<PaginationModel<SEARCH_FILTER_MODELS.EventSearchFilterResultModel>> {
    return this._http.post(`${this._SEARCH_FILTERS_ENDPOINT_BASE}/DashboardEventsSearchFilter${buildQueryString(pagination)}`, filtration).pipe(
      map(
        (res: ApiResponse<PaginationModel<SEARCH_FILTER_MODELS.EventSearchFilterResultModel>>) => res.result
      )
    )
  }

  public offersSearchFilter(pagination: PaginationConfigModel, filtration: SEARCH_FILTER_MODELS.FiltrationModel): Observable<PaginationModel<SEARCH_FILTER_MODELS.OfferSearchFilterResultModel>> {
    return this._http.post(`${this._SEARCH_FILTERS_ENDPOINT_BASE}/DashboardOffersSearchFilter${buildQueryString(pagination)}`, filtration).pipe(
      map(
        (res: ApiResponse<PaginationModel<SEARCH_FILTER_MODELS.OfferSearchFilterResultModel>>) => res.result
      )
    )
  }




}

import { SystemRoles } from '@core/modules/authorization/model/authorization.config';
import { State, NgxsOnInit, StateContext, Selector, Action } from '@ngxs/store';
import { PermissionsListModel, TicketDetailsModel } from '../model/authorization.model';
import { AuthorizationService } from '../model/authorization.service';
import { SystemPermissions } from '../authorization.rules';
import { InstallPermissions, LoadSystemRoles, SetGrantedRoles, SetTicketDetails } from './authorization.actions';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { enumToArray } from '@shared/helpers/enum-to-array.helper';

export class AuthorizationStateModel {
  public permissions: PermissionsListModel;
  public systemRoles: string[];
  public grantedRoles: string[];
  public ticketDetails: TicketDetailsModel;


  constructor() {
    this.permissions = {};
    this.systemRoles = [];
    this.grantedRoles = [];
    this.ticketDetails = null;
  }
}

@Injectable()
@State<AuthorizationStateModel>({
  name: 'authorization',
  defaults: new AuthorizationStateModel
})
export class AuthorizationState implements NgxsOnInit {
  constructor(
    private _authorizationService: AuthorizationService
  ) { }

  // Installing dummy permissions list for avoiding 'Accessing from undefined exception'
  ngxsOnInit(ctx: StateContext<AuthorizationStateModel>) {
    ctx.dispatch(new InstallPermissions(ctx.getState().grantedRoles));
  }




  @Selector()
  static permissions(state: AuthorizationStateModel): PermissionsListModel {
    return state.permissions;
  }

  @Selector() static systemRoles(state: AuthorizationStateModel): string[] {
    return state.systemRoles;
  }

  @Selector() static grantedRoles(state: AuthorizationStateModel): string[] {
    return state.grantedRoles;
  }

  @Selector() static isSuperAdmin(state: AuthorizationStateModel): boolean {
    return state.grantedRoles.includes(SystemRoles.SuperAdmin)
  }

  @Selector() static isVenueAdmin(state: AuthorizationStateModel): boolean {
    return state.grantedRoles.includes(SystemRoles.venueAdmin)
  }
  @Selector() static isEventAdmin(state: AuthorizationStateModel): boolean {
    return state.grantedRoles.includes(SystemRoles.EventAdmin)
  }
  
  @Selector() static isTicketAdmin(state: AuthorizationStateModel): boolean {
    return state.grantedRoles.includes(SystemRoles.TicketAdmin)
  }

  @Selector() static isAuthorizedUser(state: AuthorizationStateModel): boolean {

    return state.grantedRoles.some((val) => enumToArray(SystemRoles).indexOf(val) !== -1)
  }

  @Selector() static ticketDetails(state: AuthorizationStateModel): TicketDetailsModel { return state.ticketDetails }


  /**
   * @description Set the corresponding permissions based on the granted roles of current logged in user
   */
  @Action(InstallPermissions)
  public installPermissions(ctx: StateContext<AuthorizationStateModel>, { roles }: InstallPermissions) {
    const permissions = this._authorizationService.setUserPermissions(SystemPermissions, roles);
    ctx.patchState({
      permissions
    });
  }

  /**
   * @description Load all available System roles to be used in the User-Role-Form
   */
  @Action(LoadSystemRoles)
  public loadSystemRoles({ patchState }: StateContext<AuthorizationStateModel>) {
    return this._authorizationService.loadSystemRoles().pipe(
      tap((systemRoles: string[]) => patchState({ systemRoles }))
    )
  }

  /**
   * @description Set the granted roles of the current logged in user to be used into the needed guards
   */
  @Action(SetGrantedRoles)
  public setGrantedRoles({ patchState, dispatch }: StateContext<AuthorizationStateModel>, { roles }: SetGrantedRoles) {
    patchState({ grantedRoles: [...roles, SystemRoles.Master] })
    // Temporary fix for the wired behavior of firing the install permissions before set Granted roles
    setTimeout(() => {
      dispatch(new InstallPermissions(roles))
    }, 500);
  }

  @Action(SetTicketDetails)
  public SetTicketDetails({patchState}: StateContext<AuthorizationStateModel>, {ticketDetails}: SetTicketDetails){
    patchState({ticketDetails})
  }
}

import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { concatMap, first, tap } from 'rxjs/operators';
import { OutOutConfigInst } from 'src/app/config/app.config';

@Injectable({
  providedIn: 'root'
})


export class SyncHelperGuard implements CanActivate {
  public constructor(public injector: Injector, private _router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return from(route.data.syncGuards).pipe(concatMap((value) => {
      const guard = this.injector.get(value);
      console.log('syncHelperGuard', guard)
      const result = guard.canActivate(route, state);
      console.log('syncHelperGuard', result)
      if (result instanceof Observable) {
        return result;
      } else if (result instanceof Promise) {
        return from(result);
      } else {
        return of(result);
      }
    }), first((x) => x === true || x instanceof UrlTree, false), tap((val) => {
      if (val) {
        return true
      } else {
        this._router.navigate([OutOutConfigInst.ROUTES_CONFIG.forbidden])
        return false
      };
    }))

  }
}

import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { NotificationModel } from '../models/notifications.model';

export class StartConnection{
        static readonly type = '[NOTIFICATIONS] Start Connection'

}

export class GetAllNotificationsPage {
        static readonly type = '[NOTIFICATIONS] Get All Notifications Page'
}

export class PaginateAllNotificationsPage {
        static readonly type = '[NOTIFICATIONS] Paginate All Notifications Page'
        constructor(public pagination: PaginationConfigModel) { }
}

export class NewNotification {
        static readonly type = '[NOTIFICATIONS] New Notification'
        constructor(public notification: NotificationModel) { }
}
export class GetNavBarNotifications {
        static readonly type = '[NOTIFICATIONS] Get Navbar Notifications'
}

export class PaginateNavBarNotificationsPage {
        static readonly type = '[NOTIFICATIONS] Paginate NavBar Notifications'
        constructor(public pagination: PaginationConfigModel) { }
}

export class MarkAllNotificationsAsRead{
        static readonly type = '[NOTIFICATIONS] mark All Notifications As Read'
}

export class MarkNotificationsAsRead{
        static readonly type = '[NOTIFICATIONS] Mark Notifications As Read';
        constructor(public notificationIds: string[]) {}
}

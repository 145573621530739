<app-side-nav>
  <ng-container>
    <section class="min-h-screen">
      <div class="py-3 pr-10 bg-white userInfo">
        <app-user-nav class="relative"></app-user-nav>
        <!-- <app-theme-control></app-theme-control> -->
      </div>
      <router-outlet></router-outlet>
    </section>
  </ng-container>
</app-side-nav>

import { ForgetPassword } from './../../state/auth.actions';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { validationErrorMessages } from '@shared/modules/validation/config/validation-messages';

@Component({
  selector: 'app-outout-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']

})
export class ForgetPasswordComponent {

  forgetPasswordForm: FormGroup;
  errorMessage = validationErrorMessages;

  constructor(private _store: Store) {
    this.forgetPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(64)]),
    })
  }

  public sendEmail() {
    this._store.dispatch(new ForgetPassword(this.forgetPasswordForm.value.email)).subscribe(res=> {this.forgetPasswordForm.reset()})
  }
}

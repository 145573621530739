import { UserNavComponent } from './components/user-nav/user-nav.component';
import { AuthModule } from './../core/auth/auth.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizedLayoutComponent } from './pages/authorized-layout/authorized-layout.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { GuestLayoutComponent } from './pages/guest-layout/guest-layout.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { MaterialModule } from '@shared/material.module';
import { ThemeControlComponent } from './components/theme-control/theme-control.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { SharedModule } from '@shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { SearchFilterResultsState } from '@modules/search-and-filter-results/state/search-and-filter-results.state';
import { FilterComponent } from './components/filter/filter.component';
import { FilterDataState } from '@layouts/state/filter-data.state';
import { EventsState } from '@modules/events/state/events.state';
import { DealsState } from '@modules/deals/state/deals.state';
import { VenuesState } from '@modules/venues/state/venues.state';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationState } from '../feature-modules/notifications/state/notifications.state';

@NgModule({
  declarations: [
    AuthorizedLayoutComponent,
    GuestLayoutComponent,
    SideNavComponent,
    NavItemComponent,
    ThemeControlComponent,
    UserNavComponent,
    FilterComponent,
    NotificationsComponent
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    RouterModule,
    MaterialModule,
    ClickOutsideModule,
    SharedModule,
    AuthModule,
    NgxsModule.forFeature([SearchFilterResultsState, FilterDataState,EventsState,DealsState,VenuesState, NotificationState])
  ],
})
export class LayoutModule { }

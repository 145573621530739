<outout-auth-layout
name="Forget Password"
wrapperClassList="w-full md:w-2/3 xl:w-1/2"
>
  <form [formGroup]="forgetPasswordForm" (ngSubmit)="sendEmail()" class="w-full">
    <div class="form-group">
      <label for="email" class="block pb-4">E-mail</label>

      <mat-form-field appearance="outline" class="w-full">
        <input matInput="matInput" type="email" placeholder="Your Email" id="email" formControlName="email">

        <mat-icon matPrefix="matPrefix">email</mat-icon>
      </mat-form-field>

      <mat-error class="pl-5">
        <app-validation-error [customValidatorsErrorMessages]="{'email': 'This format is invalid'}" [control]="forgetPasswordForm.get('email')" businessName="Email">
        </app-validation-error>
      </mat-error>
    </div>

    <div class="mt-6">
      <button mat-flat-button class="px-12 py-3 font-bold rounded-full action-btn" [disabled]="forgetPasswordForm.invalid">Next</button>
    </div>
  </form>
</outout-auth-layout>

<outout-auth-layout name="Reset Password" wrapperClassList="w-full md:w-2/3 xl:w-1/2">


  <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" class="w-full">


    <div class="mt-6 form-group">


      <label for="password" class="block ">New Password <span class="text-red-700">*</span></label>

      <mat-form-field appearance="outline" class="w-full">

        <input matInput type="password" placeholder="New Password" id="password" formControlName="password"
          [type]="hide ? 'password' : 'text'" />

        <mat-icon matPrefix>vpn_key</mat-icon>

        <button mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">

          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>

        </button>


      </mat-form-field>

      <mat-error class="pl-5">
        <app-validation-error [customValidatorsErrorMessages]="{'pattern': 'Password must contain one capital letter, one small letter, one number and one special symbol'}" [control]="resetPasswordForm.get('password')" businessName="New Password">
        </app-validation-error>
      </mat-error>


    </div>





    <div class="mt-6 form-group">


      <label for="confirmPassword" class="block ">Confirm Password <span class="text-red-700">*</span></label>


      <mat-form-field appearance="outline" class="w-full">


        <input matInput type="password" placeholder="Confirm Password" id="confirmPassword"
          [type]="confirm_hide ? 'password' : 'text'" formControlName="confirmPassword" />


        <mat-icon matPrefix>vpn_key</mat-icon>


        <button mat-icon-button type="button" matSuffix (click)="confirm_hide = !confirm_hide"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="confirm_hide">


          <mat-icon>{{
            confirm_hide ? "visibility_off" : "visibility"
            }}</mat-icon>


        </button>


      </mat-form-field>


      <mat-error class="pl-5">
        <app-validation-error [control]="resetPasswordForm.get('confirmPassword')" businessName="Confirm password">
        </app-validation-error>
      </mat-error>
    </div>


    <div class="mt-6">
      <button mat-flat-button class="px-12 py-3 font-bold rounded-full action-btn" [disabled]="resetPasswordForm.invalid">
        Reset Password
      </button>


    </div>
  </form>
</outout-auth-layout>

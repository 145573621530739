import { M } from "@angular/cdk/keycodes";
import { SystemRoles } from "@core/modules/authorization/model/authorization.config";
import { PermissionModel } from "@core/modules/authorization/model/authorization.model";

export const DASHBOARD_PERMISSIONS: PermissionModel[] = [
    {
        name: 'VIEW_NEWEST_VENUES',
        roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin]
    },
    {
        name:'VIEW_NEWEST_DEALS',
        roles:[SystemRoles.SuperAdmin,SystemRoles.venueAdmin]
    },
    {
        name:'VIEW_UPCOMING_DEALS',
        roles:[SystemRoles.SuperAdmin,SystemRoles.venueAdmin]
    }
]
import { SystemRoles } from "@core/modules/authorization/model/authorization.config";
import { PermissionModel } from "@core/modules/authorization/model/authorization.model";

export const EVENT_PERMISSIONS: PermissionModel[] = [
    {
        name: 'EVENTS_WAITING_FOR_APPROVAL_FOR_ADMIN',
        roles: [SystemRoles.SuperAdmin]
    },
    {
        name:'EVENTS_WAITING_FOR_APPROVAL_FOR_VENUE_AND_EVENT_ADMINS',
        roles:[SystemRoles.venueAdmin,SystemRoles.EventAdmin]
    },
    {
        name:'SET_AS_FEATURED',
        roles: [SystemRoles.SuperAdmin]
    },
    {
        name:'APPROVE_CANCEL_EVENTS_WAITING_FOR_APPROVAL',
        roles: [SystemRoles.SuperAdmin]
    },
    {
        name:'CANCEL_REQUEST_FOR_VENUE_AND_EVENT_ADMINS',
        roles:[SystemRoles.venueAdmin,SystemRoles.EventAdmin]
    },
]
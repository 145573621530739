export enum RecoursesServerConfig {
    FAKE_APIs_URL = 'http://localhost:3000/',
    // STAGING_APIs_URL = 'https://192.168.54.200:20231/api/',
    // PRODUCTION_APIs_URL = 'https://192.168.54.200:20231/api/',
    // NOTIFICATION_API_URL = 'https://192.168.54.200:20231/',
    // DEVELOPMENT_APIs_URL = 'https://192.168.54.200:20231/api/',
    DEVELOPMENT_APIs_URL = 'https://api.outout.com/api/',
    STAGING_APIs_URL = 'https://api.outout.com/api/',
    PRODUCTION_APIs_URL = 'https://api.outout.com/api/',
    UAT_APIs_URL = 'https://34.225.245.26/api/',
    NOTIFICATION_API_URL = 'https://api.outout.com'
}

import { StorageService } from '@core/services/storage/storage.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
    constructor(
        private _store: Store,
        private _storage:StorageService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            headers: request.headers
            .set('authorization', `Bearer ${this._storage.get('token')}`)
        });
        return next.handle(request);
    }
}

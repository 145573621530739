import { SystemRoles } from "@core/modules/authorization/model/authorization.config";
import { PermissionModel } from "@core/modules/authorization/model/authorization.model";

export const CUSTOMERS_PERMISSIONS: PermissionModel[] = [
        {
                name: 'VIEW_AVAILED_LOYALTIES',
                roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin]
        },
        {
                name: 'VIEW_AVAILED_DEALS',
                roles: [SystemRoles.SuperAdmin, SystemRoles.venueAdmin]
        },

]
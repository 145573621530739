import { NgModule } from '@angular/core';
import { AccessControlDirective } from './access-control/access-control.directive';


@NgModule({
    declarations: [
        AccessControlDirective
    ],
    imports: [
    ],
    exports: [
        AccessControlDirective
    ]
})
export class DirectivesModule { }

import { PaginationConfigModel } from "@shared/modules/pagination/model/pagination.model";
import * as SEARCH_FILTER_MODELS from '@modules/search-and-filter-results/models/search-and-filter-results.model'


export class GetVenuesSearchFilterResults {
    static readonly type = '[SEARCH_AND_FILTER_RESULTS] Get Venues Search Filter Results'
}

export class PaginateVenues {
    static readonly type = '[SEARCH_AND_FILTER_RESULTS] Paginate Venues'
    constructor(public venuesPagination: PaginationConfigModel) { }
}

export class GetEventsSearchFilterResults {
    static readonly type = '[SEARCH_AND_FILTER_RESULTS] Get Events Search Filter Results'
}

export class PaginateEvents {
    static readonly type = '[SEARCH_AND_FILTER_RESULTS] Paginate Events'
    constructor(public eventsPagination: PaginationConfigModel) { }
}

export class GetOffersSearchFilterResults {
    static readonly type = '[SEARCH_AND_FILTER_RESULTS] Get Offers Search Filter Results'
}

export class PaginateOffers {
    static readonly type = '[SEARCH_AND_FILTER_RESULTS] Paginate Offers'
    constructor(public offersPagination: PaginationConfigModel) { }
}


export class Filter {
    static readonly type = '[SEARCH_AND_FILTER_RESULTS] Filter'
    constructor(public filtration: SEARCH_FILTER_MODELS.FiltrationModel) { }
}

export class Search {
    static readonly type = '[SEARCH_AND_FILTER_RESULTS] Search';
    constructor(public searchQuery: string) { }
}

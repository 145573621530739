import { EventEmitter, Injectable } from '@angular/core';
import { ApiResponse, PaginationModel } from '@core/http/apis.model';
import { HttpService } from '@core/http/http/http.service';
import * as EVENTS_MODELS from '@modules/events/models/events.model';
import { buildQueryString } from '@shared/helpers/build-query-string.helper';
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private _http: HttpService) {}

  eventWizardCancelButton = new EventEmitter();

  private _ENDPOINT_BASE = 'Event';
  private _EVENT_REQUEST_ENDPOINT_BASE = 'EventRequest';
  private _EVENT_BOOKING_END_POINT = 'EventBooking';

  public getEventsPage(
    pagination: PaginationConfigModel,
    body: EVENTS_MODELS.EventsRequestBodyModel
  ): Observable<PaginationModel<EVENTS_MODELS.EventModel>> {
    return this._http
      .post(
        `${this._ENDPOINT_BASE}/GetEventsPage${buildQueryString(pagination)}`,
        body
      )
      .pipe(
        map(
          (res: ApiResponse<PaginationModel<EVENTS_MODELS.EventModel>>) =>
            res.result
        )
      );
  }

  public deleteEvent(id: string): Observable<boolean> {
    return this._http
      .post(`${this._ENDPOINT_BASE}/DeleteEvent${buildQueryString({ id })}`)
      .pipe(map((res: ApiResponse<boolean>) => res.result));
  }

  public getActiveCategories(
    typeFor: number
  ): Observable<EVENTS_MODELS.ActiveCategory[]> {
    return this._http
      .fetch(`Category/GetActiveCategories${buildQueryString({ typeFor })}`)
      .pipe(
        map((res: ApiResponse<EVENTS_MODELS.ActiveCategory[]>) => res.result)
      );
  }

  public getEventDetails(
    id: string
  ): Observable<EVENTS_MODELS.EventDetailsModel> {
    return this._http
      .fetch(
        `${this._ENDPOINT_BASE}/GetEventDetailsForAdmin${buildQueryString({
          id,
        })}`
      )
      .pipe(
        map((res: ApiResponse<EVENTS_MODELS.EventDetailsModel>) => res.result)
      );
  }

  public getActiveCities(): Observable<EVENTS_MODELS.ActiveCity[]> {
    return this._http
      .fetch('City/GetActiveCities')
      .pipe(map((res: ApiResponse<EVENTS_MODELS.ActiveCity[]>) => res.result));
  }

  public getActiveVenues(): Observable<EVENTS_MODELS.ActiveVenueModel[]> {
    return this._http
      .post('Venue/GetActiveVenues')
      .pipe(
        map((res: ApiResponse<EVENTS_MODELS.ActiveVenueModel[]>) => res.result)
      );
  }

  public createEvent(event: FormData): Observable<boolean> {
    return this._http
      .post(`${this._ENDPOINT_BASE}/CreateEvent`, event)
      .pipe(map((res: ApiResponse<boolean>) => res.result));
  }

  public updateIsFeaturedForEvent(
    id: string,
    isFeatured: boolean
  ): Observable<boolean> {
    return this._http
      .post(
        `${this._ENDPOINT_BASE}/UpdateIsFeatured${buildQueryString({
          id,
          isFeatured,
        })}`
      )
      .pipe(map((res: ApiResponse<boolean>) => res.result));
  }

  public updateEventCode(id: string, code: string): Observable<boolean> {
    return this._http
      .post(
        `${this._ENDPOINT_BASE}/UpdateEventCode${buildQueryString({
          id,
          code,
        })}`
      )
      .pipe(map((res: ApiResponse<boolean>) => res.result));
  }

  public updateEvent(id: string, event: FormData): Observable<boolean> {
    return this._http
      .post(
        `${this._ENDPOINT_BASE}/UpdateEvent${buildQueryString({ id })}`,
        event
      )
      .pipe(map((res: ApiResponse<boolean>) => res.result));
  }

  public getCustomerOrdersForEvent(
    pagination: PaginationConfigModel,
    id: string,
    body: EVENTS_MODELS.CustomerOrderForEventRequestBodyModel
  ): Observable<PaginationModel<EVENTS_MODELS.CustomerOrderForEventModel>> {
    return this._http
      .post(
        `EventBooking/GetCustomersOrdersForEvent${buildQueryString({
          ...pagination,
          id,
        })}`,
        body
      )
      .pipe(
        map(
          (
            res: ApiResponse<
              PaginationModel<EVENTS_MODELS.CustomerOrderForEventModel>
            >
          ) => res.result
        )
      );
  }

  public getEventsAwaitingApproval(
    pagination: PaginationConfigModel,
    body: EVENTS_MODELS.EventsRequestBodyModel
  ): Observable<PaginationModel<EVENTS_MODELS.WaitingEventApprovalModel>> {
    return this._http
      .post(
        `${
          this._EVENT_REQUEST_ENDPOINT_BASE
        }/GetEventsAwaitingApproval${buildQueryString({ ...pagination })}`,
        body
      )
      .pipe(
        map(
          (
            res: ApiResponse<
              PaginationModel<EVENTS_MODELS.WaitingEventApprovalModel>
            >
          ) => res.result
        )
      );
  }
  public approveWaitingEvent(id: string): Observable<boolean> {
    return this._http
      .fetch(`${this._ENDPOINT_BASE}/ApproveEvent${buildQueryString({ id })}`)
      .pipe(map((res: ApiResponse<boolean>) => res.result));
  }
  public rejectWaitingEvent(id: string): Observable<boolean> {
    return this._http
      .fetch(`${this._ENDPOINT_BASE}/RejectEvent${buildQueryString({ id })}`)
      .pipe(map((res: ApiResponse<boolean>) => res.result));
  }
  public getEventAwaitingApprovalDetails(
    requestId: string
  ): Observable<EVENTS_MODELS.WaitingEventApprovalModel> {
    return this._http
      .fetch(
        `${
          this._EVENT_REQUEST_ENDPOINT_BASE
        }/GetEventAwaitingApprovalDetailsForAdmin${buildQueryString({
          requestId,
        })}`
      )
      .pipe(
        map(
          (res: ApiResponse<EVENTS_MODELS.WaitingEventApprovalModel>) =>
            res.result
        )
      );
  }
  public GetMyEventsAwaitingApproval(pagination: PaginationConfigModel,body: EVENTS_MODELS.EventsRequestBodyModel): Observable<PaginationModel<EVENTS_MODELS.WaitingEventApprovalModel>> {
    return this._http
      .post(
        `${
          this._EVENT_REQUEST_ENDPOINT_BASE
        }/GetMyEventsAwaitingApproval${buildQueryString(pagination)}`,
        body
      )
      .pipe(
        map(
          (
            res: ApiResponse<
              PaginationModel<EVENTS_MODELS.WaitingEventApprovalModel>
            >
          ) =>  res.result
        )
      );

  }

  public rejectEventBooking(eventBookingId : string): Observable<boolean> {
    return this._http
      .post(
        `${this._EVENT_BOOKING_END_POINT}/RejectBooking${buildQueryString({
          eventBookingId,
        })}`
      )
      .pipe(map((res: ApiResponse<boolean>) => res.result));
  }


  public deleteWaitingApprovalEventRequest(
    requestId: string
  ): Observable<boolean> {
    return this._http
      .post(
        `${
          this._EVENT_REQUEST_ENDPOINT_BASE
        }/DeleteEventRequest${buildQueryString({ requestId })}`
      )
      .pipe(map((res: ApiResponse<boolean>) => res.result));
  }
}

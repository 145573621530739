import { validationPatterns } from '@shared/modules/validation/config/validation-patterns';
import { ValidationService } from './../../../../shared/modules/validation/validation.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Login, ResetPassword } from '@core/auth/state/auth.actions';
import { Select, Store } from '@ngxs/store';
import { validationErrorMessages } from '@shared/modules/validation/config/validation-messages';
import { AuthState } from '@core/auth/state/auth.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-outout-reset-password',
  templateUrl: './reset-password.component.html',
  styles: [``]
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private _store: Store,
    private _fb: FormBuilder,
    private _validationService:ValidationService
    ) {
    this._initForm();
  }

  resetPasswordForm: FormGroup;
  errorMessage = validationErrorMessages;
  hide = true;
  confirm_hide = true;
  hashedOTP: string;
  email: string;

  @Select(AuthState.hashedOTP) public hashedOTP$: Observable<string>;
  @Select(AuthState.email) public email$: Observable<string>;

  private _initForm(){
    this.resetPasswordForm = this._fb.group({
      password:['', [Validators.required, Validators.minLength(8), Validators.maxLength(25), Validators.pattern(validationPatterns.password)]],
      confirmPassword:['', [Validators.required]]
    },
    {
      validators: this._validationService.passwordMatchValidator('password', 'confirmPassword')
    })
  }



  ngOnInit(): void {
    this.email$.subscribe(data => {
      this.email = data;
    })
    this.hashedOTP$.subscribe(hashed => {
      this.hashedOTP = hashed;
    })
  }
  public resetPassword() {
    this._store.dispatch(new ResetPassword(this.email, this.hashedOTP, this.resetPasswordForm.value.confirmPassword));
  }
}

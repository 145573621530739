import { Observable } from 'rxjs';
import { ForgetPassword, VerifyResetPassword } from './../../state/auth.actions';
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AuthState } from '@core/auth/state/auth.state';
import { CountdownComponent } from 'ngx-countdown'
import { animate, style, transition, trigger } from '@angular/animations';
import { delay } from 'rxjs/operators';
import { CodeInputComponent } from 'angular-code-input';
@Component({
  selector: 'app-outout-code-verification',
  templateUrl: './code-verification.component.html',
  styleUrls: ['./code-verification.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity:1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity:0}))
      ])
    ]),
    trigger('fadeInOut2', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate("500ms 550ms ease-in-out", style({opacity:1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity:0}))
      ])
    ]),
  ]
})
export class CodeVerificationComponent implements OnInit {

  constructor(private _router: Router, private _store: Store) { }

  public email: string;
  public countDownConfig: {
    leftTime : 60,
    format: 'm:s'
  }
  public canRequestNewOTP: boolean = false;
  public code: string;
  public codeAvailable: boolean;

  @Select(AuthState.email) public email$: Observable<string>;
  @Select(AuthState.codeAvailablity) public codeAvailablity$: Observable<boolean>;
  @ViewChild('codeInput') codeInput
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  ngOnInit(): void {
    this.email$.subscribe(data => {
      this.email = data;
      if(!this.email) {
        this._router.navigate(['/guest/forget-password']);
      }
    })
    this.codeAvailablity$.subscribe(codeAvailable => {
      this.codeAvailable = codeAvailable
    })
    if(this.countdown) {
    this.countdown.begin();
    }
  }

  onCodeChanged(code: string) {
    if(code.length < 6) {
      this.code = '';
    }
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.code = code;
  }

  countDownFinished($event){
    if($event.status == 3 && this.codeAvailable) this.canRequestNewOTP = true;
  }

  verifyResetPassword() {
    this._store.dispatch(new VerifyResetPassword(this.email, this.code))

  }

  requestNewOTP() {
 this.codeInput.reset();
 this.code = '';
 if(this.countdown) {
    this.countdown.restart();
 }
    this.canRequestNewOTP = false;
    this._store.dispatch(new ForgetPassword(this.email))
  }

}

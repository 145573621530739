<section (clickOutside)="isOpened = false" class="relative">

  <div class="flex items-center justify-between w-full">


    <!--search input field wrapper-->
    <div class="w-1/2 pl-16">
      <app-search-input (searchChange)="onSearchChange($event)"></app-search-input>
    </div>


    <!--icons wrapper "filter, notifications, and user"-->

    <div class="flex items-center justify-end w-1/2">


      <app-outout-notifications></app-outout-notifications>



      <app-outout-filter></app-outout-filter>

      <div class="mx-3 cursor-pointer userNav" (click)="isOpened = !isOpened" [ngClass]="{'p-1': !loggedInUser?.profileImage}">
        <img [src]="loggedInUser?.profileImage || '../../../../../assets/images/user.svg'"
          [alt]="loggedInUser?.fullName + '-photo' " class="rounded-3xl w-9 h-9">
      </div>
    </div>

  </div>

  <div [ngClass]="{'isShown': isOpened}" class="z-50 shadow-md userControl">
    <ul>
      <!-- <li class="p-2 cursor-pointer hover:bg-accent" (click)="isOpened=!isOpened" [routerLink]="[ '/my-profile']">
              My Profile
          </li> -->
      <li class="p-2 cursor-pointer hover:bg-accent" (click)="viewMyProfile()">
        My Profile
      </li>
      <!-- <a > -->
      <li class="p-2 cursor-pointer hover:bg-accent" [routerLink]="['/auth/change-password']"
        routerLinkActive="router-link-active">
        Change My Password
      </li>
      <!-- </a> -->
      <li class="p-2 cursor-pointer hover:bg-accent" (click)="confirmLogout()">
        Log Out
      </li>
    </ul>
  </div>
</section>

export class GetVenuesCategories {
    static readonly type = '[FILTER_DATA] Get Venues Categories'
}

export class GetEventsCategories {
    static readonly type = '[FILTER_DATA] Get Events Categories'
}

export class GetOfferTypes {
    static readonly type = '[FILTER_DATA] Get Offer Types'
}

export class GetAllCities {
    static readonly type = '[FILTER_DATA] Get All Cities'
}
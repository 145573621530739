

<div class="loginWrapper h-full flex flex-col w-3/4 m-auto justify-center">
    <h1 class=""> Whoops! <span>(404 Not Found)</span> </h1>
      <p class="mt-20">{{exceptionMessage}}</p>
  
      <button role="link" (click)="onReturningHome()" mat-button mat-flat-button class="w-1/2 mx-auto px-2 py-3 action-btn rounded-none min-100">
            Go back home..
      </button>
  </div>
  
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCroppingComponent } from './component/image-cropping/image-cropping.component';
import { ImageCropperModule } from 'ngx-image-cropper';


@NgModule({
  declarations: [
    ImageCroppingComponent
  ],
  imports: [
    CommonModule,
    ImageCropperModule
  ],
  exports:[
    ImageCroppingComponent
  ]
})
export class CroppingModule { }

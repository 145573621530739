import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizationState } from '../state/authorization.state';

@Injectable({
  providedIn: 'root'
})
export class EventAdminGuard implements CanActivate, CanLoad {

  constructor(
    private _store: Store) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._handleProtection();
  }



  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._handleProtection();
  }



  private _handleProtection(): boolean {
    const isEventAdmin = this._store.selectSnapshot(AuthorizationState.isEventAdmin)
    if (isEventAdmin) return true;
    return false;
  }
}

// List of roles to be used as the single trusted reference for all available roles cross over the whole system
export enum SystemRoles {
    Master = 'Master',
    SuperAdmin = 'Super Admin',
    venueAdmin = 'Venue Admin',
    EventAdmin = 'Event Admin',
    TicketAdmin = 'Ticket Admin'
}

export const ROLES_key = 'profileRoles';

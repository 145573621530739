<outout-auth-layout
name="Verification code"
wrapperClassList="w-full md:w-2/3 xl:w-1/2"
>
<p class="pb-3">Reset Password mail was sent to your email</p>
<div class="w-full">
  <code-input
    [codeLength]="6"
    (codeChanged)="onCodeChanged($event)"
    (codeCompleted)="onCodeCompleted($event)"
    #codeInput
  >
  </code-input>
  <!-- <div class="pt-4">
    Didn't receive your code ?
  </div> -->
  <div class="h-16 pt-6" >
    <span *ngIf="!canRequestNewOTP && codeAvailable" [@fadeInOut]>
      Didn't receive your code ?
      You can request a new code in
      <countdown #cd [config]="{
        leftTime : 60,
        format: 'm:ss'
      }" (event)="countDownFinished($event)"></countdown>
    </span>
    <span *ngIf="canRequestNewOTP || !codeAvailable" [@fadeInOut2]>
      <a class="cursor-pointer" (click)="requestNewOTP()" >
        Request a new code
      </a>
    </span>
  </div>
  <div class="mt-6">
    <button mat-flat-button class="px-12 py-3 font-bold rounded-full action-btn" [disabled]="!this.code" (click)="verifyResetPassword()">
     Next
    </button>
  </div>



  <!-- <div class="mt-6">
    <button mat-flat-button [disabled]="!canRequestNewOTP" class="float-right font-bold rounded-full action-btn" (click)="requestNewOTP()">
      Request a new code
    </button>
  </div> -->
</div>
</outout-auth-layout>

import { ChangePasswordComponent } from './core/auth/pages/change-password/change-password.component';
import { ResetPasswordComponent } from './core/auth/pages/reset-password/reset-password.component';
import { CodeVerificationComponent } from './core/auth/pages/code-verification/code-verification.component';
import { AuthGuard } from '@core/auth/auth.guard';
import { SplashComponent } from './core/auth/pages/splash/splash.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SomethingWentWrongComponent } from '@core/components/something-went-wrong/something-went-wrong.component';
import { ForbiddenComponent } from '@core/modules/authorization/forbidden/forbidden.component';
import { AuthorizedLayoutComponent } from './layouts/pages/authorized-layout/authorized-layout.component';
import { GuestLayoutComponent } from './layouts/pages/guest-layout/guest-layout.component';
import { authorizedRoleGuard } from '@core/auth/authorizedRole.guard';


const routes: Routes = [

  {
    path: '',
    component: AuthorizedLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'result',
        loadChildren: () =>
          import('@modules/search-and-filter-results/search-and-filter-results.module').then(m => m.SearchAndFilterResultsModule)
      },
      {
        path: 'users',
        loadChildren: () =>
          import('@modules/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'events',
        loadChildren: () =>
          import('@modules/events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'venues',
        loadChildren: () =>
          import('@modules/venues/venues.module').then(m => m.VenuesModule)
      },
      {
        path: 'loyalty',
        loadChildren: () =>
          import('@modules/loyalties/loyalties.module').then(m => m.LoyaltiesModule)
      },
      {
        path: 'deals',
        loadChildren: () =>
          import('@modules/deals/deals.module').then(m => m.DealsModule)
      },
      {
        path: 'bookings',
        loadChildren: () =>
          import('@modules/bookings/bookings.module').then(m => m.BookingsModule)
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('@modules/customers/customers.module').then(m => m.CustomersModule)
      },
      {
        path: 'manage',
        loadChildren: () =>
          import('@modules/manage/manage.module').then(m => m.ManageModule)
      },
      {
        path: 'featured-events',
        loadChildren: () =>
          import('@modules/featured-event/featured-event.module').then(m => m.FeaturedEventModule)
      },
      {
        path: 'reports',
        canLoad: [authorizedRoleGuard],
        loadChildren: () =>
          import('@modules/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'notifications',
        canLoad: [authorizedRoleGuard],
        loadChildren: () =>
          import('@modules/notifications/notifications.module').then(m=> m.NotificationsModule)
      },
      {
        path:'tickets',
        loadChildren: ()=>
        import('@modules/tickets/tickets.module').then(m => m.TicketsModule)
      },
      {
        path: '',
        redirectTo: '/splash',
        pathMatch: 'full',
      }
    ]
  },
  {
    path: 'landing-page',
    loadChildren: () =>
      import('@modules/landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  {
    path: 'auth',
    component: GuestLayoutComponent,
    children: [
      {
        path: 'code-verification',
        component: CodeVerificationComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      }
    ]
  },
  // not authorized routes
  {
    path: 'guest',
    component: GuestLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@core/auth/auth.module').then(m => m.AuthModule)
      },
    ]
  },
  {
    path: 'splash',
    component: SplashComponent,
  },
  // Exception routes
  {
    path: 'unexpected',
    component: GuestLayoutComponent,
    children: [
      {
        path: 'forbidden',
        component: ForbiddenComponent
      }, {
        path: 'not-found',
        component: SomethingWentWrongComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'unexpected/not-found',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

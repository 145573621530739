import { Navigate } from '@ngxs/router-plugin';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import * as VENUES_MODELS from '@modules/venues/models/venues.model';
import * as VENUES_ACTIONS from '@modules/venues/state/venues.actions';
import { VenuesService } from '../models/venues.service';
import { PaginationConfigModel } from '@shared/modules/pagination/model/pagination.model';
import { PaginationModel } from '@core/http/apis.model';

export class VenuesStateModel {
  public activeCategories: VENUES_MODELS.ActiveCategory
  public events: VENUES_MODELS.EventModel
  public activeCities: VENUES_MODELS.ActiveCity[]
  public activeTermsAndConditions: VENUES_MODELS.ActiveTermsAndConditions
  public allVenues: VENUES_MODELS.VenueModel[];
  public searchQuery: string;
  public sortBy: number;
  public pagination: PaginationConfigModel;
  public venue: VENUES_MODELS.VenueModel;
  public venueTermsAndConditions: VENUES_MODELS.ActiveTermsAndConditions;
  public venueDetails: VENUES_MODELS.VenueDetailsModel;

  public offers: VENUES_MODELS.OfferModel[];
  public offersPagination: PaginationConfigModel;

  public upcomingEvents: VENUES_MODELS.UpcomingEventModel[];
  public upcomingEventsPagination: PaginationConfigModel;

  public waitingForApprovalVenues: VENUES_MODELS.WaitingForApprovalVenuesModel[];
  public waitingForApprovalVenuesSearchQuery: string;
  public waitingForApprovalVenuesSortBy: number;
  public waitingForApprovalVenuesPagination: PaginationConfigModel;

  public waitingForApprovalVenuesForVenueAdmin: VENUES_MODELS.WaitingForApprovalVenuesModel[];
  public waitingForApprovalVenuesSearchQueryForVenueAdmin: string;
  public waitingForApprovalVenuesSortByForVenueAdmin: number;
  public waitingForApprovalVenuesPaginationForVenueAdmin: PaginationConfigModel;

  public awaitingVenueTermsAndConditions: VENUES_MODELS.ActiveTermsAndConditions;
  public awaitingVenueDetailsForAdmin: VENUES_MODELS.WaitingForApprovalVenuesModel;

  public awaitingVenueOffers: VENUES_MODELS.OfferModel[];
  public awaitingVenueOffersPagination: PaginationConfigModel;

  public awaitingVenueUpcomingEvents: VENUES_MODELS.UpcomingEventModel[];
  public awaitingVenueUpcomingEventsPagination: PaginationConfigModel;

  constructor() {
    this.activeCategories = null;
    this.events = null;
    this.activeCities = null;
    this.activeTermsAndConditions = null;
    this.venueTermsAndConditions = null;
    this.awaitingVenueTermsAndConditions = null;
    this.allVenues = null;
    this.venue = null;
    this.pagination = {
      pageNumber: 0,
      pageSize: 3,
    };

    this.sortBy = 0;

    this.searchQuery = '';

    this.venueDetails = null;

    this.offers = null;
    this.offersPagination = {
      pageSize: 3,
      pageNumber: 0,
    };

    this.upcomingEvents = null;
    this.upcomingEventsPagination = {
      pageSize: 3,
      pageNumber: 0,
    };

    this.waitingForApprovalVenues = null;
    this.waitingForApprovalVenuesSearchQuery = '';
    this.waitingForApprovalVenuesSortBy = 0;
    this.waitingForApprovalVenuesPagination = {
      pageSize: 3,
      pageNumber: 0,
    };

    this.waitingForApprovalVenuesForVenueAdmin = null;
    this.waitingForApprovalVenuesSearchQueryForVenueAdmin = '';
    this.waitingForApprovalVenuesSortByForVenueAdmin = 0;
    this.waitingForApprovalVenuesPaginationForVenueAdmin = {
      pageSize: 3,
      pageNumber: 0,
    };

    this.awaitingVenueDetailsForAdmin = null;
    this.awaitingVenueOffers = null;
    this.awaitingVenueOffersPagination = {
      pageSize: 3,
      pageNumber: 0,
    };

    this.awaitingVenueUpcomingEvents = null;
    this.awaitingVenueUpcomingEventsPagination = {
      pageSize: 3,
      pageNumber: 0,
    };
  }
}

@Injectable()
@State<VenuesStateModel>({
  name: 'venues',
  defaults: new VenuesStateModel(),
})
export class VenuesState {
  constructor(private _mainService: VenuesService) { }
  /*_____________________________Selectors__________________________________*/

  @Selector() static activeCategories(state: VenuesStateModel): VENUES_MODELS.ActiveCategory { return state.activeCategories }
  @Selector() static events(state: VenuesStateModel): VENUES_MODELS.EventModel { return state.events }
  @Selector() static activeCities(state: VenuesStateModel): VENUES_MODELS.ActiveCity[] { return state.activeCities }
  @Selector() static activeTermsAndConditions(state: VenuesStateModel): VENUES_MODELS.ActiveTermsAndConditions { return state.activeTermsAndConditions }
  @Selector() static allVenues(state: VenuesStateModel): VENUES_MODELS.VenueModel[] { return state.allVenues }
  @Selector() static pagination(state: VenuesStateModel): PaginationConfigModel { return { ...state.pagination } }
  @Selector() static sort(state: VenuesStateModel): number { return state.sortBy }
  @Selector() static searchQuery(state: VenuesStateModel): string { return state.searchQuery }
  @Selector() static venue(state: VenuesStateModel): VENUES_MODELS.VenueModel { return state.venue }
  @Selector() static venueTermsAndConditions(state: VenuesStateModel): VENUES_MODELS.ActiveTermsAndConditions { return state.venueTermsAndConditions }
  @Selector() static venueDetails(state: VenuesStateModel): VENUES_MODELS.VenueDetailsModel { return state.venueDetails }
  @Selector() static offers(state: VenuesStateModel): VENUES_MODELS.OfferModel[] { return state.offers }
  @Selector() static offersPagination(state: VenuesStateModel): PaginationConfigModel { return { ...state.offersPagination } }
  @Selector() static upcomingEvents(state: VenuesStateModel): VENUES_MODELS.UpcomingEventModel[] { return state.upcomingEvents }
  @Selector() static upcomingEventsPagination(state: VenuesStateModel): PaginationConfigModel { return { ...state.upcomingEventsPagination } }

  @Selector() static waitingForApprovalVenues(state: VenuesStateModel): VENUES_MODELS.WaitingForApprovalVenuesModel[] { return state.waitingForApprovalVenues }
  @Selector() static waitingForApprovalVenuesPagination(state: VenuesStateModel): PaginationConfigModel { return { ...state.waitingForApprovalVenuesPagination } }
  @Selector() static waitingForApprovalVenuesSortBy(state: VenuesStateModel): number { return state.waitingForApprovalVenuesSortBy }
  @Selector() static waitingForApprovalVenuesSearchQuery(state: VenuesStateModel): string { return state.waitingForApprovalVenuesSearchQuery }

  @Selector() static waitingForApprovalVenuesForVenueAdmin(state: VenuesStateModel): VENUES_MODELS.WaitingForApprovalVenuesModel[] { return state.waitingForApprovalVenuesForVenueAdmin }
  @Selector() static waitingForApprovalVenuesPaginationForVenueAdmin(state: VenuesStateModel): PaginationConfigModel { return { ...state.waitingForApprovalVenuesPaginationForVenueAdmin } }
  @Selector() static waitingForApprovalVenuesSortByForVenueAdmin(state: VenuesStateModel): number { return state.waitingForApprovalVenuesSortByForVenueAdmin }
  @Selector() static waitingForApprovalVenuesSearchQueryForVenueAdmin(state: VenuesStateModel): string { return state.waitingForApprovalVenuesSearchQueryForVenueAdmin }

  @Selector() static awaitingVenueTermsAndConditions(state: VenuesStateModel): VENUES_MODELS.ActiveTermsAndConditions { return state.awaitingVenueTermsAndConditions }
  @Selector() static awaitingVenueDetailsForAdmin(state: VenuesStateModel): VENUES_MODELS.WaitingForApprovalVenuesModel { return state.awaitingVenueDetailsForAdmin }
  @Selector() static awaitingVenueOffers(state: VenuesStateModel): VENUES_MODELS.OfferModel[] { return state.awaitingVenueOffers }
  @Selector() static awaitingVenueOffersPagination(state: VenuesStateModel): PaginationConfigModel { return { ...state.awaitingVenueOffersPagination } }
  @Selector() static awaitingVenueUpcomingEvents(state: VenuesStateModel): VENUES_MODELS.UpcomingEventModel[] { return state.awaitingVenueUpcomingEvents }
  @Selector() static awaitingVenueUpcomingEventsPagination(state: VenuesStateModel): PaginationConfigModel { return { ...state.awaitingVenueUpcomingEventsPagination } }

  /*_____________________________Reducers___________________________________*/

  @Action(VENUES_ACTIONS.GetActiveCategories)
  public getActiveCategory({ patchState }: StateContext<VenuesStateModel>, { typeFor }: VENUES_ACTIONS.GetActiveCategories) {
    return this._mainService.getActiveCategories(typeFor).pipe(tap(activeCategories => patchState({
      activeCategories
    })))
  }

  @Action(VENUES_ACTIONS.GetAllEvents)
  public getAllEvents({ patchState }: StateContext<VenuesStateModel>,{venueId}:VENUES_ACTIONS.GetAllEvents) {
    return this._mainService.getAllEventsNotAssociatedToVenue(venueId).pipe(
      tap((events) => {
        patchState({ events });
      })
    );
  }

  @Action(VENUES_ACTIONS.GetActiveCities)
  public getActiveCities({ patchState }: StateContext<VenuesStateModel>) {
    return this._mainService.getActiveCities().pipe(
      tap((activeCities) => {
        patchState({ activeCities });
      })
    );
  }

  @Action(VENUES_ACTIONS.GetActiveTermsAndConditions)
  public getActiveTermsAndConditions({
    patchState,
  }: StateContext<VenuesStateModel>) {
    return this._mainService.getActiveTermsAndConditions().pipe(
      tap((activeTermsAndConditions) => {
        patchState({ activeTermsAndConditions });
      })
    );
  }

  @Action(VENUES_ACTIONS.GetAllVenuesPage)
  public getAllVenuesPage({
    getState,
    patchState,
  }: StateContext<VenuesStateModel>) {
    const {
      pagination: { pageNumber, pageSize },
      searchQuery,
      sortBy,
    } = getState();
    return this._mainService
      .getVenuesPage({ pageNumber, pageSize }, { searchQuery, sortBy })
      .pipe(tap(
        ({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<VENUES_MODELS.VenueModel>) => {
          patchState({
            allVenues: records,
            pagination: {
              totalPages,
              recordsTotalCount,
              pageSize,
              pageNumber,
            },
          });
        }
      )
      );
  }

  @Action(VENUES_ACTIONS.PaginateAllVenuesPage)
  public paginateAllVenuesPage(
    { dispatch, patchState, getState }: StateContext<VenuesStateModel>,
    { pagination }: VENUES_ACTIONS.PaginateAllVenuesPage
  ) {
    patchState({
      pagination: {
        ...getState().pagination,
        pageNumber: pagination.pageNumber,
      },
    });
    dispatch(new VENUES_ACTIONS.GetAllVenuesPage());
  }

  @Action(VENUES_ACTIONS.SortAllVenuesPage)
  public sortAllVenuesPage(
    { getState, patchState, dispatch }: StateContext<VenuesStateModel>,
    { sortBy }: VENUES_ACTIONS.SortAllVenuesPage
  ) {
    patchState({
      sortBy,
      pagination: {
        ...getState().pagination,
        pageNumber: 0,
      },
    });
    dispatch(new VENUES_ACTIONS.GetAllVenuesPage());
  }

  @Action(VENUES_ACTIONS.SearchAllVenuesPage)
  public searchAllVenuesPage(
    { getState, patchState, dispatch }: StateContext<VenuesStateModel>,
    { searchQuery }: VENUES_ACTIONS.SearchAllVenuesPage
  ) {
    patchState({
      searchQuery,
      pagination: {
        ...getState().pagination,
        pageNumber: 0,
      },
    });

    dispatch(new VENUES_ACTIONS.GetAllVenuesPage());
  }

  @Action(VENUES_ACTIONS.DeleteVenue)
  public DeleteVenue(
    { dispatch, getState, patchState }: StateContext<VenuesStateModel>,
    { id }: VENUES_ACTIONS.DeleteVenue
  ) {
    return this._mainService
      .deleteVenue(id)
      .pipe(
        tap((isDeleted) => {
          if (isDeleted) {
            const pagination = { ...getState().pagination }
            if (pagination.recordsTotalCount && (pagination.recordsTotalCount - 1) % pagination.pageSize === 0) {
              patchState({
                pagination: {
                  ...getState().pagination,
                  pageNumber: pagination.pageNumber > 0 ? pagination.pageNumber - 1 : pagination.pageNumber
                }
              })
            }
            dispatch(new VENUES_ACTIONS.GetAllVenuesPage())
          } else {
            return
          }
        }
        )
      );
  }

  @Action(VENUES_ACTIONS.CreateVenue)
  public CreateVenue(
    { dispatch }: StateContext<VenuesStateModel>,
    { venue }: VENUES_ACTIONS.CreateVenue
  ) {
    return this._mainService
      .createVenue(venue)
      .pipe(tap((res) => (res ? dispatch(new Navigate(['/venues'])) : '')));
  }

  @Action(VENUES_ACTIONS.GetVenue)
  public getVenue({ patchState, getState }: StateContext<VenuesStateModel>, { id }: VENUES_ACTIONS.GetVenue) {
    return this._mainService.getVenue(id).pipe(tap((venue => {
      const { activeCities } = getState();
      let activeCitiesCopy: VENUES_MODELS.ActiveCity[] = [...activeCities]

      if (venue.location && activeCitiesCopy.findIndex((city) => city.id === venue.location.city.id) === -1) {
        activeCitiesCopy.push(venue.location.city);
        activeCitiesCopy.sort((a, b) => (a.name < b.name ? -1 : 1));
      }
      patchState({ venue, activeCities: activeCitiesCopy });
    }
    )))
  }

  @Action(VENUES_ACTIONS.UpdateVenue)
  public updateVenue({ dispatch }: StateContext<VenuesStateModel>, { id, venue }: VENUES_ACTIONS.UpdateVenue) {
    return this._mainService.updateVenue(id, venue).pipe(tap((res => res ? dispatch(new Navigate([`/venues/view/${id}`])) : '')))
  }

  @Action(VENUES_ACTIONS.getVenueTermsAndConditions)
  public getVenueTermsAndConditions({ patchState }: StateContext<VenuesStateModel>, { id }: VENUES_ACTIONS.getVenueTermsAndConditions) {
    return this._mainService.getVenueTermsAndConditions(id).pipe(tap((venueTermsAndConditions => {
      patchState({
        venueTermsAndConditions
      })
    }
    )));
  }

  @Action(VENUES_ACTIONS.GetVenueDetails)
  public getVenueDetails({ patchState }: StateContext<VenuesStateModel>, { id }: VENUES_ACTIONS.GetVenueDetails) {
    return this._mainService.getVenueDetails(id).pipe(
      tap(
        (venueDetails) => patchState({
          venueDetails
        })
      )
    )
  }

  @Action(VENUES_ACTIONS.GetOffersPage)
  public getOffersPage({ patchState, getState }: StateContext<VenuesStateModel>) {
    const { offersPagination: { pageNumber, pageSize } } = getState();
    const id = getState().venueDetails.id
    return this._mainService.getOffersPageInVenue({ pageNumber, pageSize }, id).pipe(
      tap(
        ({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<VENUES_MODELS.OfferModel>) => patchState({
          offers: records,
          offersPagination: { recordsTotalCount, totalPages, pageNumber, pageSize }
        })
      )
    )
  }

  @Action(VENUES_ACTIONS.PaginateOffersPage)
  public paginateVenuesCreatedByAdmin({ patchState, dispatch, getState }: StateContext<VenuesStateModel>, { offersPagination }: VENUES_ACTIONS.PaginateOffersPage) {
    patchState({
      offersPagination: {
        ...getState().offersPagination,
        pageNumber: offersPagination.pageNumber
      }
    })
    dispatch(new VENUES_ACTIONS.GetOffersPage)
  }

  @Action(VENUES_ACTIONS.GetUpcomingEvents)
  public getUpcomingEvents({ patchState, getState }: StateContext<VenuesStateModel>) {
    const { upcomingEventsPagination: { pageNumber, pageSize } } = getState();
    const id = getState().venueDetails.id
    return this._mainService.getUpcomingEventsPageInVenue({ pageNumber, pageSize }, id).pipe(
      tap(
        ({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<VENUES_MODELS.UpcomingEventModel>) => patchState({
          upcomingEvents: records,
          upcomingEventsPagination: { recordsTotalCount, totalPages, pageNumber, pageSize }
        })
      )
    )
  }

  @Action(VENUES_ACTIONS.PaginateUpcomingEvents)
  public PaginateUpcomingEvents({ patchState, dispatch, getState }: StateContext<VenuesStateModel>, { upcomingEventsPagination }: VENUES_ACTIONS.PaginateUpcomingEvents) {
    patchState({
      upcomingEventsPagination: {
        ...getState().upcomingEventsPagination,
        pageNumber: upcomingEventsPagination.pageNumber
      }
    })
    dispatch(new VENUES_ACTIONS.GetUpcomingEvents)
  }

  @Action(VENUES_ACTIONS.UpdateVenueCode)
  public updateVenueCode(ctx: StateContext<VenuesStateModel>, { id, code }: VENUES_ACTIONS.UpdateVenueCode) {
    return this._mainService.updateVenueCode(id, code).pipe(tap(res => res))
  }

  @Action(VENUES_ACTIONS.UpdateVenueTermsAndConditions)
  public updateVenueTermsAndConditions(ctx: StateContext<VenuesStateModel>, { venueId, selectedTermsAndConditions }: VENUES_ACTIONS.UpdateVenueTermsAndConditions) {
    return this._mainService.updateVenueTermsAndConditions(venueId, selectedTermsAndConditions).pipe(tap(res => res))
  }

  @Action(VENUES_ACTIONS.DeleteGalleryImage)
  public deleteGalleryImageVenue({ dispatch }: StateContext<VenuesStateModel>, { id, images }: VENUES_ACTIONS.DeleteGalleryImage) {
    return this._mainService.deleteGalleryImage(id, images)
  }

  @Action(VENUES_ACTIONS.GetAllWaitingForApprovalVenuesPage)
  public getAllWaitingForApprovalVenuesPage({
    getState,
    patchState,
  }: StateContext<VenuesStateModel>) {
    const {
      waitingForApprovalVenuesPagination: { pageNumber, pageSize },
      waitingForApprovalVenuesSearchQuery,
      waitingForApprovalVenuesSortBy,
    } = getState();
    return this._mainService
      .getAllWaitingForApprovalVenuesPage(
        { pageNumber, pageSize },
        {
          searchQuery: waitingForApprovalVenuesSearchQuery,
          sortBy: waitingForApprovalVenuesSortBy,
        }
      )
      .pipe(tap(
        ({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<VENUES_MODELS.WaitingForApprovalVenuesModel>) => {
          patchState({
            waitingForApprovalVenues: records,
            waitingForApprovalVenuesPagination: {
              totalPages,
              recordsTotalCount,
              pageSize,
              pageNumber,
            },
          });
        }
      )
      );
  }

  @Action(VENUES_ACTIONS.PaginateAllWaitingForApprovalVenuesPage)
  public paginateAllWaitingForApprovalVenuesPage(
    { dispatch, patchState, getState }: StateContext<VenuesStateModel>,
    { pagination }: VENUES_ACTIONS.PaginateAllWaitingForApprovalVenuesPage
  ) {
    patchState({
      waitingForApprovalVenuesPagination: {
        ...getState().waitingForApprovalVenuesPagination,
        pageNumber: pagination.pageNumber,
      },
    });
    dispatch(new VENUES_ACTIONS.GetAllWaitingForApprovalVenuesPage());
  }

  @Action(VENUES_ACTIONS.SortAllWaitingForApprovalVenuesPage)
  public sortAllWaitingForApprovalVenuesPage(
    { getState, patchState, dispatch }: StateContext<VenuesStateModel>,
    { sortBy }: VENUES_ACTIONS.SortAllWaitingForApprovalVenuesPage
  ) {
    patchState({
      waitingForApprovalVenuesSortBy: sortBy,
      waitingForApprovalVenuesPagination: {
        ...getState().waitingForApprovalVenuesPagination,
        pageNumber: 0,
      },
    });
    dispatch(new VENUES_ACTIONS.GetAllWaitingForApprovalVenuesPage());
  }

  @Action(VENUES_ACTIONS.SearchAllWaitingForApprovalVenuesPage)
  public searchAllWaitingForApprovalVenuesPage(
    { getState, patchState, dispatch }: StateContext<VenuesStateModel>,
    { searchQuery }: VENUES_ACTIONS.SearchAllWaitingForApprovalVenuesPage
  ) {
    patchState({
      waitingForApprovalVenuesSearchQuery: searchQuery,
      waitingForApprovalVenuesPagination: {
        ...getState().waitingForApprovalVenuesPagination,
        pageNumber: 0,
      },
    });

    dispatch(new VENUES_ACTIONS.GetAllWaitingForApprovalVenuesPage());
  }

  @Action(VENUES_ACTIONS.GetAllWaitingForApprovalVenuesPageForVenueAdmin)
  public getAllWaitingForApprovalVenuesPageForVenueAdmin({
    getState,
    patchState,
  }: StateContext<VenuesStateModel>) {
    const {
      waitingForApprovalVenuesPaginationForVenueAdmin: { pageNumber, pageSize },
      waitingForApprovalVenuesSearchQueryForVenueAdmin,
      waitingForApprovalVenuesSortByForVenueAdmin,
    } = getState();
    return this._mainService
      .getAllWaitingForApprovalVenuesPageForVenueAdmin(
        { pageNumber, pageSize },
        {
          searchQuery: waitingForApprovalVenuesSearchQueryForVenueAdmin,
          sortBy: waitingForApprovalVenuesSortByForVenueAdmin,
        }
      )
      .pipe(tap(
        ({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<VENUES_MODELS.WaitingForApprovalVenuesModel>) => {
          patchState({
            waitingForApprovalVenuesForVenueAdmin: records,
            waitingForApprovalVenuesPaginationForVenueAdmin: {
              totalPages,
              recordsTotalCount,
              pageSize,
              pageNumber,
            },
          });
        }
      )
      );
  }

  @Action(VENUES_ACTIONS.PaginateAllWaitingForApprovalVenuesPageForVenueAdmin)
  public paginateAllWaitingForApprovalVenuesPageForVenueAdmin(
    { dispatch, patchState, getState }: StateContext<VenuesStateModel>,
    {
      pagination,
    }: VENUES_ACTIONS.PaginateAllWaitingForApprovalVenuesPageForVenueAdmin
  ) {
    patchState({
      waitingForApprovalVenuesPaginationForVenueAdmin: {
        ...getState().waitingForApprovalVenuesPaginationForVenueAdmin,
        pageNumber: pagination.pageNumber,
      },
    });
    dispatch(
      new VENUES_ACTIONS.GetAllWaitingForApprovalVenuesPageForVenueAdmin()
    );
  }

  @Action(VENUES_ACTIONS.SortAllWaitingForApprovalVenuesPageForVenueAdmin)
  public sortAllWaitingForApprovalVenuesPageForVenueAdmin(
    { getState, patchState, dispatch }: StateContext<VenuesStateModel>,
    { sortBy }: VENUES_ACTIONS.SortAllWaitingForApprovalVenuesPageForVenueAdmin
  ) {
    patchState({
      waitingForApprovalVenuesSortByForVenueAdmin: sortBy,
      waitingForApprovalVenuesPaginationForVenueAdmin: {
        ...getState().waitingForApprovalVenuesPaginationForVenueAdmin,
        pageNumber: 0,
      },
    });
    dispatch(
      new VENUES_ACTIONS.GetAllWaitingForApprovalVenuesPageForVenueAdmin()
    );
  }

  @Action(VENUES_ACTIONS.SearchAllWaitingForApprovalVenuesPageForVenueAdmin)
  public searchAllWaitingForApprovalVenuesPageForVenueAdmin(
    { getState, patchState, dispatch }: StateContext<VenuesStateModel>,
    {
      searchQuery,
    }: VENUES_ACTIONS.SearchAllWaitingForApprovalVenuesPageForVenueAdmin
  ) {
    patchState({
      waitingForApprovalVenuesSearchQueryForVenueAdmin: searchQuery,
      waitingForApprovalVenuesPaginationForVenueAdmin: {
        ...getState().waitingForApprovalVenuesPaginationForVenueAdmin,
        pageNumber: 0,
      },
    });

    dispatch(
      new VENUES_ACTIONS.GetAllWaitingForApprovalVenuesPageForVenueAdmin()
    );
  }

  @Action(VENUES_ACTIONS.ApproveVenue)
  public ApproveVenue(
    { dispatch, getState, patchState }: StateContext<VenuesStateModel>,
    { requestId }: VENUES_ACTIONS.ApproveVenue
  ) {
    return this._mainService
      .approveVenue(requestId)
      .pipe(
        tap((isApproved) => {
          if (isApproved) {
            const pagination = { ...getState().waitingForApprovalVenuesPagination };
            if (pagination.recordsTotalCount &&(pagination.recordsTotalCount - 1) % pagination.pageSize === 0) {
              patchState({
                waitingForApprovalVenuesPagination: {
                  ...getState().waitingForApprovalVenuesPagination,
                  pageNumber: pagination.pageNumber > 0 ? pagination.pageNumber - 1 : pagination.pageNumber,
                },
              });
            }
            dispatch(new VENUES_ACTIONS.GetAllWaitingForApprovalVenuesPage());
          } else {
            return
          }

        }
        )
      );
  }

  @Action(VENUES_ACTIONS.RejectVenue)
  public RejectVenue(
    { dispatch, getState, patchState }: StateContext<VenuesStateModel>,
    { id }: VENUES_ACTIONS.RejectVenue
  ) {
    return this._mainService.rejectVenue(id).pipe(
      tap((isRejected) => {
        if (isRejected) {
          const pagination = { ...getState().waitingForApprovalVenuesPagination };
          if (pagination.recordsTotalCount && (pagination.recordsTotalCount - 1) % pagination.pageSize === 0) {
            patchState({
              waitingForApprovalVenuesPagination: {
                ...getState().waitingForApprovalVenuesPagination,
                pageNumber: pagination.pageNumber > 0 ? pagination.pageNumber - 1 : pagination.pageNumber,
              },
            });
          }
          dispatch(new VENUES_ACTIONS.GetAllWaitingForApprovalVenuesPage());
        } else {
          return;
        }
      })
    );
  }

  @Action(VENUES_ACTIONS.DeleteVenueRequest)
  public DeleteVenueRequest(
    { dispatch, getState, patchState }: StateContext<VenuesStateModel>,
    { requestId }: VENUES_ACTIONS.DeleteVenueRequest
  ) {
    return this._mainService.deleteVenueRequest(requestId).pipe(
      tap((isDeleted) => {
        if (isDeleted) {
          const pagination = { ...getState().waitingForApprovalVenuesPaginationForVenueAdmin };
          if ((pagination.recordsTotalCount - 1) % pagination.pageSize === 0) {
            patchState({
              waitingForApprovalVenuesPaginationForVenueAdmin: {
                ...getState().waitingForApprovalVenuesPaginationForVenueAdmin,
                pageNumber: pagination.pageNumber > 0 ? pagination.pageNumber - 1 : pagination.pageNumber,
              },
            });
          }
          dispatch(new VENUES_ACTIONS.GetAllWaitingForApprovalVenuesPageForVenueAdmin());
        } else {
          return;
        }
      })
    );
  }

  @Action(VENUES_ACTIONS.GetAwaitingVenueTermsAndConditions)
  public getAwaitingVenueTermsAndConditions(
    { patchState }: StateContext<VenuesStateModel>,
    { id }: VENUES_ACTIONS.GetAwaitingVenueTermsAndConditions
  ) {
    return this._mainService.getAwaitingVenueTermsAndConditions(id).pipe(
      tap((venueTermsAndConditions) => {
        patchState({
          awaitingVenueTermsAndConditions: venueTermsAndConditions,
        });
      })
    );
  }

  @Action(VENUES_ACTIONS.GetAwaitingVenueDetailsForAdmin)
  public getAwaitingVenueDetailsForAdmin({ dispatch, patchState }: StateContext<VenuesStateModel>, { id }: VENUES_ACTIONS.GetAwaitingVenueDetailsForAdmin) {
    return this._mainService.getAwaitingVenueDetailsForAdmin(id).pipe(
      tap(
        (venueDetails) => {
          dispatch(new VENUES_ACTIONS.GetAwaitingVenueTermsAndConditions(id))

          patchState({ awaitingVenueDetailsForAdmin: venueDetails })
        }
      )
    )
  }

  @Action(VENUES_ACTIONS.GetOffersPageInAwaitingVenue)
  public getOffersPageInAwaitingVenue({ patchState, getState }: StateContext<VenuesStateModel>) {
    const { awaitingVenueOffersPagination: { pageNumber, pageSize } } = getState();
    const id = getState().awaitingVenueDetailsForAdmin.id
    return this._mainService.getOffersPageInAwaitingVenue({ pageNumber, pageSize }, id).pipe(
      tap(
        ({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<VENUES_MODELS.OfferModel>) => patchState({
          awaitingVenueOffers: records,
          awaitingVenueOffersPagination: { recordsTotalCount, totalPages, pageNumber, pageSize }
        })
      )
    )
  }

  @Action(VENUES_ACTIONS.PaginateOffersPageInAwaitingVenue)
  public paginateOffersPageInAwaitingVenue({ patchState, dispatch, getState }: StateContext<VenuesStateModel>, { offersPagination }: VENUES_ACTIONS.PaginateOffersPageInAwaitingVenue) {
    patchState({
      awaitingVenueOffersPagination: {
        ...getState().awaitingVenueOffersPagination,
        pageNumber: offersPagination.pageNumber
      }
    })
    dispatch(new VENUES_ACTIONS.GetOffersPageInAwaitingVenue)
  }

  @Action(VENUES_ACTIONS.GetUpcomingEventsInAwaitingVenue)
  public getUpcomingEventsInAwaitingVenue({ patchState, getState }: StateContext<VenuesStateModel>) {
    const { awaitingVenueUpcomingEventsPagination: { pageNumber, pageSize } } = getState();
    const id = getState().awaitingVenueDetailsForAdmin.id
    return this._mainService.getUpcomingEventsPageInAwaitingVenue({ pageNumber, pageSize }, id).pipe(
      tap(
        ({ records, recordsTotalCount, totalPages, pageNumber, pageSize }: PaginationModel<VENUES_MODELS.UpcomingEventModel>) => patchState({
          awaitingVenueUpcomingEvents: records,
          awaitingVenueUpcomingEventsPagination: { recordsTotalCount, totalPages, pageNumber, pageSize }
        })
      )
    )
  }

  @Action(VENUES_ACTIONS.PaginateUpcomingEventsInAwaitingVenue)
  public paginateUpcomingEventsInAwaitingVenue({ patchState, dispatch, getState }: StateContext<VenuesStateModel>, { eventsPagination }: VENUES_ACTIONS.PaginateUpcomingEventsInAwaitingVenue) {
    patchState({
      awaitingVenueUpcomingEventsPagination: {
        ...getState().awaitingVenueUpcomingEventsPagination,
        pageNumber: eventsPagination.pageNumber
      }
    })
    dispatch(new VENUES_ACTIONS.GetUpcomingEventsInAwaitingVenue)
  }
}

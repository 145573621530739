import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { LoggedUser } from './../model/auth.model';
import { StorageService } from './../../services/storage/storage.service';
import { ApiResponse } from './../../../shared/models/api-response';
import { AuthService } from '@core/auth/model/auth.service';
import {
  State,
  Action,
  StateContext,
  NgxsOnInit,
  Store,
  Selector,
} from '@ngxs/store';
import * as AuthActions from './auth.actions';
import { SetTicketDetails } from '@core/modules/authorization/state/authorization.actions';
import { Injectable, NgZone } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { OutOutConfigInst } from 'src/app/config/app.config';
import { AuthorizationState } from '@core/modules/authorization/state/authorization.state';
import { SnackBarsService } from '@shared/modules/snackbars/snackbars.service';
import { OauthFailsMessages } from '../config/oauth-events.config';
import { Location } from '@angular/common';
import { SetUser } from '@core/modules/user/state/user.actions';
import { forkJoin } from 'rxjs';
import {
  InstallPermissions,
  LoadSystemRoles,
  SetGrantedRoles,
} from '@core/modules/authorization/state/authorization.actions';
import { of } from 'rxjs';
import { ModalsService } from '@shared/modules/modals/model/modals.service';
import { tap } from 'rxjs/operators';
import { StateResetAll } from 'ngxs-reset-plugin';
import { NotificationService } from '../../../feature-modules/notifications/models/notifications.service';

export class AuthStateModel {
  logoutChannel: BroadcastChannel;
  loginChannel: BroadcastChannel;
  returnUrl?: string;
  lastDispatchedActions: object[];
  tokenRefreshInProgress: boolean;
  user: any;
  email: string;
  hashedOTP: string;
  newCodeAvaialable: boolean;
}

@Injectable()
@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    logoutChannel: new BroadcastChannel('logout_channel'),
    loginChannel: new BroadcastChannel('login_channel'),
    lastDispatchedActions: [],
    tokenRefreshInProgress: false,
    user: null,
    email: null,
    hashedOTP: null,
    newCodeAvaialable: true
  },
})
export class AuthState implements NgxsOnInit {
  constructor(
    private readonly _store: Store,
    private readonly _snackbar: SnackBarsService,
    private readonly _location: Location,
    private readonly _modals: ModalsService,
    private readonly _auth: AuthService,
    private readonly _storage: StorageService,
    private _notificationService:NotificationService
  ) { }
  @SelectSnapshot(AuthorizationState.isAuthorizedUser)
  public isAuthorizedUser: boolean;

  ngxsOnInit({ getState, dispatch }: StateContext<AuthStateModel>) {
    getState().logoutChannel.addEventListener('message', (e: MessageEvent) => {
      if (e.data.userHasLoggedOut) {
        getState().logoutChannel.close();
        window.location.reload();
      }
    });

    getState().loginChannel.addEventListener('message', (e: MessageEvent) => {
      if (e.data.userHasLoggedIn) {
        console.log('[AuthState] Login Message has been received successfully');
        forkJoin([
          // dispatch(new ResetUserInfo),
          dispatch(new LoadSystemRoles()),
        ]).subscribe(() => {
          getState().loginChannel.close();
          window.location.reload();
        });
      }
    });
  }

  @Selector() static email(state: AuthStateModel): string {
    return state.email;
  }

  @Selector() static hashedOTP(state: AuthStateModel): string {
    return state.hashedOTP;
  }

  @Selector() static codeAvailablity(state: AuthStateModel): boolean {
    return state.newCodeAvaialable;
  }

  @Action(AuthActions.Login)
  public login(ctx: StateContext<AuthStateModel>, { user }: AuthActions.Login) {
    return this._auth.login(user).pipe(
      tap(({ result }: ApiResponse<LoggedUser>) => {
        this._storage.set('expiration', result.expiration);
        this._storage.set('token', result.token);
        this._storage.set('refreshToken', result.refreshToken);
        this._storage.set(
          'refreshTokenExpiration',
          result.refreshTokenExpiration
        );
        this._store.dispatch(new SetUser(result.user));
        this._store.dispatch(new SetGrantedRoles(result.userRoles));
        this._store.dispatch(new InstallPermissions(result.userRoles))
        // this._router.navigateByUrl('/dashboard')
        if (this.isAuthorizedUser) {
          // fetching ticket details and user role to navigate to tickets page if user is ticket admin or user is super admin and there is ticket scanned
          let ticketDetails = this._store.selectSnapshot(AuthorizationState.ticketDetails);
          let navigateToTicketsPage = this._store.selectSnapshot(AuthorizationState.isTicketAdmin) || (ticketDetails && this._store.selectSnapshot(AuthorizationState.isSuperAdmin))
          ctx.dispatch([new Navigate([navigateToTicketsPage ? '/tickets':OutOutConfigInst.ROUTES_CONFIG.root])]);
        } else {
          ctx.dispatch([
            new Navigate([OutOutConfigInst.ROUTES_CONFIG.landingPage]),
          ]);
        }
      })
    );
  }

  @Action(AuthActions.ForgetPassword)
  public forgetPassword(
    ctx: StateContext<AuthStateModel>,
    { email }: AuthActions.ForgetPassword
  ) {
    return this._auth.forgetPassword(email).pipe(
      tap((result) => {
        ctx.patchState({
          email: email,
        });
        ctx.patchState({
          newCodeAvaialable: result.status
        })
        if (result.status) {
          ctx.dispatch(new Navigate(['/auth/code-verification']));
        }
      }, error => {
        console.log(error);
        ctx.patchState({
          newCodeAvaialable: error.error.status
        })
      })
    );


  }

  @Action(AuthActions.VerifyResetPassword)
  public verifyResetPassword(
    ctx: StateContext<AuthStateModel>,
    { email, otp }: AuthActions.VerifyResetPassword
  ) {
    return this._auth.verifyResetPassword(email, otp).pipe(
      tap((result) => {
        ctx.patchState({
          hashedOTP: result.result,
        });
        ctx.dispatch(new Navigate(['/auth/reset-password']));
      })
    );
  }

  @Action(AuthActions.ResetPassword)
  public resetPassword(
    ctx: StateContext<AuthStateModel>,
    { email, hashedOTP, newPassword }: AuthActions.ResetPassword
  ) {
    return this._auth.resetPassword(email, hashedOTP, newPassword).pipe(
      tap((result) => {
        if (result.result) {
          this._snackbar.openSuccessSnackbar({
            message: 'New Password has been set',
            duration: 8,
          });
          ctx.dispatch(new Navigate([OutOutConfigInst.ROUTES_CONFIG.login]));
        }
      })
    );
  }

  @Action(AuthActions.ChangePassword)
  public changePassword(
    ctx: StateContext<AuthStateModel>,
    changePasswordPayload: AuthActions.ChangePassword
  ) {
    return this._auth.changePassword(changePasswordPayload).pipe(
      tap((result) => {
        if (result.result) {
          this._snackbar.openSuccessSnackbar({
            message: 'New Password has been set',
            duration: 8,
          });
          ctx.dispatch(new Navigate(['/dashboard']));
        }
      })
    );
  }

  @Action(AuthActions.Logout)
  public logout(ctx: StateContext<AuthStateModel>) {
    //reset all App states to their defaults
    ctx.dispatch(new StateResetAll())
    //! setting ticket details with null value when user is logging out to disabled navigating to the ticket details 
    ctx.dispatch(new SetTicketDetails(null))
    this._storage.clearStorage();
    this._notificationService.closeConnection();
    ctx.dispatch(new Navigate([OutOutConfigInst.ROUTES_CONFIG.splash]));
  }

  @Action(AuthActions.RefreshToken, {
    cancelUncompleted: true,
  })
  public refreshToken({
    getState,
    patchState,
    dispatch,
  }: StateContext<AuthStateModel>) {
    if (!getState().tokenRefreshInProgress) {
      // this._modals.openFeedbackDialog({
      //   title: 'Renewing Session',
      //   content: 'Session expired, We are working on renewing it',
      //   status: 'success',
      //   autoDismissTime: 3,
      // });
      // dispatch(new DeactivateSpinner());
      patchState({ tokenRefreshInProgress: true });
      
        // alert('Session Refreshing Started')
        const refreshTokenPayload = {
          accessToken: this._storage.get('token') ? this._storage.get('token').toString() : 'INVALID_TOKEN',
          refreshToken: this._storage.get('refreshToken') ? this._storage.get('refreshToken').toString() : 'INVALID_REFRESH_TOKEN',
        };
        console.log(refreshTokenPayload.accessToken)
        return this._auth.refreshToken(refreshTokenPayload).subscribe(
          (res) => {
            console.info('Access token has been refreshed', res);
            // Incase access token been refreshed on origin route
            if (window.location.href == window.location.origin + '/')
              window.location.href =
                window.location.origin + OutOutConfigInst.ROUTES_CONFIG.root;
            const lastDispatchedActions = getState().lastDispatchedActions;
            if (lastDispatchedActions.length)
              lastDispatchedActions.forEach((action) => dispatch(action));
            patchState({
              tokenRefreshInProgress: false,
              lastDispatchedActions: [],
            });
            // dispatch(new ActivateSpinner());
          },
          () => {
            console.error(
              '[Auth State handler]' + OauthFailsMessages.failToRefresh
            );
            this._snackbar.openFailureSnackbar({
              message: OauthFailsMessages.failToRefresh,
              duration: 8,
            });
            patchState({
              tokenRefreshInProgress: false,
              lastDispatchedActions: [],
            });
            dispatch(new Navigate([OutOutConfigInst.ROUTES_CONFIG.login]));
            // dispatch(new ActivateSpinner());
          })
      
    }
    return of(null);
  }

  @Action(AuthActions.NavigateTargetRoute)
  public navigateTargetUrl(
    { dispatch, getState }: StateContext<AuthStateModel>,
    { route }: AuthActions.NavigateTargetRoute
  ) {
    dispatch(new Navigate([route ?? getState().returnUrl]));
  }

  @Action(AuthActions.NotifyAllOriginContextsToLogout)
  public notifyAllOriginContextsToLogout({
    getState,
  }: StateContext<AuthStateModel>) {
    getState()?.logoutChannel.postMessage({
      userHasLoggedOut: true,
    });
  }

  @Action(AuthActions.NotifyAllOriginContextsToLogin)
  public notifyAllOriginContextsToLogin({
    getState,
  }: StateContext<AuthStateModel>) {
    console.log('[Auth state] Login message has been posted successfully');
    getState().loginChannel.postMessage({
      userHasLoggedIn: true,
    });
  }

  @Action(AuthActions.SetReturnUrl) public setReturnUrl(
    { patchState }: StateContext<AuthStateModel>,
    { route }: AuthActions.SetReturnUrl
  ) {
    patchState({ returnUrl: route || this._location.path() });
  }

  @Action(AuthActions.RemoveReturnUrl) public removeReturnUrl({
    patchState,
  }: StateContext<AuthStateModel>) {
    patchState({ returnUrl: null });
  }

  @Action(AuthActions.CacheLastDispatchedAction)
  public cacheLastDispatchedAction(
    { patchState, getState }: StateContext<AuthStateModel>,
    { action }: AuthActions.CacheLastDispatchedAction
  ) {
    patchState({
      lastDispatchedActions: [...getState().lastDispatchedActions, action],
    });
  }
}

import { loggedInUserModel } from '@core/modules/user/model/user.model';
import { SetGrantedRoles } from '@core/modules/authorization/state/authorization.actions';
import { SetUser } from '@core/modules/user/state/user.actions';
import { ApiResponse } from '@core/http/apis.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, ReplaySubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OutOutConfigInst } from 'src/app/config/app.config';
import { Store } from '@ngxs/store';
import { HttpService } from '@core/http/http/http.service';
import { StorageService } from '@core/services/storage/storage.service';
import { Location } from '@angular/common';
import { SnackBarsService } from '@shared/modules/snackbars/snackbars.service';
import { buildQueryString } from '@shared/helpers/build-query-string.helper';
@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(
    private readonly _router: Router,
    private readonly _store: Store,
    private readonly _http: HttpService,
    private readonly _storage: StorageService,
    private readonly _location: Location,
    private readonly _snacks: SnackBarsService
  ) {
  }
  private showSplashSubject$ = new BehaviorSubject<boolean>(false);
  public showSplash$ = this.showSplashSubject$.asObservable();

  initSplash() {
    if (this._storage.get("token")) {
      this.showSplash();
    } else {
      this.hideSplash();
    }
  }
  showSplash() {
    this.showSplashSubject$.next(true);
  }
  hideSplash() {
    this.showSplashSubject$.next(false);
  }

  public login(form) {
    return this._http.post(`Auth/Login`, form);
  }

  /**
 * Get user info then fire SetUser Action which will save user data to storage
 */
    public setupUserInfo() {
      return this._http.fetch(`AdminProfile/GetMyAccountInfo`).pipe(
          tap(({ result: user }: ApiResponse<loggedInUserModel>) => {
              this._store.dispatch(new SetUser(user))
              this._store.dispatch(new SetGrantedRoles(user.permissions));
        }))
    }

  public logout() {
    return this._http.fetch('Auth/WebLogout');
  }

  public forgetPassword(email: string) {
    return this._http.fetch(`Auth/ForgetPassword${buildQueryString({ email })}`);
  }
  public refreshToken(refreshTokenPayload: {
    accessToken: string,
    refreshToken: string
  }) {
    return this._http.post(`Auth/RefreshAccessToken`, refreshTokenPayload);
  }

  public verifyResetPassword(email: string, otp: string) {
    return this._http.post(`Auth/VerifyResetPassword`, {
      email, otp
    })
  }
  public resetPassword(email: string, hashedOTP: string, newPassword) {
    return this._http.post(`Auth/ResetPassword`, { email, hashedOTP, newPassword })
  }

  public changePassword(payload) {
    console.log('changePasswordPayload', payload)
    return this._http.post('Auth/ChangePassword', {newPassword : payload.changePasswordPayload.newPassword, oldPassword: payload.changePasswordPayload.oldPassword})
  }

}
